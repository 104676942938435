export const selectlogin = (state: any) => {return state.app.isLoggedIn};

export const selectEventExtras = (state:any)=>{return state.app.eventExtras}

export const selectStudent = (state: any) => {return state.app.student};

export const selectPasses = (state: any) => {return state.app.passes};

export const selectGoalPlans = (state: any) => {return state.app.goalPlans}

export const selectRecommendedGoalPlan = (state: any) => {return state.app.recommendedGoalPlan}

export const selectPassProducts = (state: any) => {return state.app.products}

export const selectGoals = (state: any) => {return state.app.goals}

export const selectEducator = (state: any) => {return state.app && state.app.educator};

export const selectFaculty = (state: any) => {return state.app && state.app.faculty};

export const selectEducatorFollowStatus = (state: any) => {return state.app && state.app.isEducatorFollowed};

export const selectGoalTestSeries = (state: any) => {return state.app.goalTestSeries}

export const selectEnrolledGoalTestSeries = (state: any) => {return state.app.enrolledGoalTestSeries}

export const selectSuggestedTestByGoal = (state: any) => {return state.app.suggestedTestByGoal}

export const selectSearchedTestSeries = (state: any) => {return state.app.searchedTestSeries}

export const selectSearchedPypTargets = (state: any) => {return state.app.searchedPypTargets}

export const selectGoalPypTargets = (state: any) => {return state.app.goalPypTargets}

export const selectGoalTestTabComponentState = (state: any) => {return state.app.goalTestTabComponentState}

export const selectCustomTagsByGoal = (state: any) => {return state.app.customTags}

export const selectGoalPageSequence = (state: any) => {return state.app.goalPageSequence}


export const selectPassPageFaqs = (state:any)=>{return state.app.passFaqs};

export const selectReferredUser = (state: any) => {return state.app && state.app.referredUser};

export const selectProductEntityCount = (state: any) => {return state.app.productEntityCount};

export const selectReportModal = (state: any) => {return state.app.reportModal};

export const selectCurrentLanguage = (state: any) => {return state.app.lang};

export const selectPageCategory = (state : any) => {return state.app.page};

export const selectPageSeo = (state: any) => {return state.app.pageSeo};

export const selectSuperGroups = (state: any) => {return state.app.superGroups};

export const selectPypSuperGroups = (state: any) => {return state.app.pypSuperGroups};

export const selectTargets = (state: any) => {return state.app.targets};

export const selectPypTargets = (state: any) => {return state.app.pypTargets};

export const selectBanners = (state: any) => {return state.app.banners};

export const selectBannerDate = (state: any) => {return state.app.bannerDate};

export const selectJustLoggedIn = (state: any) => {return state.app.justLoggedIn};

export const selectNotifications = (state: any) => {return state.app.notifications};
export const selectNotificationsCount = (state: any) => {return state.app.notificationsCount};

export const selectHeaderState = (state: any) => {return state.app.showHeader};
export const selectSidebarState = (state: any) => {return state.app.showSidebar};

export const selectRazorpayIframeStatus = (state: any) => {return state.app.razorpayPayment.isIframeVisible};

export const selectRazorpayPaymentDetails = (state: any) => {return state.app.razorpayPayment};

export const selectFooterState = (state: any) => {return state.app.showFooter};

export const selectStickyFooterState = (state: any) => {return state.app.showStickyFooter};

export const selectContinueInAppState = (state: any) => {return state.app.showContinueInApp};

export const selectStickyFooterTypeState = (state: any) => {return state.app.stickyfooterType};

export const selectContinueInAppDeeplinkData = (state: any) => {return state.app.continueInAppDeeplinkData};

export const selectOnboardingParams = (state: any) => {return state.app.onboardingParams};

export const selectSearchHistory = (state: any) => {return state.app.searchHistory};

export const selectDeletedNotificationId = (state: any) => {return state.app.deletedNotificationId};

export const selectCouponModal = (state: any) => {return state.app.couponModal};

export const selectProductDetail = (state: any) => {return state.app.productsOnPage};

export const selectAutoCouponApply = (state: any) => {return state.app.autoCouponApply};
export const selectDisableAutoCouponApply = (state: any) => {return state.app.disableAutoCouponApply};

export const selectAlertState = (state: any) => {return state.app.alertToast};

export const selectPricingModal = (state: any) => {return state.app.pricingModal};

export const selectBookModal = (state: any) => {return state.app.bookModal};

export const selectCounsellingModal = (state: any) => {return state.app.counsellingModal};

export const selectProductInfoModal = (state: any) => {return state.app.productInfoModal};

export const selectVideoModal = (state: any) => {return state.app.videoModal};

export const selectSignupAndVerificationModal = (state: any) => {return state.app.signupAndVerificationModal};

export const selectIsMobileVerified = (state: any) => {return state.app.isMobileVerified};

export const selectTransactionPopupAction = (state: any) => {return state.app.transactionPopupAction};

export const selectKindOffer = (state: any) => {return state.app && state.app.kindOffer};

export const selecSeoGroupSubFooter = (state: any) => {return state.app.subFooter};

export const selectOpenInAppDialog = (state: any) => {return state.app.openInAppDialog};

export const selectMultiAppJsonData = (state: any) => {return state.app.multiAppJsonData};

export const selectMasterSeriesEnrollmentDetails = (state:any) => {return state.app.enrollment};

export const selectGoal = (state: any) => { return state.app.goal };

export const selectGoalMap = (state: any) => { return state.app.goalMap };

export const selectCurrentPageGoal = (state: any) => { return state.app.currentPageGoal; };

export const selectGoalSelectionModal = (state: any) => {return state.app.goalSelectionModal};

export const selectActivePageType = (state:any) => {return state.app.activePageType};

export const selectPitchMappingDetails = (state:any) =>{return state.app.pitchMapping}

export const selectGoalCourses = (state:any) =>{return state.app.goalCourses}

export const selectTeachersModules = (state:any)=>{return state.app.teachersModules}
export const selectTargetToGoalMap = (state:any) =>{return state.app.targetToGoalMap}

export const selectResumeFlowActivities = (state:any) => {return state.app.resumeFlowActivities }

export const selectAccountBlockedModal = (state: any) => {return state.app.accountBlocked;};
export const selectGoalClasses = (state: any) => state.app.goalClasses;
export const selectGoalClassesMap = (state: any) => state.app.goalClassesMap;


export const selectMasterSeries = (state: any) => state.app.masterSeries;

export const selectWhatappModal = (state:any) => {return state.app.whatsappModal}

export const selectGoalBannerData = (state: any) => {return state.app.goalBannerData}

export const selectCloudBucketData = (state: any) => {return state.app.cloudBucketData}

export const selectGlobalPopupBannerData = (state: any) => {return state.app.globalPopupBannerData}

export const selectPurchaseState = (state: any) => {return state.app.purchaseState};

export const selectTopSeoMenu = (state: any) => {return state.app.seoMenu.top};

export const selectSideSeoMenu = (state: any) => {return state.app.seoMenu.side};

export const selectRequestCallBackModal = (state:any) => {return state.app.requestCallBackModal}
export const selectGoalPagePopupStatus = (state:any) => {return state.app.showGoalPagePopup}

export const selectPersonalisedBannerData = (state:any) => {return state.app.personalisedBannerData}

export const selectSimilarGoalPacks = (state:any) => {return state.app.similarGoalPacks};

export const selectJarvisBanner = (state: any) => {return state.app.jarvisBanner};

export const selectSearchBarQuery = (state: any) => {return state.app.searchBarQuery};

export const selectContentLang = (state: any) => {return state.app.availableContentLangs};

export const selectCalendarData = (state: any) => {return state.app.examCalendar};
export const selectPassSaleBanner = (state: any) => {return state.app.passSaleBannerData};

export const selectDailyCurrentAffairs = (state: any) => {return state.app.dailyCurrentAffairs};
export const selectMonthlyCurrentAffairs = (state: any) => {return state.app.monthlyCurrentAffairs};


export const selectPopupData = (state: any) => {return state.app.popupData};

export const selectReattemptModal = (state: any) => {return state.app.showReattemptModal};

export const selectPassProIntroPopup = (state: any) => {return state.app.passProIntroPopup};

export const selectPassProDetails = (state: any) => {return state.app.passProDetails};

export const selectComparisonPagePassDetails = (state:any) =>{return state.app.comparisonPagePassDetails}

export const selectGoalFeatureModal = (state: any) => {return state.app.goalFeatureModal};

export const selectAutoSelectTabData = (state: any) => {return state.app.autoSelectTabData};

export const selectSelectedPass = (state: any) => state.app.selectedPass;

export const selectPassEliteCard = (state:any) => {return state.app.passEliteCard}

export const selectGoalFreeTest = (state: any) => {return state.app.goalFreeTest}

export const selectPassElitePageDetails = (state: any) => {return state.app.passElitePageDetails}

export const selectPassElitePitch = (state: any) => {return state.app.passElitePitch}

