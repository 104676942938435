<header class="header">
    <!-- Main Header for logged out state -->
    <div class="header__post" [ngClass]="{'open': showHeaderMweb}" >
        <div class="logo">
            <div class="logo__hamburger js-trigger-hamburger-button" (click)="toggleHeaderMweb($event)"><span></span></div>
            <a class="logo__testbook" routerLink="/" *ngIf="!isMobile">
                <svg>
                    <use xlink:href="#testbook-logo"></use>
                </svg>
            </a>
            <a class="logo__testbook" routerLink="/" *ngIf="isMobile">
                <svg width="30" height="30">
                    <use xlink:href="#testbook-icon"></use>
                </svg>
            </a>
            <div class="mweb" *ngIf="isMobile">
                <app-lang-dropdown></app-lang-dropdown>

                <div class="search-container">
                    <global-search-bar-modal (onModalToggle)="toggleSearchModal()"></global-search-bar-modal>
                </div>
                <a href="{{signupUrl}}"
                   gtmextras='{"module":"TopNavigation"}'
                   title="Login" class="btn btn--cta btn--mweb ui-js-login"
                   [attr.data-onboarding-params]="openLoginParams() | json"
                   translate>Get Started</a>
            </div>
        </div>
        <!-- Main Header right panel logged out case-->
        <ul class="action-nav" *ngIf="!isMobile">
            <li class="desktop language-container">
                <app-lang-dropdown></app-lang-dropdown>
            </li>
            <li>
                <a href="{{signupUrl}}" title="Sign Up"
                   [attr.data-onboarding-params]="openLoginParams() | json"
                   class="btn btn--cta btn--signup ui-js-login" translate>Get Started</a>
            </li>
        </ul>
        <div class="container">
            <!-- Main Header logged out case-->
            <ul class="main-nav">
                <li>
                    <a *ngIf="isServer" (mouseenter)="showTargetDropdown()" (mouseleave)="hideTargetDropdown()" href="/exams" (click)="showTargetDropdown($event)" prevent-default="false" [ngClass]="{'show': showTargets}" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#exams-inactive"></use>
                        </svg>Exams
                    </a>
                    <a *ngIf="!isServer" class="exam-nav" (mouseenter)="showTargetDropdown()" (mouseleave)="hideTargetDropdown()" href="javascript:void(0)" (click)="showTargetDropdown($event)" prevent-default="false" [ngClass]="{'show': showTargets}" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#exams-inactive"></use>
                        </svg>Exams
                        <target-list *ngIf="isMobile && targetDropdownTouched" [hidden]="!showHeaderMweb" class="target-list" (onTargetClick)="hideTargetDropdown()"> </target-list>
                        <target-list *ngIf="!isMobile && targetDropdownTouched" class="target-list" (onTargetClick)="hideTargetDropdown()"> </target-list>
                    </a>

                </li>

                <li>
                    <a *ngIf="isServer" routerLinkActive="active-link" [routerLink]="goalSelectionPageUrl" target="_self" [ngClass]="{'active-link': activePageType === 'superCoaching','coaching-nav':!isServer}" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#coaching-inactive"></use>
                        </svg>SuperCoaching
                    </a>

                    <a *ngIf="!isServer" href="javascript:void(0)"  [routerLink]="goalSelectionPageUrl" routerLinkActive="active-link" prevent-default="false" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#coaching-inactive"></use>
                        </svg>SuperCoaching

                    </a>
                </li>
                <li class="nav-hidden">
                    <a routerLink="/free-live-classes" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#master-class-inactive"></use>
                        </svg> Live Classes <span class="badge badge--free">FREE</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/online-test-series" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#test-series-inactive"></use>
                        </svg>Test Series
                    </a>
                </li>
                <li class="nav-hidden">
                    <a routerLink="/previous-year-papers" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pyp-inactive"></use>
                        </svg>Previous Year Papers
                    </a>
                </li>
                <li>
                    <a routerLink="/skill-academy" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#skills-inactive"></use>
                        </svg> Skill Academy
                    </a>
                </li>

                <li class="dropdown" *ngIf="!isMobile">
                    <a class="more-nav" translate>Pass</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/pass" routerLinkActive="active-link" target="_self" translate>
                            <svg class="main-nav__img" width="16" height="16">
                                <use xlink:href="#pass-inactive"></use>
                            </svg>
                            HEADER_PASS
                        </a>
                        <a class="dropdown-item" routerLink="/pass-pro" routerLinkActive="active-link" target="_self" translate>
                            <svg class="main-nav__img" width="16" height="16">
                                <use xlink:href="#pro-inactive"></use>
                            </svg>HEADER_PASS_PRO
                        </a>
                        <a class="dropdown-item" routerLink="/pass-elite" routerLinkActive="active-link" target="_self" translate>
                            <svg class="main-nav__img" width="16" height="16">
                                <use xlink:href="#elite-inactive"></use>
                            </svg>HEADER_PASS_ELITE
                        </a>

                    </div>
                </li>

                <li class="nav-hidden">
                    <a routerLink="/pass" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pass-inactive"></use>
                        </svg>HEADER_PASS
                    </a>
                </li>
                <li class="nav-hidden">
                    <a routerLink="/pass-pro" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pro-inactive"></use>
                        </svg>HEADER_PASS_PRO
                    </a>
                </li>

                <li class="nav-hidden">
                    <a routerLink="/pass-elite" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#elite-inactive"></use>
                        </svg>HEADER_PASS_ELITE
                    </a>
                </li>

                <li class="nav-hidden">
                    <a routerLink="/ias-preparation" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#ias-preparation-inactive"></use>
                        </svg>HEADER_IAS_PREPARATION
                    </a>
                </li>

                <li *ngIf="!isMobile" class="dropdown">
                    <a class="more-nav" translate>
                        More
                    </a>
                    <div class="dropdown-menu">
                        <!-- <a routerLink="/pass" routerLinkActive="active-link"  class="dropdown-item" target="_self" translate>
                            Pass
                        </a> -->
                        <a routerLink="/free-live-classes"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Free Live Classes
                        </a>
                        <a routerLink="/free-live-tests-and-quizzes"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Free Live Tests & Quizzes
                        </a>
                        <a routerLink="/free-online-quizzes"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Free Quizzes
                        </a>
                        <a routerLink="/previous-year-papers"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Previous Year Papers
                        </a>
                        <a href="/doubts"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Doubts
                        </a>
                        <a href="/practice-questions"  class="dropdown-item"  target="_self" translate>
                            Practice
                        </a>
                        <a href="/referrals"  class="dropdown-item"  target="_self" translate>
                            Refer and Earn
                        </a>
                        <a href="/exams"  class="dropdown-item"  target="_self" translate>
                            All Exams
                        </a>
                        <a routerLink="/success-stories"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Our Selections
                        </a>
                        <a href="/careers"  class="dropdown-item"  target="_self" translate>
                            Careers
                        </a>
                        <a routerLink="/ias-preparation"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            IAS Preparation
                        </a>
                        <a routerLink="/current-affairs"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Current Affairs
                        </a>
                    </div>
                </li>
            <!--  =======  M-WEB HIDDEN  -->
                <!-- <li class="nav-hidden">
                    <a href="/free-live-classes" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pass-inactive"></use>
                        </svg>Free Live Classes
                    </a>
                </li> -->
                <li class="nav-hidden">
                    <a href="/practice-questions" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#practice-inactive"></use>
                        </svg>Practice
                        <!-- <span class="badge badge--free">FREE</span> -->
                    </a>
                </li>
                <hr class="main-nav__divider">
                <li class="nav-hidden">
                    <a href="/current-affairs/current-affairs-quiz" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#gk-ca-inactive"></use>
                        </svg>GK & Current Affairs
                    </a>
                </li>

                <li *ngIf="isMobile">
                    <a href="/doubts" target="_self" translate>
                        <svg class="main-nav__img" width="15" height="16">
                            <use xlink:href="#doubts-inactive"></use>
                        </svg>Doubts
                    </a>
                </li>

                <li class="nav-hidden">
                    <a href="/blog/" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#blog-inactive"></use>
                        </svg>Blog
                    </a>
                </li>
                <hr class="main-nav__divider">
                <li class="nav-hidden">
                    <a href="/referrals" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#refer-earn-inactive"></use>
                        </svg>Refer & Earn
                    </a>
                    <a [routerLink]="['/success-stories']" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#selections-inactive"></use>
                        </svg>HEADER_SELECTIONS
                    </a>
                </li>
                <li *ngIf="!isMobile" class="global-search w-100">
                    <global-search-bar class="w-100"></global-search-bar>
                </li>
            </ul>
        </div>
        <div class="header-backdrop js-trigger-header-backdrop">

        </div>
    </div>
    <div class="container">
        <div class="global-search-wrap">
            <div class="global-search">
                <!-- <app-global-search-modal></app-global-search-modal> -->
            </div>
        </div>
    </div>
</header>



<svg style="display: none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="elite-inactive" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52299 0.623058C5.09346 0.49839 4.6421 0.735039 4.50034 1.15924L1.04322 11.5045C0.89324 11.9534 1.14665 12.4368 1.60107 12.5689L1.79102 12.6241C2.24164 12.755 2.61534 12.4847 2.7903 12.2388C2.9486 12.0165 3.27098 11.8906 3.59021 11.9834C3.89781 12.0728 4.06461 12.3225 4.06701 12.5581C4.07013 12.8647 4.22807 13.332 4.70648 13.4709L4.9743 13.5487C5.42484 13.6795 5.79844 13.4093 5.97339 13.1636C6.13168 12.9413 6.4543 12.8154 6.77354 12.9083C7.08086 12.9974 7.24775 13.2471 7.25015 13.4829C7.25326 13.7895 7.41119 14.2568 7.88957 14.3957L8.15435 14.4727C8.60495 14.6035 8.9786 14.3332 9.15354 14.0874C9.31173 13.8652 9.63387 13.7392 9.95328 13.8319C10.2613 13.9215 10.428 14.1713 10.4304 14.4065C10.4335 14.713 10.5913 15.1805 11.0698 15.3195L11.2626 15.3755C11.6922 15.5003 12.1437 15.2637 12.2855 14.8394L15.7425 4.49418C15.8925 4.04534 15.6391 3.56183 15.1846 3.42981L14.9917 3.37376C14.5411 3.24286 14.1674 3.51321 13.9925 3.75893C13.8342 3.98124 13.5119 4.10718 13.1927 4.01451C12.885 3.92498 12.7182 3.67516 12.7158 3.43971C12.7126 3.13327 12.5547 2.6659 12.0763 2.52693L11.8115 2.45002C11.361 2.31915 10.9873 2.58943 10.8124 2.83513C10.6541 3.05743 10.3315 3.18327 10.0122 3.09041C9.70492 3.00129 9.53803 2.75163 9.53563 2.5158C9.5325 2.20922 9.37452 1.74187 8.89608 1.60297L8.62817 1.52519C8.17745 1.39434 7.80384 1.66488 7.62896 1.91057C7.47085 2.13269 7.14852 2.25873 6.82901 2.16593C6.52154 2.07663 6.35472 1.8269 6.35236 1.59123C6.34929 1.28463 6.19136 0.817043 5.71273 0.678128L5.52299 0.623058Z" fill="#86A1AE"/>
        <rect x="0.389565" y="4.56339" width="15.2209" height="6.87304" rx="0.569955" fill="#1B1B1B"/>
        <rect x="0.389565" y="4.56339" width="15.2209" height="6.87304" rx="0.569955" stroke="#86A1AE" stroke-width="0.779131"/>
        <path d="M4.20174 8.95184V9.5H2.38624V8.95184H4.20174ZM2.61582 6.08921V9.5H1.91304V6.08921H2.61582ZM3.96514 7.47835V8.01246H2.38624V7.47835H3.96514ZM4.1994 6.08921V6.63971H2.38624V6.08921H4.1994ZM6.92429 8.95184V9.5H5.20718V8.95184H6.92429ZM5.43441 6.08921V9.5H4.73163V6.08921H5.43441ZM8.19443 6.08921V9.5H7.494V6.08921H8.19443ZM10.4274 6.08921V9.5H9.72695V6.08921H10.4274ZM11.4769 6.08921V6.63971H8.69387V6.08921H11.4769ZM14.2861 8.95184V9.5H12.4706V8.95184H14.2861ZM12.7001 6.08921V9.5H11.9974V6.08921H12.7001ZM14.0495 7.47835V8.01246H12.4706V7.47835H14.0495ZM14.2837 6.08921V6.63971H12.4706V6.08921H14.2837Z" fill="#86A1AE"/>
    </symbol>
    <symbol id="elite-active" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52299 0.623058C5.09346 0.49839 4.6421 0.735039 4.50034 1.15924L1.04322 11.5045C0.89324 11.9534 1.14665 12.4368 1.60107 12.5689L1.79102 12.6241C2.24164 12.755 2.61534 12.4847 2.7903 12.2388C2.9486 12.0165 3.27098 11.8906 3.59021 11.9834C3.89781 12.0728 4.06461 12.3225 4.06701 12.5581C4.07013 12.8647 4.22807 13.332 4.70648 13.4709L4.9743 13.5487C5.42484 13.6795 5.79844 13.4093 5.97339 13.1636C6.13168 12.9413 6.4543 12.8154 6.77354 12.9083C7.08086 12.9974 7.24775 13.2471 7.25015 13.4829C7.25326 13.7895 7.41119 14.2568 7.88957 14.3957L8.15435 14.4727C8.60495 14.6035 8.9786 14.3332 9.15354 14.0874C9.31173 13.8652 9.63387 13.7392 9.95328 13.8319C10.2613 13.9215 10.428 14.1713 10.4304 14.4065C10.4335 14.713 10.5913 15.1805 11.0698 15.3195L11.2626 15.3755C11.6922 15.5003 12.1437 15.2637 12.2855 14.8394L15.7425 4.49418C15.8925 4.04534 15.6391 3.56183 15.1846 3.42981L14.9917 3.37376C14.5411 3.24286 14.1674 3.51321 13.9925 3.75893C13.8342 3.98124 13.5119 4.10718 13.1927 4.01451C12.885 3.92498 12.7182 3.67516 12.7158 3.43971C12.7126 3.13327 12.5547 2.6659 12.0763 2.52693L11.8115 2.45002C11.361 2.31915 10.9873 2.58943 10.8124 2.83513C10.6541 3.05743 10.3315 3.18327 10.0122 3.09041C9.70492 3.00129 9.53803 2.75163 9.53563 2.5158C9.5325 2.20922 9.37452 1.74187 8.89608 1.60297L8.62817 1.52519C8.17745 1.39434 7.80384 1.66488 7.62896 1.91057C7.47085 2.13269 7.14852 2.25873 6.82901 2.16593C6.52154 2.07663 6.35472 1.8269 6.35236 1.59123C6.34929 1.28463 6.19136 0.817043 5.71273 0.678128L5.52299 0.623058Z" fill="#86A1AE"/>
        <rect x="0.389565" y="4.56339" width="15.2209" height="6.87304" rx="0.569955" fill="#1B1B1B"/>
        <rect x="0.389565" y="4.56339" width="15.2209" height="6.87304" rx="0.569955" stroke="#86A1AE" stroke-width="0.779131"/>
        <path d="M4.20174 8.95184V9.5H2.38624V8.95184H4.20174ZM2.61582 6.08921V9.5H1.91304V6.08921H2.61582ZM3.96514 7.47835V8.01246H2.38624V7.47835H3.96514ZM4.1994 6.08921V6.63971H2.38624V6.08921H4.1994ZM6.92429 8.95184V9.5H5.20718V8.95184H6.92429ZM5.43441 6.08921V9.5H4.73163V6.08921H5.43441ZM8.19443 6.08921V9.5H7.494V6.08921H8.19443ZM10.4274 6.08921V9.5H9.72695V6.08921H10.4274ZM11.4769 6.08921V6.63971H8.69387V6.08921H11.4769ZM14.2861 8.95184V9.5H12.4706V8.95184H14.2861ZM12.7001 6.08921V9.5H11.9974V6.08921H12.7001ZM14.0495 7.47835V8.01246H12.4706V7.47835H14.0495ZM14.2837 6.08921V6.63971H12.4706V6.08921H14.2837Z" fill="#86A1AE"/>
    </symbol>
</svg>
