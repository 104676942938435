import { Component, Input, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from '@angular/router';
import { TbcoreService } from "@core/services/tbcore.service";
import { AuthenticationService, KEY_REFERRAL_INFO } from "@core/services/authentication.service";
import { PlatformService } from "@core/services/platform-service";
import { ProductAccessService } from "@core/services/product-access.service";
import { StudentService } from '@angular-commons/core/services/student.service';
import { setReferredUserData } from "@core/application-state/app.actions";
import { getFromSessionStorage, isServer, setToSessionStorage } from "@shared/utility/tb-common";
import { clientOnly } from "@shared/utility/platform-decorators";
import { _passModel } from '@angular-commons/models/entity/passes/passes.adapter';
import { getGoalPlansPageUrl } from "@shared/utility/goal-utility";
import { passTypeProducts } from "@shared/utility/constants";

@Component({
  selector: 'pass-status',
  templateUrl: './pass-status.component.html',
  styleUrls: ['./pass-status.component.scss']
})
export class PassStatusComponent implements OnInit {

  @Input() activeUsersCount: number;
  @Input() activePassStudents: any;
  @Input() referralDiscount: any;
  @Input() openPricingModal: any;
  @Input() isInGoalPageHeader = false;
  @Input() passType = "globalPass";
  @Input() isLogoutPaymentPage = false;
  @Input() hideActiveUserStrip = false;
  @Input() showHeading = true;
  @Input() selectedPass: any;
  @Input() isEMIPlan = false;
  currentGoalStateAccess;

  isServer: boolean = isServer();
  isLoggedIn: boolean;
  isMobile: boolean;
  student: any;
  globalPassState: any;
  referrer: any;
  isReferredUser: boolean = false;
  username: string = "user";
  passProAccess: boolean = false;
  passTypeProducts = passTypeProducts;
  dlPage: string = this.platformService.getPageText();
  showDefaultStatus: boolean = true;
  passProState;
  passAccess: boolean = false;
  goal: any;

  constructor(
    private store: Store<{}>,
    private platformService: PlatformService,
    private route: ActivatedRoute,
    private studentService: StudentService,
    private auth: AuthenticationService,
    private router: Router,
    private tbcore: TbcoreService,
    private productAccessService: ProductAccessService
  ) {}

  ngOnInit() {
    this.showDefaultStatus = this.platformService.getPageText() !== 'PassProPage' &&  this.passType != 'passElite';
    this.isMobile=this.platformService.isMobile();
    this.isLoggedIn = this.platformService.isLoggedIn();
    this.setReferrerFromUrlParams();
    if (this.isLoggedIn) {
      this.getStudent();
    } else if (passTypeProducts.includes(this.passType)) {
      this.initReferrerFromSession();
    }
  }

  @clientOnly()
  private getStudent() {
    this.studentService.loadStudent(true).then((student: any) => {
      if (student) {
        this.student = student;
        this.username = student.name;
        this.goal = this.auth.getGoal();
        let goalId = this.goal?.id;
        if (student.globalPassState && student.passProState && passTypeProducts.includes(this.passType)) {
          this.globalPassState = student.globalPassState;
          this.passAccess = _passModel.isPassActive(student.globalPassExpiry, true);
          this.passProState = student.passProState;
          this.passProAccess = _passModel.isPassActive(student.passProExpiry, true);
          this.initReferrer(student.referrer);
        } else if (student.goalPlanState && student.goalPlanState[goalId] && this.passType === 'goalSubs') {
          this.globalPassState = student.goalPlanState[goalId];
        }
        this.currentGoalStateAccess = student?.goalPlanState?.[goalId];
      }
    })
    .catch((error) => {
      console.error("Error loading student:", error);
    });
  }

  initReferrer(referrer,code = ""){
    if(!referrer || !(referrer.sid || referrer._id)) return;
    let _referrer : any = {};
    _referrer.name = (referrer.name && referrer.name.split(" ")[0]) || "";
    _referrer.image = referrer.image || "";
    _referrer.code = code;
    this.isReferredUser = true;
    this.referrer = _referrer;
    this.store.dispatch(setReferredUserData({referrer:this.referrer,isReferredUser:this.isReferredUser,username:this.username}))
  }


  @clientOnly()
  private setReferrerFromUrlParams() {
    try {
      let referrerCode = this.route.snapshot.paramMap.get('refLink');
      if (referrerCode) {
        setToSessionStorage(KEY_REFERRAL_INFO, { code: referrerCode });
      }
    } catch (e) {
      console.error(e);
    }
  }

  @clientOnly()
  private initReferrerFromSession() {
    let _referrer: any = getFromSessionStorage(KEY_REFERRAL_INFO);
    if (!_referrer || !_referrer.code) return;

    this.auth.getReferrerInfo(_referrer.code).subscribe((response: any) => {
      if (response && response.data && response.data.student) {
        this.initReferrer(response.data.student, _referrer.code);
        setToSessionStorage(KEY_REFERRAL_INFO, this.referrer);
        // this.showReferredUser.emit({ show:true});
      }
    });
  }

  renewPass() {
    if (passTypeProducts.includes(this.passType)) {
      if (this.selectedPass?.id) {
        let tab = 'globalPass';
        if (this.passType === 'passPro') {
          tab = 'passPro';
        }
        let url = `/pass?trigger=quickPay&pid=${this.selectedPass?.id}&tab=${tab}`;
        if (this.selectedPass.coupon) {
          url += '&coupon=' + this.selectedPass.coupon;
        }
        this.platformService.redirect(url);
      } else {
        this.productAccessService.triggerPassPurchase({ passType: this.passType });
      }
    } else if (this.passType == 'goalSubs') {
      this.platformService.redirect(getGoalPlansPageUrl(this.goal?.slug), 302);
    }
  }
}
