
import {Injectable} from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {getDeferredPromise, IDeferPromise} from "@shared/utility/defer-promise";
import {getGoalPageUrl, getGoalPlansPageUrl} from "@shared/utility/goal-utility";
import {_passModel, goalPlan} from "@models/entity/passes/passes.adapter";
import {toShortFormat} from "@shared/utility/date-utilities";
import {TransferStateManager} from "@core/services/transferStateManager";
import {getGoals, showPricingModal} from "@core/application-state/app.actions";
import {selectGoals, selectStudent} from "@core/application-state/app.selectors";
import {Store} from "@ngrx/store";
import {retry, skipWhile, take} from "rxjs/operators";
import {PricingModalObj} from "@shared/utility/constants";
import { StudentService } from './student.service';

@Injectable()
export class ProductAccessService {
    constructor(private platformService: PlatformService,private studentService:StudentService, private transferStateManager: TransferStateManager, private store: Store) {}
    goals$: any = this.store.select(selectGoals);
    getGoalPageOrDashboardUrl(goalId, goalSlug){
        const dPromise = getDeferredPromise();
        this.hasGoalSubscription(goalId, true).then( hasAccess => {
            if (hasAccess){
                return dPromise.resolve(`/super-coaching/${goalSlug}/dashboard`);
            } else {
                return dPromise.resolve(`/${goalSlug}-coaching`);
            }
        });
        return dPromise.promise;
    }

    getPlansPageUrlFromCurrentGoal(){
        const dPromise = getDeferredPromise();
        this.studentService.loadStudent().then((student: any) => {
            if(!student.currentGoal) {
                dPromise.resolve('/super-coaching');
            } else {
                let currentGoal;
                this.goals$.subscribe(goals => {
                    currentGoal = goals.find(goal => goal.id === student.currentGoal);
                    const planPageUrl = getGoalPlansPageUrl(currentGoal.properties.slug);
                    dPromise.resolve(planPageUrl);
                });
            }
        }).catch(() => {
            dPromise.resolve('/super-coaching');
        });
        return dPromise.promise;
    }

    hasMultiplePurchasedGoals(){
        let dPromise = getDeferredPromise();
        if(!this.platformService.isLoggedIn()) {
            dPromise.resolve(false);
            return dPromise.promise;
        }
        if (!isServer()) {
            this.studentService.loadStudent().then((student: any) => {
                if(!student.goalPlanState || Object.keys(student.goalPlanState).length < 3) {
                    dPromise.resolve(false);
                } else {
                    dPromise.resolve(true);
                }
            }).catch(() => {
                dPromise.resolve(false);
            });
            return dPromise.promise;
        } else {
            dPromise.resolve(false);
            return dPromise.promise;
        }
    }

    getMultiplePurchasedGoals(){
        let dPromise = getDeferredPromise();
        let allGoals: any;
        let goalPlanState  = {};
        let purchasedGoals = [];
        this.transferStateManager.get('allGoals', getGoals(), this.goals$,(value) => {
            if(value && value?.goals?.length){
                allGoals = value.goals;
                let goalMap = {};
                allGoals.forEach(goal => {
                    goalMap[goal.id] = goal;
                });
                this.store.select(selectStudent).pipe(skipWhile( s => !s || s.id == "-1"),take(1)).subscribe(student => {
                    if(student.id === -1){
                        dPromise.reject(purchasedGoals);
                    }
                    if(student?.goalSubs?.length){
                        let currDate = new Date();
                        student?.goalSubs.forEach(sub => {
                            goalPlanState[sub.goalId] = goalPlan.getStuPassState(sub);
                            let isActive = new Date(sub.expiry) >= currDate;
                            let goal: any  = goalMap[sub.goalId];
                            if(goal?.id && isActive) {
                                purchasedGoals.push({
                                    ...goal,
                                    expiry: toShortFormat(new Date(sub.expiry)),
                                    isActive,
                                    pageSlug: getGoalPageUrl(goal?.properties?.slug)
                                });
                            }
                        });
                        dPromise.resolve(purchasedGoals);
                    } else {
                        dPromise.reject(purchasedGoals);
                    }
                });
            } else {
                dPromise.reject(purchasedGoals);
            }
        });
        return dPromise.promise;
    }

    hasGoalSubscription(goalId, skipRefetch= false){
        let dPromise = getDeferredPromise();
        if(!this.platformService.isLoggedIn()) {
            dPromise.resolve(false);
            return dPromise.promise;
        }
        let goalPlanStateStr = "";
        //let goalPlanStateStr = this.platformService.getCookie(TB_GOALS_PURCHASED);

        if(false && isServer() && goalPlanStateStr){
            let goalPlanState;
            try{
                goalPlanState = goalPlan.allPlansDeserialsier(goalPlanStateStr);
            } catch(e){
                console.info("error parsing goalPlanStateStr" ,e);
            }
            if(!goalPlanState || !goalPlanState[goalId] || !goalPlan.isPassActiveByState(goalPlanState[goalId],true)) {
                dPromise.resolve(false);
            } else {
                dPromise.resolve(true);
            }
            return dPromise.promise;
        } else if (!isServer()) {
            this.studentService.loadStudent(skipRefetch).then((student: any) => {
                if(!student.goalPlanState || !student.goalPlanState[goalId] || !goalPlan.isPassActiveByState(student.goalPlanState[goalId],true)) {
                    dPromise.resolve(false);
                } else {
                    dPromise.resolve(true);
                }
            }).catch(() => {
                dPromise.resolve(false);
            });
            return dPromise.promise;
        } else {
            dPromise.resolve(false);
            return dPromise.promise;
        }
    }
    triggerPassPurchase(pricingModalObj: PricingModalObj, redirectUrl = '',newTab=false){
        if(!pricingModalObj?.passType && !redirectUrl){return;}

        if(redirectUrl){
            if(newTab){
                window.open(redirectUrl,'_.blank');
            }else{
                this.platformService.redirect(redirectUrl, 302, true);
            }
        } else{
            if(!isServer()){
                let hasPricingModal = !!document.querySelector("pricing-modal");
                if(hasPricingModal){
                    this.store.dispatch(showPricingModal(pricingModalObj));
                } else {
                    if(pricingModalObj?.passType === "globalPass"){
                        this.platformService.redirect('/pass', 302,true);
                    } else if(pricingModalObj?.passType === "passPro"){
                        this.platformService.redirect('/pass-pro', 302, true);
                    }
                }
            } else {
                this.store.dispatch(showPricingModal(pricingModalObj));
            }
        }
    }

    passAndPassProActiveState(){
        const dPromise = getDeferredPromise();
        let passAccess = false;
        let passProAccess = false;
        let goalSubsAccess = false;
        let passEliteAccess = false;
        if(!this.platformService.isLoggedIn()){
            dPromise.resolve({passAccess, passProAccess});
            return dPromise.promise;
        }
        this.studentService.loadStudent(true).then(student => {
            if (student && student.globalPassState && student.passProState) {
                const passAccess = _passModel.isPassActive(student.globalPassExpiry, true);
                const passProAccess = _passModel.isPassActive(student.passProExpiry, true);
                const goalSubs = student.goalPlanState || {};
        
                for (let key in goalSubs) {
                    const goalSub = goalSubs[key];
                    if (!(goalSub.passUnpurchased || goalSub.passExpired)) {
                        goalSubsAccess = true;
                        if (goalSub.passEliteAccess) {
                            passEliteAccess = true;
                            break;
                        }
                    }
                }
        
                dPromise.resolve({ passAccess, passProAccess, goalSubsAccess, passEliteAccess });
            }
        });
        return dPromise.promise;
    }

    passEliteArrayState(){
        const dPromise = getDeferredPromise();
        let passElitePurchaseArray=[];
        this.studentService.loadStudent(true).then(student => {
            if (student && student.globalPassState && student.passProState) {
                const goalSubs = student.goalPlanState || {};
                for (let key in goalSubs) {
                    const goalSub = goalSubs[key];
                    if (!(goalSub.passUnpurchased || goalSub.passExpired)) {
                        if (goalSub.passEliteAccess) {
                            passElitePurchaseArray.push(goalSubs[key])
                        }
                    }
                }
        
                dPromise.resolve({passElitePurchaseArray });
            }
        });
        return dPromise.promise;
    }

    isPassEliteGoalAccess(goalIds: Array<string>){
        if(!goalIds.length){
            return Promise.resolve(false);
        }
        const dPromise = getDeferredPromise();
        let goalIdsMap={};
        goalIds.forEach(item => {
            goalIdsMap[item]=true
        })
        this.passEliteArrayState().then(access => {
            if (access?.passElitePurchaseArray?.some?.(state => state?.id && goalIdsMap[state?.id])) {
                dPromise.resolve(true);
            } else {
                dPromise.resolve(false)
            }
        })
        return dPromise.promise;

    }

    passEliteGoalAccess(goalId: string) {
        if (!goalId) {
            return Promise.resolve(false);
        }
    
        const dPromise = getDeferredPromise();
        const goalIdMap = { [goalId]: true }; // Map with the single goalId
    
        this.passEliteArrayState().then(access => {
            // Check if any state contains the goalId
            if (access?.passElitePurchaseArray?.some?.(state => state?.id && goalIdMap[state?.id])) {
                const matchedState = access.passElitePurchaseArray.find(state => state?.id === goalId);
                dPromise.resolve(matchedState); // Return the full object for the goalId
            } else {
                dPromise.resolve(null); // Return null if not found
            }
        });
    
        return dPromise.promise;
    }
    

    getSubcategoryOfUser(goalId){
        const dPromise = getDeferredPromise();
        let subCategory = '';
        if(!this.platformService.isLoggedIn()){
            dPromise.resolve({subCategory});
            return dPromise.promise;
        }
        this.studentService.loadStudent(true).then(student => {
            const goalObj = student?.goalSubs?.find(goal => goal.id===goalId);
            let isExpired = false;
            if(goalObj?.expiry) {
                isExpired = new Date(goalObj?.expiry) < new Date();
            }
            if(!goalObj) {
                subCategory = 'NewUser';
            }
            else if(goalObj?.isTrial) {
                subCategory = 'TrialUser';
            }
            else if(isExpired && !goalObj?.isTrial) {
                subCategory = 'RenewalUser';
            }
            else if(!isExpired) {
                subCategory = 'ExtensionUser';
            }
            dPromise.resolve({subCategory});
        });
        return dPromise.promise;
    }
}
