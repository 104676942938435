import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ModelManager} from "../services/model-manager.service";
import {DataRecipe, PersistentDataRecipe, ToBackendRecipe} from "../services/model-manager/recipe";
import { student, studentMeApi, studentProfileAPI} from "../../models/entity/student/student.adapter";
import {EMPTY, Observable, of, zip} from "rxjs";
import {switchMap, map, catchError, mergeMap} from 'rxjs/operators';
import {
    deleteNotification,
    deleteNotificationSuccess,
    getNotifications,
    getNotificationsCount,
    getNotificationsCountSuccess,
    getNotificationsSuccess,
    getPageSeo,
    getPageSeoLoaded,
    getPasses,
    getPromotionBanner,
    getPromotionBannerSuccess,
    getSearchSuggestions,
    getSearchSuggestionsSuccess,
    getStudentMe,
    getTargetsOfSuperGroup,
    getTargetsOfSuperGroupSuccess,
    getTargetSuperGroups,
    getTargetSuperGroupsSuccess,
    passesLoaded,
    storeSearchSuggestion,
    studentMeLoaded,
    getProductEntityCount,
    productEntityCountSuccess,
    getPypTargetsOfSuperGroup,
    getPypTargetsOfSuperGroupSuccess,
    getPypTargetSuperGroups,
    getPypTargetSuperGroupsSuccess,
    registerModule,
    getPassPageInfo,
    passPageInfoLoaded,
    getGoals,
    registerModuleSuccess,
    getSeoGroupSubFooter,
    getSubFooterLoaded,
    getMultiAppJson,
    getMultiAppJsonSuccess,
    setMasterSeriesAccess,
    setMasterSeriesAccessSuccess,
    createMasterLessonReminder,
    MasterLessonReminderSuccess,
    getMasterSeriesEnrollmentDetails,
    masterSeriesEnrollmentDetailsLoaded,
    getGoalPlans,
    goalPlansLoaded,
    goalLoaded,
    getTestSeriesByGoalId,
    getTestSeriesByGoalIdSuccess,
    getGoalBySlug,
    getGoalById,
    getGoalInfoSuccess,
    getEnrolledTestSeriesByGoalId,
    getEnrolledTestSeriesByGoalIdSuccess,
    getSuggestedTestByGoalId,
    getSuggestedTestByGoalIdSuccess,
    getSearchedTestSeries,
    getSearchedTestSeriesSuccess,
    getPypByGoal,
    getPypByGoalSuccess,
    getCustomTagsByGoal,
    getCustomTagsByGoalSuccess,
    getGoalPageSequence,
    getGoalPageSequenceSuccess,
    getEducatorData,
    getFacultyData,
    getEducatorDataLoaded,
    getFacultyDataLoaded,
    setEducatorsFollow,
    setEducatorsFollowStatus,
    setEducatorsUnfollow,
    markTestSeriesEnrolled,
    markTestSeriesUnEnrolled,
    getSearchedPypTargets,
    getSearchedPypTargetsSuccess,
    getPassProducts,
    getPassProductsLoaded,
    getGoalPitchMap,
    getGoalPitchMapLoaded,
    getClassesById,
    getClassesByIdSuccess,
    getMasterSeriesByGoalId,
    getMasterSeriesByGoalIdLoaded,
    getGoalCourses,
    getGoalCoursesLoaded,
    getSuperTeachersModules,
    getSuperTeachersModulesLoaded,
    getResumeFlowActivitiesLoaded,
    getResumeFlowActivities,
    getGoalPageBanner,
    getGoalPageBannerLoaded,
    getGlobalPopupBanner,
    getGlobalPopupBannerLoaded,
    getPurchaseState,
    getSeoMenuById,
    getSeoMenuByIdLoaded,
    getPurchaseStateLoaded,
    getCloudBucketData,
    getCloudBucketDataLoaded,
    getPersonalisedBannerData,
    getPersonalisedBannerDataLoaded,
    getExamCalendar,
    getExamCalendarLoaded,
    showPricingModal,
    setPricingModalState,
    getRecommendedGoalPlan,
    recommendedGoalPlanLoaded,
    getSimilarGoalPacks,
    getSimilarGoalPacksLoaded,
    getJarvisBanner,
    getJarvisBannerLoaded,
    getPassSaleBanner,
    getPassSaleBannerLoaded,
    sendClientErrorLogToBackend,
    sendClientErrorLogToBackendLoaded,
    getDailyCurrentAffairs,
    dailyCurrentAffairsLoaded,
    getMonthlyCurrentAffairs,
    monthlyCurrentAffairsLoaded,
    getPopupData,
    getPopupDataLoaded,
    getPassProPageWidgets,
    passProPageWidgetsLoaded,
    getPassProPageInfo,
    passProPageInfoLoaded,
    setStudentGCLID,
    setStudentGCLIDLoaded,
    getSelectedTab,
    getSelectedTabLoaded,
    getPassEliteCard,
    getPassEliteCardLoaded,
    getGoalFreeTest,
    getGoalFreeTestLoaded, getPassElitePageDetails, getPassElitePageDetailsLoaded,
    getAttemptedTests,
    getAttemptedTestsSuccess,
    passElitePitch,
    passElitePitchLoaded,
} from "./app.actions";
import { getGoalPlanDetailsApi, getPassesApi, getPassProductsApi, globalPass, goalPlan, GoalPlanDetails, passesData, passProducts, PassProductsDetails } from "@models/entity/passes/passes.adapter";
import { EducatorsModulesWrapper, EducatorWrapper,  GetEducatorApi, GetEducatorsModulesApi, SetEducatorFollowDetails, GetFacultyApi, FacultyWrapper} from "@angular-commons/models/entity/educators/educator.adapter";
import { productEntityCountApi, productEntityCountData } from "@models/entity/product-entity-count/product-entity-count-adapter";
import { _SubFooterWrapperModel, GetPageSeoApi, GetSubFooterApi, PageSeoModel, SubFooterWrapperModel } from "@models/entity/page-seo/page-seo.adapter";
import {GetTargetSuperGroupsApi, TargetSuperGroupWrapper} from "@models/entity/targets/target-supergroup.adapter";
import { _PypTargetSlugWrapper, GetPypTargetsFromSupergroupApi, GetTargetsFromSupergroupApi, PypTargetSlugWrapper, TargetSlugWrapper
} from "@models/entity/targets/target.slug.adapter";
import { BannerModel, BannerWrapper, GetHomePagePromotionsBanner, GetPromotionsBannerData } from "@models/entity/promotion-banner/banner.adapter";
import { _NotificationModel, DeleteNotificationApi, DeleteNotificationParams, GetNotificationsApi, GetNotificationsCountApi, NotificationCountModel, NotificationModel } from "@models/entity/notifications/notification.model";
import {Dummy} from "@models/entity/doubts/doubts.answers.adapter";
import { GetSearchSuggestionsAPI, SearchSuggestions, StoreSearchSuggestionParams, StoreSearchSuggestionsAPI } from "@models/entity/search/suggestions.adapter";
import {RegisterModuleApi} from "@models/entity/tests/test.adapter";
import {IdentityAdapter} from "@models/mapping-framework";
import {passDetails,_passDetails,getPassDetailsApi} from "@models/entity/passes/passPage-details.adapter";
import { HttpClient } from '@angular/common/http';
import { getMultiAppJsonUrl } from '@angular-commons/shared/utility/android-app-utilities';
import { getMasterSeriesEnrollmentApi, masterLessonsReminderApi, MasterSeriesEnrollment, setMasterSeriesAccessApi } from '@angular-commons/models/entity/master-series/master-series.adapter';
import { _GlobalPopupBanner, _GoalPageBanner, ComboGoalPacks, GetAllGoalsApi, GetComboGoalPacksApi, GetGlobalPopupBannerApi,
    GetGoalDetailsApi, GetGoalDetailsBySlugApi, GetGoalPageBannerApi, GetGoalPitchMapApi, GoalWrapper, PassSaleBanner,
    PitchMap, SimpleGoals } from "@models/entity/goals/goals.adapter";
import { EnrollInTestSeriesApi, enrollUnenrollParams, GetEnrolledTestSeriesByGoalIdApi, GetSuggestedTestByGoalApi,
  GetTestSeriesByGoalIdApi, GoalEnrolledTestSeries, GoalTestSeries, SuggestedTest, UnEnrollInTestSeriesApi } from "@models/entity/test-series/test-series.adapter";
import {IndividualSearchApi, SearchModel} from "@models/entity/search/individual-search.adapter";
import {GetPypTargetsByGoalApi} from "@models/entity/targets/target.adapter";
import {_TargetDetailsWrapper} from "@models/entity/targets/target.adapter";
import {DAY, HOUR} from "@shared/utility/date-utilities";
import {Action} from "@ngrx/store";
import {CustomTags, GetCustomTagsByGoalApi} from "@models/entity/goals/custom-tags.adapter";
import {GoalPageSequenceApi, GoalPageSequenceWrapper} from "@models/entity/goals/goal-page-sequence.adapter";
import { getDefaultSequenceData } from '@angular-commons/shared/utility/sequence-utility';
import { PlatformService } from '../services/platform-service';
import {GetClassesByIdApi, GoalClasses} from '@models/entity/classes/goal-based-classes.adapter';
import {_OngoingMasterSeriesWrapper, GetOngoingMasterSeriesApi} from "@models/entity/master-class/master-class.adapter";
import {hideLoader, isServer, showAlert} from "@shared/utility/tb-common";
import { EnrolledCourseWrapper, GetEnrolledCoachingCourseApi } from '@angular-commons/models/entity/coaching-course/coaching-course.adapter';

import {PurchaseState, PurchaseStateApi} from "@models/entity/purchaseState";
import {GetSeoMenuApi, SeoMenuModelWrapper} from "@models/entity/page-seo/seo-menu.adapter";
import {GetCloudBucketDataApi} from "@models/entity/cloud-data/cloud-data.adapter";
import {GetPersonalisedBannerDataApi} from "@models/entity/personalised-banner-data/personalised-banner.adapter";
import {getGoalPlansPageUrl, getGoalSelectionPageUrl} from "@shared/utility/goal-utility";
import {Router} from "@angular/router";
import {AuthenticationService} from "@core/services/authentication.service";
import { ExamCalendar, ExamModel, GetExamCalendarApi } from '@angular-commons/models/entity/exams/exams.adapter';
import { ClientErrorLogToBackendApi } from '@angular-commons/models/entity/error/error-debugging.adapter';
import { CurrentAffairsNotes, DailyCurrentAffairsApi, MonthlyCurrentAffairsApi } from "@models/entity/current-affairs/current-affairs.adapter";
import { GetPopupDataApi } from '@angular-commons/models/entity/popup-wrapper/popup-wrapper.adapter';
import {getPassProDetailsApi, PassProDetails, PassProWidget} from "@models/entity/passes/pass-pro-adapter";
import {commonDataApi} from "@models/entity/common-data/common-data.adapter";
import {transformToTrackerResultArray} from "@shared/utility/tracker-utils";
import {TrackerService} from "@core/services/tracker.service";
import {getGoalFreeTestApi, getPassEliteCardApi, getPassElitePageDetailsApi, goalFreeTestDetails, passElitePageDetails} from "@models/entity/passes/pass-elite-adapter";
import { _TestSubmissions, GetAttemptedTestApi, TestSubmissions } from '@angular-commons/models/entity/targets/target.test.adapter';

@Injectable()
export class AppEffects{
    studentMeRecipe             : DataRecipe;
    passesRecipe                : DataRecipe;
    pageSeoRecipe               : DataRecipe;
    subFooter                   : DataRecipe;
    targetSupersGroupsRecipe    : DataRecipe;
    pypTargetSupersGroupsRecipe : DataRecipe;
    targetsRecipe               : DataRecipe;
    pypTargetsRecipe            : DataRecipe;
    promoBannerRecipe           : DataRecipe;
    calendarDataRecipe          : DataRecipe;
    fetchTargetRecipe           : DataRecipe;
    productEntityCountRecipe    : DataRecipe;
    passPageInfo                : DataRecipe;
    getGoalBySlug               : DataRecipe;
    getGoalById                 : DataRecipe;
    notificationsRecipe         : DataRecipe;
    notificationsCountRecipe    : DataRecipe;

    getGoals                    : PersistentDataRecipe;
    getGoalPlansRecipe          : DataRecipe;
    getRecommendedGoalPlansRecipe          : DataRecipe;
    getPassProductsRecipe           : DataRecipe;
    getTestSeriesByGoalRecipe          : DataRecipe;
    getEnrolledTestSeriesByGoalRecipe          : DataRecipe;
    getSuggestedTestByGoalId          : DataRecipe;
    getSearchedTestSeriesRecipe          : DataRecipe;
    getSearchedPypTargetsRecipe          : DataRecipe;
    getPypByGoalRecipe          : DataRecipe;
    getCustomTagsByGoalRecipe          : DataRecipe;
    searchSuggestionsRecipe     : DataRecipe;
    getGoalPageSequenceRecipe     : DataRecipe;
    educatorRecipe              : DataRecipe;
    facultyRecipe              : DataRecipe;
    getClassesByIdRecipe: DataRecipe;
    getMasterSeriesByIdRecipe: DataRecipe;
    goalPitchMapRecipe : PersistentDataRecipe;
    getGoalCoursesRecipe : DataRecipe;
    getSuperTeachersModulesRecipe: DataRecipe;

    deleteNotificationRecipe    : ToBackendRecipe;
    storeSearchSuggestions      : ToBackendRecipe;
    registerModuleRecipe        : ToBackendRecipe;
    setEducatorFollow        : ToBackendRecipe;
    setEducatorsUnfollow    :   ToBackendRecipe;
    setStudentProfile : ToBackendRecipe;

    setMasterSeriesAccessRecipe : ToBackendRecipe;
    markTestSeriesEnrolledRecipe      : ToBackendRecipe;
    markTestSeriesUnEnrolledRecipe    : ToBackendRecipe;
    getGoalPageBannerRecipe: DataRecipe;
    getCloudBucketDataRecipe: DataRecipe;
    getGlobalPopupBannerRecipe: DataRecipe;
    getPurchaseStateRecipe: DataRecipe;
    getSeoMenuByRecipe: DataRecipe;
    getPersonalisedBannerDataRecipe: DataRecipe;
    getSimilarGoalPacksRecipe: DataRecipe;
    getJarvisBannerRecipe: DataRecipe;
    getPassSaleBannerRecipe: DataRecipe;
    getClientErrorLogToBackendRecipe: DataRecipe;
    getDailyCurrentAffairsRecipe: DataRecipe;
    getMonthlyCurrentAffairsRecipe: DataRecipe;
    getPopupDataRecipe: DataRecipe;
    passProPageWidget: DataRecipe;
    passProPageInfo: DataRecipe;
    getAutoSelectedTabRecipe: DataRecipe;
    getPassEliteCardRecipe: DataRecipe;
    getGoalFreeTestRecipe: DataRecipe;
    getPassElitePageDetailRecipe: DataRecipe;
    fetchAttemptedTestsRecipe: DataRecipe;

    loadStudentMe$ = createEffect(() => this.action$.pipe(
        ofType(getStudentMe),
        switchMap((action) => {
            if(isServer()){
                return EMPTY;
            }
        return this.studentMeRecipe.get({}).pipe(
                map((data: any) => studentMeLoaded({payload: data})),
                catchError((err)=> {
                    if(err?.error && err?.error.hasOwnProperty('success') && !err?.error?.success){
                        this.platformService.redirect('logout',302,true)
                    }
                    return EMPTY;
                })
            );
        })
    ));

    loadPasses$ = createEffect(() => this.action$.pipe(
        ofType(getPasses),
        mergeMap((action) => {
            return this.passesRecipe.get(action).pipe(
                map((data: any) => passesLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadPassDetails$ = createEffect(() => this.action$.pipe(
        ofType(getPassPageInfo),
        mergeMap((action) => {
            return this.passPageInfo.get({}).pipe(
                map((data: any) => passPageInfoLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadProductEntityCount$ = createEffect(() => this.action$.pipe(
        ofType(getProductEntityCount),
        mergeMap((action) => {
            return this.productEntityCountRecipe.get({}).pipe(
                map((data: any) => productEntityCountSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));


    loadPageSeo$ = createEffect(() => this.action$.pipe(
        ofType(getPageSeo),
        switchMap((action) => {
            return this.pageSeoRecipe.get(action).pipe(
                map((data: any) => getPageSeoLoaded({payload: data})),
                catchError(() => EMPTY)
            );
        })
    ));

    loadSeoGroupSubFooter$ = createEffect(() => this.action$.pipe(
        ofType(getSeoGroupSubFooter),
        switchMap((action) => {
            return this.subFooter.get(action).pipe(
                map((data: any) => getSubFooterLoaded({payload: data})),
                catchError(() => of(getPageSeo({pathname: action.pathname})))
            );
        })
    ));

    loadSuperGroups$ = createEffect(() => this.action$.pipe(
        ofType(getTargetSuperGroups),
        switchMap((action) => {
            return this.targetSupersGroupsRecipe.get({}).pipe(
                switchMap((data: TargetSuperGroupWrapper) => {
                    //Loading the targets of first supergroup immediately after getting response.
                    return [getTargetsOfSuperGroup({superGroupId: data.superGroup[0]._id}), getTargetSuperGroupsSuccess({payload: data})];
                }),
                catchError(() => EMPTY)
            )
        })
    ));

    loadPypSuperGroups$ = createEffect(() => this.action$.pipe(
        ofType(getPypTargetSuperGroups),
        switchMap((action) => {
            return this.pypTargetSupersGroupsRecipe.get(action).pipe(
                switchMap((data: TargetSuperGroupWrapper) => {
                    // Loading the targets of first supergroup immediately after getting response.
                    return [getPypTargetsOfSuperGroup({superGroupId: data.superGroup[0]._id, pageType: action.pageType}), getPypTargetSuperGroupsSuccess({payload: data})];
                }),
                catchError(() => EMPTY)
            );
        })
    ));

    loadTargets$ = createEffect(() => this.action$.pipe(
        ofType(getTargetsOfSuperGroup),
        switchMap((action) => {
            return this.targetsRecipe.get(action).pipe(
                map((data: any) => getTargetsOfSuperGroupSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadPypTargets$ = createEffect(() => this.action$.pipe(
        ofType(getPypTargetsOfSuperGroup),
        switchMap((action) => {
            return this.pypTargetsRecipe.get(action).pipe(
                map((data: any) => getPypTargetsOfSuperGroupSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadPromoBanner$ = createEffect(() => this.action$.pipe(
        ofType(getPromotionBanner),
        switchMap((action) => {
            return this.promoBannerRecipe.get(action).pipe(
                map((data: BannerWrapper) => getPromotionBannerSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadNotifications$ = createEffect(() => this.action$.pipe(
        ofType(getNotifications),
        switchMap((action) => {
            return this.notificationsRecipe.get(action).pipe(
                map((data: NotificationModel) => getNotificationsSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadNotificationsCount$ = createEffect(() => this.action$.pipe(
        ofType(getNotificationsCount),
        switchMap((action) => {
            return this.notificationsCountRecipe.get(action).pipe(
                map((data: NotificationCountModel) => getNotificationsCountSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    deleteNotifications$ = createEffect(() => this.action$.pipe(
        ofType(deleteNotification),
        switchMap((action) => {
            return this.deleteNotificationRecipe.send(action).pipe(
                map((data: any) => deleteNotificationSuccess({payload: data, id: action?.id})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadSearchSuggestions$ = createEffect(() => this.action$.pipe(
        ofType(getSearchSuggestions),
        switchMap((action) => {
            return this.searchSuggestionsRecipe.get(action).pipe(
                map((data: any) => getSearchSuggestionsSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    storeSearchSuggestion$ = createEffect(() => this.action$.pipe(
        ofType(storeSearchSuggestion),
        switchMap((action) => {
            return this.storeSearchSuggestions.send(action);
        })
    ));

    postRegisterModule$ = createEffect( () => this.action$.pipe(
        ofType(registerModule),
        switchMap((action)=>{
            return this.registerModuleRecipe.send(action).pipe(
                map( (res) => registerModuleSuccess({payload:res})),
                catchError(() => EMPTY)
            );
        })
    ));

    postMasterSeriesAccess$ = createEffect( () => this.action$.pipe(
        ofType(setMasterSeriesAccess),
        switchMap((action)=>{
            return this.setMasterSeriesAccessRecipe.send(action).pipe(
                map( (res) => setMasterSeriesAccessSuccess({payload:res})),
                catchError(() => EMPTY)
            );
        })
    ));

    getMultiAppJson$ = createEffect(() => this.action$.pipe(
        ofType(getMultiAppJson),
        switchMap((action) => {
            return this.http.get(getMultiAppJsonUrl()).pipe(
                map((data: any) => getMultiAppJsonSuccess({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    masterLessonReminder : ToBackendRecipe

    masterLessonReminder$ = createEffect( () => this.action$.pipe(
        ofType(createMasterLessonReminder),
        switchMap((action)=>{
          return this.masterLessonReminder.send(action).pipe(
            map((data:any)=>MasterLessonReminderSuccess(action)),
            catchError( () =>EMPTY)
          )
        })
      ))

    masterSeriesEnrollmentRecipe:DataRecipe;

    masterSeriesEnrollment$ = createEffect( () => this.action$.pipe(
    ofType(getMasterSeriesEnrollmentDetails),
    switchMap((action)=>{
      if(isServer()){
          return EMPTY;
      }
      return this.masterSeriesEnrollmentRecipe.get(action).pipe(
        map((data:any) => masterSeriesEnrollmentDetailsLoaded({payload:data,id:action.id})),
        catchError(()=>EMPTY)
        )
      })
    ))

    getGoals$ = createEffect( () => this.action$.pipe(
        ofType(getGoals),
        switchMap((action)=>{
            return this.getGoals.get(action).pipe(
                map((data:any) => goalLoaded({payload:data})),
                catchError(()=>EMPTY)
            ) as (Observable<Action>)
        })
    ))

    getGoalPlans$ = createEffect( () => this.action$.pipe(
        ofType(getGoalPlans),
        mergeMap((action)=>{
            return this.getGoalPlansRecipe.get(action).pipe(
                map((data:any) => {
                    return goalPlansLoaded({payload:data} )
                }),
                catchError((err)=> {
                    return EMPTY;
                })
            )
        })
    ))

    getRecommendedGoalPlans$ = createEffect( () => this.action$.pipe(
        ofType(getRecommendedGoalPlan),
        mergeMap((action)=>{
            return this.getRecommendedGoalPlansRecipe.get(action).pipe(
                map((data:any) => {
                    return recommendedGoalPlanLoaded({payload:data} )
                }),
                catchError((err)=> {
                    return EMPTY;
                })
            )
        })
    ))



    getPassProduct$ = createEffect( () => this.action$.pipe(
        ofType(getPassProducts),
        mergeMap((action)=>{
            return this.getPassProductsRecipe.get(action).pipe(
                map((data:any) => {
                    return getPassProductsLoaded({payload:data} )
                }),
                catchError((err)=> {
                    return EMPTY;
                })
            )
        })
    ))

  getTestSeriesByGoalId$ = createEffect( () => this.action$.pipe(
        ofType(getTestSeriesByGoalId),
        switchMap((action) => {
            return this.getTestSeriesByGoalRecipe.get(action).pipe(
                map((data: any) => getTestSeriesByGoalIdSuccess({
                  testSeries: data.testSeries,
                  goalId : action.goalId,
                  skip : action.skip,
                  limit : action.limit
                })),

                catchError(() => EMPTY)
            );
        })
    ));

    getEnrolledTestSeriesByGoalId$ = createEffect( () => this.action$.pipe(
        ofType(getEnrolledTestSeriesByGoalId),
        switchMap((action) => {
            if(isServer()){
                return EMPTY;
            }
            return this.getEnrolledTestSeriesByGoalRecipe.get(action).pipe(
                map((data: any) => getEnrolledTestSeriesByGoalIdSuccess({
                  testSeries: data.testSeries,
                  goalId : action.goalId,
                  skip : action.skip,
                  limit : action.limit
                })),
                catchError(() => EMPTY)
            );
        })
    ));

    getGoalInfoId$ = createEffect(()=> this.action$.pipe(
        ofType(getGoalById),
        switchMap((action)=> {
            if (!action.id){
                return EMPTY;
            }
            return this.getGoalById.get(action).pipe(
                map((data:any) => {
                    if(!data?.goal?.id) {
                        return getGoalInfoSuccess({payload: {failed:true,goal:{id:action.id,properties:{slug:action.id}}}});
                    }
                    return getGoalInfoSuccess({payload: data});
                }),
                catchError(() => { return of(getGoalInfoSuccess({payload: {failed:true,goal:{id:action.id,properties:{slug:action.id}}}})); })
            );
        })
        )
    );

    getGoalInfoSlug$ = createEffect(()=> this.action$.pipe(
        ofType(getGoalBySlug),
        mergeMap((action)=> {
            return this.getGoalBySlug.get(action).pipe(
                map((data:any) => {
                    if(!data?.goal?.id) {
                        return getGoalInfoSuccess({payload: {failed:true,goal:{id:action.slug,properties:{slug:action.slug}}}});
                    }
                    return getGoalInfoSuccess({payload: data});

                }),
                catchError(() => { return of(getGoalInfoSuccess({payload: {failed:true,goal:{id:action.slug,properties:{slug:action.slug}}}}));})
            );
        }))
    );

    getSuggestedTestByGoalId$ = createEffect(()=> this.action$.pipe(
          ofType(getSuggestedTestByGoalId),
          switchMap((action)=> {
              if (isServer()){
                  return EMPTY;
              }
              return this.getSuggestedTestByGoalId.get(action).pipe(
                  map((data) => {
                      return getSuggestedTestByGoalIdSuccess({payload: data});
                  }),
                  catchError(() => { return of(getSuggestedTestByGoalIdSuccess({payload: {}}));})
              );
          }))
      );

      loadFaculty$ = createEffect(() => this.action$.pipe(
        ofType(getFacultyData),
        mergeMap((action) => {
            return this.facultyRecipe.get(action).pipe(
                map((data: any) => {
                    return getFacultyDataLoaded({educatorData: data,ids:action.ids});
                }),
                catchError(() => {
                  return of(getFacultyDataLoaded({educatorData: {},ids:action.ids}));
                })
            )
        })
      ));

      loadEducator$ = createEffect(() => this.action$.pipe(
        ofType(getEducatorData),
        switchMap((action) => {
            return this.educatorRecipe.get(action).pipe(
                map((data: any) => {
                    return getEducatorDataLoaded({educatorData: data,idOrSlug:action.idOrSlug});
                }),
                catchError(() => {
                  return of(getEducatorDataLoaded({educatorData: {},idOrSlug:action.idOrSlug}));
                })
            )
        })
      ));

      setEducatorFollow$ =createEffect ( ()=> this.action$.pipe(
        ofType(setEducatorsFollow),
        switchMap((action)=> {
            return this.setEducatorFollow.send(action).pipe(
                map( () => setEducatorsFollowStatus({followStatus:true})),
                catchError(() => EMPTY)
            )
        })
      ));

      setEducatorUnfollow$ =createEffect ( ()=> this.action$.pipe(
        ofType(setEducatorsUnfollow),
        switchMap((action)=> {
            return this.setEducatorsUnfollow.send(action).pipe(
                map( () => setEducatorsFollowStatus({followStatus:false})),
                catchError(() => EMPTY)
            )
        })
      ));

      setStudentGCLID$ = createEffect(() => this.action$.pipe(
        ofType(setStudentGCLID),
        mergeMap((action) => {
            return this.setStudentProfile.send(action).pipe(
                map((data: any) => setStudentGCLIDLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
      ));

      getSearchedTestSeries$ = createEffect( () => this.action$.pipe(
          ofType(getSearchedTestSeries),
          switchMap((action) => {
            return this.getSearchedTestSeriesRecipe.get(action).pipe(
              mergeMap((data: any) => {
                  this.trackerService.setSearchResultTracker({
                      id: data.searchId,
                      q: action?.searchParams?.term,
                      sid: action?.studentId,
                      cmp: action?.component,
                      url: this.platformService.href,
                      lang: this.platformService.getSiteLang(),
                      rtm: new Date().getTime(),
                      rfr: this.platformService.getPathName(),
                      rslt: transformToTrackerResultArray(data.results, 'details'),

                      skip: action?.skip,
                      limit: action?.limit
                  });
                  return [getSearchedTestSeriesSuccess({payload: data})]

              }),
              catchError(() => EMPTY)
            );
          })
      ));

      getSearchedPypTargets$ = createEffect( () => this.action$.pipe(
          ofType(getSearchedPypTargets),
          switchMap((action) => {
            return this.getSearchedPypTargetsRecipe.get(action).pipe(
              map((data: any) => getSearchedPypTargetsSuccess({payload: data})),
              catchError(() => EMPTY)
            );
          })
      ));

      getCustomTagsByGoal$ = createEffect( () => this.action$.pipe(
          ofType(getCustomTagsByGoal),
          switchMap((action) => {
            return this.getCustomTagsByGoalRecipe.get(action).pipe(
              map((data: any) => getCustomTagsByGoalSuccess({payload: data})),
              catchError(() => EMPTY)
            );
          })
      ));

      getGoalPitchMap$ = createEffect( () => this.action$.pipe(
        ofType(getGoalPitchMap),
        switchMap((action) => {
          return this.goalPitchMapRecipe.get(action).pipe(
            map((data: any) => getGoalPitchMapLoaded({payload: data})),
            catchError(() => EMPTY)
          )as (Observable<Action>)
        })
    ));

    getPypByGoal$ = createEffect( () => this.action$.pipe(
          ofType(getPypByGoal),
          switchMap((action) => {
            return this.getPypByGoalRecipe.get(action).pipe(
              map((data: any) => getPypByGoalSuccess({
                targets: data.targets,
                goalId : action.goalId,
                skip : action.skip,
                limit : action.limit
              })),
              catchError(() => EMPTY)
            );
          })
      ));

      getGoalPageSequence$ = createEffect( () => this.action$.pipe(
        ofType(getGoalPageSequence),
        switchMap((action) => {
          return this.getGoalPageSequenceRecipe.get(action).pipe(
            map((data: any) => {
                let verifiedData = (data.sequence && data.sequence.length > 0) ? data : getDefaultSequenceData(action.pageType,this.platformService.isMobile());
                return getGoalPageSequenceSuccess({pageType: action.pageType,slug:action.slug, payload: verifiedData})
            }),
            catchError((err:any) => {
                return of(getGoalPageSequenceSuccess({pageType: action.pageType,slug:action.slug, payload: getDefaultSequenceData(action.pageType,this.platformService.isMobile())}))
            })
          );
        })
      ));

      markTestSeriesEnrolled$ = createEffect(() => this.action$.pipe(
        ofType(markTestSeriesEnrolled),
        switchMap((action) => {
          return this.markTestSeriesEnrolledRecipe.send(action).pipe(
            map((data: any) => {
                if(data?.success && !isServer()) {
                    window.location.reload();
                }
                return data;
            }),
            catchError((err) => {
                if(!isServer()){
                    hideLoader();
                    showAlert(err?.error?.message || 'Something went wrong', 'error', 'large', 2000);
                }
                return EMPTY;
            })
          )
        })
      ));

      markTestSeriesUnEnrolled$ = createEffect(() => this.action$.pipe(
        ofType(markTestSeriesUnEnrolled),
        switchMap((action) => {
          return this.markTestSeriesUnEnrolledRecipe.send(action).pipe(
            map((data: any) => {
                if(data?.success && !isServer()) {
                    window.location.reload();
                }
                return data;
            }),
            catchError((err) => {
                if(!isServer()){
                    hideLoader();
                    showAlert(err?.error?.message || 'Something went wrong', 'error', 'large', 2000);
                }
                return EMPTY;
            })
          )
        })
      ));

      loadGoalCourses$ = createEffect(() => this.action$.pipe(
        ofType(getGoalCourses),
        switchMap((action) => {
            return this.getGoalCoursesRecipe.get(action).pipe(
                map((data: any) => {
                      return getGoalCoursesLoaded({coursesData:data,goalIds:action.goalIds});
                })
            )
      })
      ));

      loadSuperTeachersModules$ = createEffect(() => this.action$.pipe(
        ofType(getSuperTeachersModules),
        switchMap((action) => {
            return this.getSuperTeachersModulesRecipe.get(action).pipe(
                map((data: any) => {
                      return getSuperTeachersModulesLoaded({teachersData:data,goalIds:action.goalIds});
                })
            )
      })
      ));
      loadResumeFlowActivity$ = createEffect(() => this.action$.pipe(
        ofType(getResumeFlowActivities),
        switchMap((action:any) => {
            let first = this.getSuperTeachersModulesRecipe.get({goalIds:action.goalIds})
            let second = (this.getGoalCoursesRecipe.get(action))
            let final = zip(first, second)
            return final.pipe(
                map((data: any) => {
                    return getResumeFlowActivitiesLoaded({teachersData:data[0], coursesData:data[1], gaolIds:action.goalIds});
                })
            )
        })
      ));

      getClassesByGoal$ = createEffect(() => this.action$.pipe(
          ofType(getClassesById),
          switchMap((action) => {
            return this.getClassesByIdRecipe.get(action).pipe(
              map((data) => {
                return getClassesByIdSuccess({payload: data, customTagId: action.customTagIds, goalIds: action.goalIds, viewMore: !!action.viewMore});
              }),
              catchError(() => EMPTY)
            );
          })
        )
      );

      loadMasterSeries$ = createEffect(() => this.action$.pipe(
        ofType(getMasterSeriesByGoalId),
        switchMap((action) => {
          return this.getMasterSeriesByIdRecipe.get(action.params).pipe(
            map((data: any) => getMasterSeriesByGoalIdLoaded({
              masterSeriesData : data,
              goalIds : action.params.goalIds,
              customTagIds : action.params.customTagIds || 'all',
              skip : action.params.skip,
              limit : action.params.limit
            })),

            catchError(() => EMPTY)
          );
        })
      ));

    loadGoalPageBanners$ = createEffect(() => this.action$.pipe(
        ofType(getGoalPageBanner),
        switchMap((action) => {
            return this.getGoalPageBannerRecipe.get(action).pipe(
                map((data: any) => getGoalPageBannerLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ))

    loadCloudBucketData$ = createEffect(() => this.action$.pipe(
        ofType(getCloudBucketData),
        mergeMap((action) => {
            return this.getCloudBucketDataRecipe.get(action).pipe(
                map((data: any) => getCloudBucketDataLoaded({payload: data, key: action.fileName})),
                catchError(() => EMPTY)
            )
        })
    ))

    loadPersonalisedBannerData$ = createEffect(() => this.action$.pipe(
        ofType(getPersonalisedBannerData),
        mergeMap((action) => {
            return this.getPersonalisedBannerDataRecipe.get(action).pipe(
                map((data: any) => getPersonalisedBannerDataLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ))

    laodCalendarData$ = createEffect(()=> this.action$.pipe(
        ofType(getExamCalendar),
        switchMap((action)=>{
            return this.calendarDataRecipe.get(action).pipe(
                map((data: any) =>
                    getExamCalendarLoaded({payload:data})),
                catchError(() => {
                    return EMPTY
                })
            );
        })
    ))

    loadGlobalPopupBanners$ = createEffect(() => this.action$.pipe(
        ofType(getGlobalPopupBanner),
        switchMap((action) => {
            return this.getGlobalPopupBannerRecipe.get(action).pipe(
                map((data: any) => getGlobalPopupBannerLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ))

    loadPurchaseState$ = createEffect(() => this.action$.pipe(
        ofType(getPurchaseState),
        switchMap((action) => {
            return this.getPurchaseStateRecipe.get(action).pipe(
                map((data: any) => getPurchaseStateLoaded({payload: data})),
                catchError(() => EMPTY)
            );
        })
    ));

    loadSeoMenu$ = createEffect(() => this.action$.pipe(
        ofType(getSeoMenuById),
        switchMap((action) => {
            return this.getSeoMenuByRecipe.get(action).pipe(
                map((data: any) => getSeoMenuByIdLoaded({payload: data?.menu})),
                catchError(() => EMPTY)
                );
            })
        ));
    loadSimilarGoalPacks$ = createEffect(() => this.action$.pipe(
        ofType(getSimilarGoalPacks),
        switchMap((action) => {
            return this.getSimilarGoalPacksRecipe.get(action?.params).pipe(
                map((data: any) => getSimilarGoalPacksLoaded({id: action?.params?.id, payload: data?.goalCards})),
                catchError(() => EMPTY)
            );
        })
    ));

    showPricingModalState$ = createEffect(() => this.action$.pipe(
        ofType(showPricingModal),
        switchMap((action) => {
            if (action.passType === 'goalSubs') {
                let goalSlug = action?.goalSlug;
                if(!action?.goalSlug){
                    goalSlug = this.auth.getGoal()?.slug;
                }
                if (goalSlug) {
                    this.router.navigateByUrl(getGoalPlansPageUrl(goalSlug));
                }
                else {
                    this.router.navigateByUrl(getGoalSelectionPageUrl());
                }
                return EMPTY;
            }

            return of(setPricingModalState( {...action}));
        })
    ));

    loadJarvisBanner$ = createEffect(() => this.action$.pipe(
        ofType(getJarvisBanner),
        switchMap((action) => {
            return this.getJarvisBannerRecipe.get(action.params).pipe(
                map((data: any) => {
                    return getJarvisBannerLoaded({key: action?.params?.page, payload: data});
                }),
                catchError(() => EMPTY)
            )
        })
    ))
    loadPassSaleBannerData = createEffect(() => this.action$.pipe(
        ofType(getPassSaleBanner),
        switchMap((action) => {
            return this.getPassSaleBannerRecipe.get(action).pipe(
                map((data: any) => {
                    return getPassSaleBannerLoaded({payload: data?.passSaleBanner});
                }),
                catchError(() => EMPTY)
            );
        })
    ));
    clientErrorLogToBackend = createEffect(() => this.action$.pipe(
        ofType(sendClientErrorLogToBackend),
        switchMap((action) => {
            return this.getClientErrorLogToBackendRecipe.get(action.payload).pipe(
                map((data: any) => {
                    return sendClientErrorLogToBackendLoaded({payload: data});
                }),
                catchError(() => EMPTY)
            );
        })
    ));

    loadPopupData$ = createEffect(() => this.action$.pipe(
        ofType(getPopupData),
        switchMap((action) => {
            return this.getPopupDataRecipe.get(action).pipe(
                map((data: any) => getPopupDataLoaded({payload: data.popup})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadPassProDetails$ = createEffect(() => this.action$.pipe(
        ofType(getPassProPageInfo),
        mergeMap((action) => {
            return this.passProPageInfo.get(action).pipe(
                map((data: any) => passProPageInfoLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));

    loadPassProWidgets$ = createEffect(() => this.action$.pipe(
        ofType(getPassProPageWidgets),
        mergeMap((action) => {
            return this.passProPageWidget.get(action).pipe(
                map((data: any) => passProPageWidgetsLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));


    loadDailyCurrentAffairs$ = createEffect(() => this.action$.pipe(
        ofType(getDailyCurrentAffairs),
        switchMap((action) => {
            return this.getDailyCurrentAffairsRecipe.get(action).pipe(
                map((data: any) => dailyCurrentAffairsLoaded({payload: data, tags: action?.tags, date: action?.date, viewMore: !!action?.viewMore})),
                catchError(() => of(dailyCurrentAffairsLoaded({payload: {notes: [], count: 0}, tags: action?.tags, date: action?.date, viewMore: !!action?.viewMore})))
            );
        })
    ));

    loadMonthlyCurrentAffairs$ = createEffect(() => this.action$.pipe(
        ofType(getMonthlyCurrentAffairs),
        switchMap((action) => {
            return this.getMonthlyCurrentAffairsRecipe.get(action).pipe(
                map((data: any) => monthlyCurrentAffairsLoaded({payload: data, date: action?.date, month: action?.month, year: action?.year, viewMore: !!action?.viewMore})),
                catchError(() => of(monthlyCurrentAffairsLoaded({payload: {notes: [], count: 0}, date: action?.date, month: action?.month, year: action?.year, viewMore: !!action?.viewMore})))
            );
        })
    ));

    loadAutoSelectedTab$ = createEffect(() => this.action$.pipe(
        ofType(getSelectedTab),
        switchMap((action) => {
            return this.getAutoSelectedTabRecipe.get(action).pipe(
                map((data: any) => getSelectedTabLoaded({payload: data})),
                catchError(() => EMPTY)
            );
        })
    ));

    loadPassEliteCard$ = createEffect(() => this.action$.pipe(
        ofType(getPassEliteCard),
        switchMap((action) => {
            return this.getPassEliteCardRecipe.get(action).pipe(
                map((data: any) => getPassEliteCardLoaded({payload: data})),
                catchError(() => EMPTY)
            );
        })
    ));

    loadGoalFreeTest$ = createEffect(() => this.action$.pipe(
        ofType(getGoalFreeTest),
        switchMap((action) => {
            return this.getGoalFreeTestRecipe.get(action).pipe(
                map((data: any) => {
                    return {payload: data};
                }),
                catchError(() => {
                    return of(getGoalFreeTestLoaded({payload: {}}));
                })
            );
        }),
        switchMap((data: any) => {
            if(this.platformService.isLoggedIn() && data && data.payload && data.payload.tests) {
              let testIds =  data.payload.tests.reduce((ids, item)=>{ids.push(item?.id); return ids}, []).join(',');
              if(testIds){
                return [
                    getGoalFreeTestLoaded(data),
                    getAttemptedTests({
                    testIds: testIds
                })];
              }else{
                return [getGoalFreeTestLoaded(data)];
              }
            }
            return of(getGoalFreeTestLoaded(data));

        })
    ));

    loadPassElitePageDetails$ = createEffect(() => this.action$.pipe(
        ofType(getPassElitePageDetails),
        switchMap((action) => {
            return this.getPassElitePageDetailRecipe.get(action).pipe(
                map((data: any) => getPassElitePageDetailsLoaded({payload: data})),
                catchError(() => EMPTY)
            );
        })
    ));

    getAttemptedTests$ = createEffect(() => this.action$.pipe(
        ofType(getAttemptedTests),
        mergeMap((action) => {
            if(isServer()){
                return EMPTY;
            }
            return this.fetchAttemptedTestsRecipe.get(action).pipe(
                map((data: any) => getAttemptedTestsSuccess({payload: data})),
                catchError(() => {
                    return of(getAttemptedTestsSuccess({payload: {}}));
                })
            )
        })
    ));

    passElitePitch$ = createEffect(() => this.action$.pipe(
        ofType(passElitePitch),
        switchMap((action) => {
            return this.passElitePitchRecipe.get(action).pipe(
                map((data: any) => passElitePitchLoaded({payload: data})),
                catchError(() => EMPTY)
            )
        })
    ));
    passElitePitchRecipe:DataRecipe;
   

  constructor(private modelManager: ModelManager,private action$ : Actions,private http: HttpClient,private platformService:PlatformService,
              private router: Router, private auth : AuthenticationService, private trackerService: TrackerService) {
        let globalPassModel = new globalPass();
        let goalPlanModel = new goalPlan();
        let passProductsModel = new passProducts();

        this.studentMeRecipe            = modelManager.makeDataRecipe(IdentityAdapter,student,studentMeApi,{
            optimistic : true,
            alwaysStale : true,
            cacheOnServer: false
        });
        this.passesRecipe               = modelManager.makeDataRecipe(globalPassModel,passesData,getPassesApi);

        this.pageSeoRecipe              = modelManager.makeDataRecipe(PageSeoModel, PageSeoModel, GetPageSeoApi);
        this.subFooter                  = modelManager.makeDataRecipe(SubFooterWrapperModel, _SubFooterWrapperModel, GetSubFooterApi);

        this.targetSupersGroupsRecipe   = modelManager.makeDataRecipe(TargetSuperGroupWrapper, TargetSuperGroupWrapper, GetTargetSuperGroupsApi);
        this.pypTargetSupersGroupsRecipe   = modelManager.makeDataRecipe(TargetSuperGroupWrapper, TargetSuperGroupWrapper, GetTargetSuperGroupsApi);
        this.pypTargetsRecipe           = modelManager.makeDataRecipe(_PypTargetSlugWrapper, PypTargetSlugWrapper, GetPypTargetsFromSupergroupApi);
        this.targetsRecipe              = modelManager.makeDataRecipe(TargetSlugWrapper, TargetSlugWrapper, GetTargetsFromSupergroupApi);

        this.promoBannerRecipe          = modelManager.makeDataRecipe(BannerWrapper, BannerWrapper, GetPromotionsBannerData, {optimistic: false, alwaysStale: true, cacheOnServer: true});

        this.calendarDataRecipe         = modelManager.makeDataRecipe(IdentityAdapter, ExamCalendar, GetExamCalendarApi);

        this.productEntityCountRecipe   = modelManager.makeDataRecipe(productEntityCountData, productEntityCountData, productEntityCountApi);

        this.notificationsRecipe        = modelManager.makeDataRecipe(_NotificationModel, NotificationModel, GetNotificationsApi);
        this.notificationsCountRecipe   = modelManager.makeDataRecipe(NotificationCountModel, NotificationCountModel, GetNotificationsCountApi);
        this.deleteNotificationRecipe   = modelManager.makeToBackendRecipe(Dummy, DeleteNotificationParams, DeleteNotificationApi);

        this.searchSuggestionsRecipe    = modelManager.makeDataRecipe(SearchSuggestions, SearchSuggestions, GetSearchSuggestionsAPI);
        this.storeSearchSuggestions     = modelManager.makeToBackendRecipe(Dummy, StoreSearchSuggestionParams, StoreSearchSuggestionsAPI);

        this.registerModuleRecipe       =   modelManager.makeToBackendRecipe({},IdentityAdapter,RegisterModuleApi)
        this.setMasterSeriesAccessRecipe = modelManager.makeToBackendRecipe({},IdentityAdapter,setMasterSeriesAccessApi)

        this.passPageInfo  = modelManager.makeDataRecipe(passDetails,_passDetails,getPassDetailsApi);
        this.masterLessonReminder = modelManager.makeToBackendRecipe({},IdentityAdapter,masterLessonsReminderApi);
        this.masterSeriesEnrollmentRecipe=modelManager.makeDataRecipe(IdentityAdapter,MasterSeriesEnrollment,getMasterSeriesEnrollmentApi,{
            optimistic : true,
            alwaysStale : true,
            cacheOnServer: false
        })

        this.getGoals                         = modelManager.makePersistentDataRecipe(SimpleGoals, GetAllGoalsApi).setValidity(HOUR);
        this.getGoalPlansRecipe               = modelManager.makeDataRecipe(goalPlanModel,GoalPlanDetails,getGoalPlanDetailsApi);
        this.getRecommendedGoalPlansRecipe               = modelManager.makeDataRecipe(goalPlanModel,GoalPlanDetails,getGoalPlanDetailsApi);
        this.getPassProductsRecipe                = modelManager.makeDataRecipe(passProductsModel,PassProductsDetails,getPassProductsApi);
        this.getTestSeriesByGoalRecipe        = modelManager.makeDataRecipe(IdentityAdapter, GoalTestSeries, GetTestSeriesByGoalIdApi);
        this.getEnrolledTestSeriesByGoalRecipe        = modelManager.makeDataRecipe(IdentityAdapter, GoalEnrolledTestSeries, GetEnrolledTestSeriesByGoalIdApi);

        this.getGoalById  = modelManager.makeDataRecipe(IdentityAdapter,GoalWrapper,GetGoalDetailsApi);
        this.getGoalBySlug  = modelManager.makeDataRecipe(IdentityAdapter,GoalWrapper,GetGoalDetailsBySlugApi);
        this.getSuggestedTestByGoalId  = modelManager.makeDataRecipe(IdentityAdapter, SuggestedTest, GetSuggestedTestByGoalApi);
        this.getSearchedTestSeriesRecipe = modelManager.makeDataRecipe(IdentityAdapter, SearchModel, IndividualSearchApi);
        this.getSearchedPypTargetsRecipe = modelManager.makeDataRecipe(IdentityAdapter, SearchModel, IndividualSearchApi);
        this.getPypByGoalRecipe = modelManager.makeDataRecipe(IdentityAdapter, _TargetDetailsWrapper, GetPypTargetsByGoalApi);
        this.getCustomTagsByGoalRecipe = modelManager.makeDataRecipe(IdentityAdapter, CustomTags, GetCustomTagsByGoalApi);
        this.getGoalPageSequenceRecipe = modelManager.makeDataRecipe(IdentityAdapter, GoalPageSequenceWrapper, GoalPageSequenceApi);
        this.educatorRecipe       =   modelManager.makeDataRecipe(IdentityAdapter,EducatorWrapper,GetEducatorApi);
        this.facultyRecipe       =   modelManager.makeDataRecipe(IdentityAdapter,FacultyWrapper,GetFacultyApi);
        this.setEducatorFollow = modelManager.makeToBackendRecipe({},IdentityAdapter,SetEducatorFollowDetails)
        this.setEducatorsUnfollow=modelManager.makeToBackendRecipe({},IdentityAdapter,SetEducatorFollowDetails)
        this.markTestSeriesEnrolledRecipe         =       modelManager.makeToBackendRecipe(Dummy, enrollUnenrollParams, EnrollInTestSeriesApi);
        this.markTestSeriesUnEnrolledRecipe       =       modelManager.makeToBackendRecipe(Dummy, enrollUnenrollParams, UnEnrollInTestSeriesApi);
        this.goalPitchMapRecipe = modelManager.makePersistentDataRecipe(PitchMap,GetGoalPitchMapApi).setValidity(DAY);
        this.getGoalCoursesRecipe=modelManager.makeDataRecipe(IdentityAdapter,EnrolledCourseWrapper,GetEnrolledCoachingCourseApi)
        this.getSuperTeachersModulesRecipe=modelManager.makeDataRecipe(IdentityAdapter,EducatorsModulesWrapper,GetEducatorsModulesApi)
        this.getClassesByIdRecipe = modelManager.makeDataRecipe(IdentityAdapter, GoalClasses, GetClassesByIdApi);
        this.getMasterSeriesByIdRecipe = modelManager.makeDataRecipe(IdentityAdapter, _OngoingMasterSeriesWrapper, GetOngoingMasterSeriesApi);
        this.getGoalPageBannerRecipe = modelManager.makeDataRecipe(IdentityAdapter, _GoalPageBanner, GetGoalPageBannerApi);
        this.getCloudBucketDataRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, GetCloudBucketDataApi);
        this.getGlobalPopupBannerRecipe = modelManager.makeDataRecipe(IdentityAdapter, _GlobalPopupBanner, GetGlobalPopupBannerApi);
        this.getPurchaseStateRecipe = modelManager.makeDataRecipe(IdentityAdapter, PurchaseState, PurchaseStateApi);
        this.getSeoMenuByRecipe = modelManager.makeDataRecipe(IdentityAdapter, SeoMenuModelWrapper, GetSeoMenuApi);
        this.getPersonalisedBannerDataRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, GetPersonalisedBannerDataApi,{
            optimistic : true,
            alwaysStale : true,
            cacheOnServer: false
        });
        this.getSimilarGoalPacksRecipe = modelManager.makeDataRecipe(IdentityAdapter, ComboGoalPacks, GetComboGoalPacksApi);
        this.getJarvisBannerRecipe = modelManager.makeDataRecipe(IdentityAdapter, BannerModel, GetHomePagePromotionsBanner);
        this.getPassSaleBannerRecipe = modelManager.makeDataRecipe(IdentityAdapter, PassSaleBanner, GetCloudBucketDataApi);
        this.getClientErrorLogToBackendRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, ClientErrorLogToBackendApi);
        this.getDailyCurrentAffairsRecipe = modelManager.makeDataRecipe(IdentityAdapter, CurrentAffairsNotes, DailyCurrentAffairsApi);
        this.getMonthlyCurrentAffairsRecipe = modelManager.makeDataRecipe(IdentityAdapter, CurrentAffairsNotes, MonthlyCurrentAffairsApi);
        this.getPopupDataRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, GetPopupDataApi);
        this.passProPageWidget = modelManager.makeDataRecipe(IdentityAdapter, PassProWidget, commonDataApi);
        this.passProPageInfo = modelManager.makeDataRecipe(IdentityAdapter, PassProDetails, getPassProDetailsApi);
        this.setStudentProfile = modelManager.makeToBackendRecipe(IdentityAdapter, IdentityAdapter, studentProfileAPI);
        this.getAutoSelectedTabRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, commonDataApi);
        this.getPassEliteCardRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, getPassEliteCardApi);
        this.getGoalFreeTestRecipe = modelManager.makeDataRecipe(IdentityAdapter, goalFreeTestDetails, getGoalFreeTestApi);
        this.getPassElitePageDetailRecipe = modelManager.makeDataRecipe(IdentityAdapter, passElitePageDetails, getPassElitePageDetailsApi);
        this.fetchAttemptedTestsRecipe    =   modelManager.makeDataRecipe(TestSubmissions, _TestSubmissions, GetAttemptedTestApi);
        this.passElitePitchRecipe = modelManager.makeDataRecipe(IdentityAdapter, IdentityAdapter, commonDataApi);

  }

}
