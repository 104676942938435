<header class="header" [ngClass]="{'open' : showSidebar, 'close' : !showSidebar, 'goals-show' : showGoalsPopup }">
    <div class="hamburger" (click)="toggleSideBar()"  [ngClass]="{'d-none': searchModalOpen}"><span></span></div>
    <div class="header__sidebar">
        <div class="logo">
            <a class="logo__testbook" routerLink="/home" routerLinkActive="active-link" target="_self">
            <svg width="120" height="27">
                <use xlink:href="#testbook-logo-white"></use>
            </svg>
        </a>
        </div>
        <ul class="header__sidebar__nav">
            <li>
                <a (click)="toggleSideBar()" routerLink="/home" routerLinkActive="active-link" target="_self" class="home" translate>
                    HEADER_HOME
                </a>
            </li>

            <li class="header__divider" translate>HEADER_LEARN</li>
            <li>
                <a [routerLink]="goalSelectionPageUrl" [ngClass]="{'active-link': activePageType === 'superCoaching'}" target="_self" class="coaching"  translate>
                    HEADER_SUPER_COACHING
                </a>
<!--                <a *ngIf="isMobile" (click)="showGoalsDropdown($event)" target="_self" class="coaching" translate>-->
<!--                    HEADER_SUPER_COACHING <span class="badge">New</span>-->
<!--                </a>-->
<!--                <div class="goals-dropdown" *ngIf="showGoalsPopup && isMobile">-->
<!--                    <goal-dropdown (closeDropdown)="hideGoalsDropdown()" [navigate]="true"></goal-dropdown>-->
<!--                </div>-->
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/free-live-classes" routerLinkActive="active-link" target="_self" class="master-class"  translate>
                    HEADER_MASTERCLASS <span class="badge badge--free" translate> HEADER_FREE </span>
                </a>
            </li>

            <li>
                <a href="/skill-academy" target="_self" class="skills"  translate>
                    HEADER_SKILL_ACADEMY
                </a>
            </li>

            <li class="header__divider" translate>HEADER_TESTS</li>

            <li>
                <a (click)="toggleSideBar()" routerLink="/online-test-series" routerLinkActive="active-link" target="_self" class="test-series" translate>
                    HEADER_TEST_SERIES
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/free-live-tests-and-quizzes" class="test-quizzes" routerLinkActive="active-link"  target="_self" translate>
                    HEADER_ETQ
                    <!-- <span class="badge badge--free" translate> HEADER_FREE </span> -->
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/previous-year-papers" class="pyp" routerLinkActive="active-link"  target="_self" translate>
                    HEADER_PYP
                </a>
            </li>
            <li>
                <a href="/practice-questions" target="_self" class="practice" [ngClass]="{'active-link': activePageType === 'practicePage'}" translate>
                    HEADER_PRACTICE
                    <!-- <span class="badge badge--free"> FREE </span> -->
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/free-online-quizzes" target="_self" class="quizzes" routerLinkActive="active-link" translate>
                    HEADER_QUIZZES
                    <span class="badge" translate> HEADER_NEW </span>
                </a>
            </li>
            <li>
                <a href="/attempted-tests" target="_self" class="attempted-tests" translate>
                    HEADER_ATTEMPTED_TESTS
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/pass" class="pass" routerLinkActive="active-link"  target="_self"  translate>
                    HEADER_PASS
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/pass-pro" class="pro" routerLinkActive="active-link"  target="_self" translate>
                    HEADER_PASS_PRO
                </a>
            </li>

            <li>
                <a (click)="toggleSideBar()" routerLink="/pass-elite" class="elite" routerLinkActive="active-link"  target="_self" translate>
                    Pass Elite
                </a>
            </li>

            <li class="header__divider" translate>HEADER_MISCELLANEOUS</li>

            <li>
                <a class="exam-nav" href="/exams" class="exams"  target="_self" translate>
                    HEADER_EXAMS
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/saved-questions" routerLinkActive="active-link" target="_self" class="bookmark" translate>
                    HEADER_SAVED_QUES
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" routerLink="/reported-questions" routerLinkActive="active-link" target="_self" class="reported" translate>
                    HEADER_REPORTED_QUES
                </a>
            </li>
            <li>
                <a href="/doubts" target="_self" class="doubts-discuss" translate>
                    HEADER_DOUBTS
                </a>
            </li>
            <li>
                <a href="/current-affairs/current-affairs-quiz" target="_self" class="gk-ca" translate>
                    HEADER_GK_CA
                </a>
            </li>


            <li>
                <a href="/e-cards" target="_self" class="attempted-tests" translate>
                    Activate Products
                </a>
            </li>

            <li class="header__divider" translate>HEADER_MORE</li>

            <li>
                <a href="/teachers-training-program" class="training-program" target="_self"  translate>
                    HEADER_TRAINING_PROGRAM
                </a>
            </li>
            <li>
                <a href="/referrals" target="_self" class="refer-earn" translate>
                    HEADER_REFER_EARN
                </a>
            </li>
            <li>
                <a (click)="toggleSideBar()" [routerLink]="['/success-stories']" routerLinkActive="active-link" target="_self" class="selections" translate>
                    HEADER_SELECTIONS
                </a>
            </li>

        </ul>
    </div>
    <div class="header__topbar">
        <a class="logo__testbook mweb" routerLink="/home" target="_self">
            <svg width="30" height="30">
                <use xlink:href="#testbook-icon"></use>
            </svg>
        </a>
        <ul class="header__topbar__action">
            <li>
                <app-lang-dropdown></app-lang-dropdown>
            </li>
            <li class="desktop">
                <pass-button></pass-button>
            </li>
            <li class="mweb">
                <global-search-bar-modal (onModalToggle)="toggleSearchModal()"></global-search-bar-modal>
            </li>
            <li>
                 <app-notifications></app-notifications>
            </li>
            <li class="dropdown" [ngClass]="{'skeleton': isServer}">
                <img loading="lazy" alt="profile-pic" class="profile-pic" width="34" height="34" [src]="student?.image" />
                <div class="dropdown-menu">
                    <a href="/referrals" class="dropdown-item">Refer & Earn</a>
                    <a href="/settings" class="dropdown-item">Settings</a>
                    <a href="/transactions" class="dropdown-item">Transactions</a>
                    <a href="/logout" class="dropdown-item">Logout</a>
                </div>
            </li>
        </ul>
        <div class="container desktop" style="height: 100%" *ngIf="!isMobile">
            <div class="search-holder">
                <ng-container *ngIf="showGoalDropdownSection">
                    <img class="img" src="/assets/img/super/super-coaching.svg?v=2" alt="super-coaching-logo" width="145" height="22">
                    <div  class="goals-toggle" type="button" (click)="showGoalPopup()">
                        {{currentGoalDetails?.properties?.title || "Select Goal"}}
                        <svg width="20" height="20"><use xlink:href="#arrow-down"></use></svg>
                    </div>
                    <button class="goal-close" *ngIf="showGoalsPage"  (click)="hideGoalPopup()"></button>
                </ng-container>
                <global-search-bar class="global-search"></global-search-bar>
            </div>
        </div>
    </div>
</header>


<svg style="display: none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="testbook-logo" viewBox="0 0 111 25">
        <g fill="none">
            <path d="M20.211 24.611H5.264c-2.222 0-4.024-1.83-4.024-4.089V5.332c0-2.259 1.802-4.09 4.024-4.09h14.947c2.223 0 4.024 1.831 4.024 4.09v15.19c0 2.258-1.801 4.09-4.024 4.09" fill="#FEFEFE"/><path d="M20.211 24.611H5.264c-2.222 0-4.024-1.83-4.024-4.089V5.332c0-2.259 1.802-4.09 4.024-4.09h14.947c2.223 0 4.024 1.831 4.024 4.09v15.19c0 2.258-1.801 4.09-4.024 4.09z" stroke="#BABBC4" stroke-width=".5"/>
            <path d="M20.211 24.611H5.264c-2.222 0-4.024-1.83-4.024-4.089V5.332c0-2.259 1.802-4.09 4.024-4.09h14.947c2.223 0 4.024 1.831 4.024 4.09v15.19c0 2.258-1.801 4.09-4.024 4.09" fill="#FEFEFE"/><path d="M20.211 24.611H5.264c-2.222 0-4.024-1.83-4.024-4.089V5.332c0-2.259 1.802-4.09 4.024-4.09h14.947c2.223 0 4.024 1.831 4.024 4.09v15.19c0 2.258-1.801 4.09-4.024 4.09z" stroke="#BABBC4" stroke-width=".5"/>
            <path fill="#0AD0F4" d="M5.433 21.342l7.202-3.87V4.467L5.433 9.323z"/>
            <path fill="#161625" d="M19.837 21.342l-7.202-3.87V4.467l7.202 4.857z"/>
            <path d="M30.308 4.59h2.96v4.39h1.757v2.572H33.27v9.338h-2.96v-9.338h-1.52V8.98h1.518zm12.643 9.53c-.045-1.099-.755-2.175-2.443-2.175-1.533 0-2.376 1.166-2.443 2.175h4.886zm2.753 3.61c-.555 1.905-2.287 3.497-4.974 3.497-2.998 0-5.64-2.175-5.64-5.896 0-3.52 2.576-5.807 5.373-5.807 3.376 0 5.397 2.175 5.397 5.716 0 .427-.045.875-.045.92h-7.816c.066 1.457 1.288 2.51 2.753 2.51 1.377 0 2.132-.694 2.487-1.68l2.465.74zm3.309-.472c.067.875.711 1.682 2 1.682.976 0 1.442-.516 1.442-1.099 0-.493-.333-.896-1.176-1.076l-1.444-.336c-2.11-.471-3.065-1.749-3.065-3.296 0-1.973 1.733-3.61 4.087-3.61 3.109 0 4.152 1.996 4.286 3.184l-2.466.56c-.089-.65-.555-1.479-1.798-1.479-.777 0-1.4.471-1.4 1.099 0 .538.4.874 1 .986l1.554.337c2.155.447 3.243 1.77 3.243 3.385 0 1.793-1.377 3.632-4.242 3.632-3.286 0-4.419-2.153-4.553-3.408l2.532-.56zm8.14-12.668h2.96v4.39h1.757v2.572h-1.757v9.338h-2.96v-9.338h-1.519V8.98h1.519zm11.199 7.646c-1.488 0-2.732 1.1-2.732 3.117 0 1.995 1.244 3.138 2.732 3.138 1.51 0 2.709-1.12 2.709-3.138s-1.2-3.117-2.71-3.117zm-5.597 8.654V4.66h2.91v6.412c.488-.785 1.688-1.503 3.308-1.503 3.198 0 5.041 2.49 5.041 5.762 0 3.34-2.042 5.83-5.152 5.83-1.51 0-2.664-.673-3.242-1.615v1.345h-2.865zm20.498-5.515c0-2.085-1.332-3.139-2.776-3.139-1.443 0-2.776 1.054-2.776 3.139 0 2.063 1.333 3.14 2.776 3.14 1.444 0 2.776-1.055 2.776-3.14m2.954 0c0 3.385-2.466 5.852-5.73 5.852-3.265 0-5.729-2.467-5.729-5.852 0-3.408 2.464-5.851 5.729-5.851 3.264 0 5.73 2.443 5.73 5.851m9.238 0c0-2.085-1.333-3.139-2.776-3.139-1.443 0-2.776 1.054-2.776 3.139 0 2.063 1.333 3.14 2.776 3.14 1.443 0 2.776-1.055 2.776-3.14m2.954 0c0 3.385-2.465 5.852-5.73 5.852-3.265 0-5.73-2.467-5.73-5.852 0-3.408 2.465-5.851 5.73-5.851 3.265 0 5.73 2.443 5.73 5.851m7.772-.875l4.464 6.39h-3.62l-2.887-4.214-1.222 1.3v2.915h-2.953V4.659h2.953v9.304l3.776-4.103h3.864z" fill="#0AD0F4"/>
        </g>
    </symbol>
    <symbol id="bell-notify" viewBox="0 0 20 20">
        <path d="M18.5 15.333S17 13 17 10V6.667C17 3 14 0 10.333 0 6.667 0 3.667 3 3.667 6.667V10c0 3-1.5 5.333-1.5 5.333a.757.757 0 0 0 0 .834c.083.333.333.5.666.5h15a.874.874 0 0 0 .75-.417c.167-.333.084-.583-.083-.917zm-11 3c.583 1 1.667 1.667 2.833 1.667 1.167 0 2.25-.667 2.834-1.667H7.5z" fill="#86A1AE" fill-rule="evenodd"></path>
    </symbol>
    <symbol id="search" viewBox="0 0 20 20">
        <path d="M14.7 13.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S2 5.2 2 9.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S6.3 4 9.1 4s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" fill="#86A1AE" fill-rule="evenodd"/>
    </symbol>
    <symbol id="arrow-down" viewBox="0 0 20 20">
        <g fill="none" fill-rule="evenodd">
            <g fill="#86A1AE">
                <g>
                    <g>
                        <path d="M5.41 14L10 9.055 14.59 14 16 12.478 10 6 4 12.478z" transform="translate(-1266 -400) translate(623 382) translate(643 18) matrix(1 0 0 -1 0 20)"/>
                    </g>
                </g>
            </g>
        </g>
    </symbol>

</svg>
<!--<a class="btn btn&#45;&#45;block" (click)="showGoalsPopup=true" type="button">-->
<!--    {{currentGoalDetails?.properties?.title || "Select Goal"}}-->
<!--</a>-->


