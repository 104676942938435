import { createReducer, on } from '@ngrx/store';
import {globalPass, goalPlan,_passModel} from '@models/entity/passes/passes.adapter';
import { getUserTypeState } from '@angular-commons/shared/utility/pass-utils';
import {
    hideReportModal,
    login,
    showReportModal,
    studentMeLoaded,
    passesLoaded,
    passPageInfoLoaded,
    getPageSeoLoaded,
    getTargetsOfSuperGroupSuccess,
    getTargetSuperGroupsSuccess,
    getPromotionBannerSuccess,
    justLoggedIn,
    getNotificationsSuccess,
    getNotificationsCountSuccess,
    deleteNotificationSuccess,
    showCouponModal,
    hideCouponModal,
    showHeader,
    hideHeader,
    hideSidebar,
    initiateRazorpayPayment,
    hideRazorpayIframe,
    showAlert,
    hideAlert,
    setRazorypayTransactionStatus,
    getPypTargetsOfSuperGroupSuccess,
    productEntityCountSuccess,
    setOnboardingParams,
    getSearchSuggestionsSuccess,
    showFooter,
    hideFooter,
    hidePricingModal,
    getPypTargetSuperGroupsSuccess,
    transactionPopupAction,
    showStickyFooter,
    hideStickyFooter,
    getSubFooterLoaded,
    setIdForSubFooter,
    sendProductDetails,
    autoCouponApply,
    resetOnNavigation,
    showKindOfferModal,
    hideKindOfferModal,
    setOpenInAppDialogDeeplink,
    setSubFooterType,
    setContinueInAppDeeplink,
    setReferredUserData,
    setOpenInAppDialogVisibility,
    setSeoSubFooter,
    getMultiAppJsonSuccess,
    masterSeriesEnrollmentDetailsLoaded,
    goalPlansLoaded,
    goalLoaded,
    getGoalInfoSuccess,
    hideGoalSelectionModal,
    showGoalSelectionModal,
    getTestSeriesByGoalIdSuccess,
    showProductInfoModal,
    hideProductInfoModal,
    getEnrolledTestSeriesByGoalIdSuccess,
    getSuggestedTestByGoalIdSuccess,
    getSearchedTestSeriesSuccess,
    resetGoalTestSeries,
    getPypByGoalSuccess,
    getCustomTagsByGoalSuccess,
    showVideoModal,
    hideVideoModal,
    getGoalPageSequenceSuccess,
    getEducatorDataLoaded,
    getFacultyDataLoaded,
    setEducatorsFollowStatus,
    markTestSeriesEnrolledSuccess,
    markTestSeriesUnEnrolledSuccess,
    resetGoalPypTargets,
    getSearchedPypTargetsSuccess,
    showMobileSignupAndVerificationModal,
    hideMobileSignupAndVerificationModal,
    showWhatsappModal,
    hideWhatsappModal,
    mobileVerificationSuccess,
    setActivePageType,
    stickyFooterType,
    getGoalCoursesLoaded,
    updateAccountBlockedModalState,
    getPassProductsLoaded,
    showSidebar,
    setEventExtras,
    getGoalPitchMapLoaded,
    getMasterSeriesByGoalIdLoaded,
    getClassesByIdSuccess,
    getResumeFlowActivitiesLoaded,
    setGoalTestTabComponentState,
    resetGoalTestTabComponentState,
    setCurrentPageGoal,
    getGoalPageBannerLoaded,
    getGlobalPopupBannerLoaded,
    getPurchaseStateLoaded,
    getSeoMenuByIdLoaded,
    getCloudBucketDataLoaded,
    showRequestCallBackModal,
    hideRequestCallBackModal,
    setPricingModalState,
    resetClassesById,
    recommendedGoalPlanLoaded,
    goalPagePopupState,
    getPersonalisedBannerDataLoaded,
    getExamCalendarLoaded,
    getSimilarGoalPacksLoaded,
    disableAutoCouponApply,
    getJarvisBannerLoaded,
    setSearchBarQuery,
    setContentLanguage,
    getPassSaleBannerLoaded,
    sendClientErrorLogToBackendLoaded,
    showContinueInApp,
    hideContinueInApp,
    getPopupDataLoaded,
    showReattemptModal,
    showPassProIntroPopup,
    hidePassProIntroPopup,
    passProPageInfoLoaded,
    dailyCurrentAffairsLoaded,
    monthlyCurrentAffairsLoaded,
    hideGoalFeatureModal,
    showGoalFeatureModal,
    hideBookCarouselModal,
    hideStudentCounsellingModal,
    showBookCarouselModal,
    showStudentCounsellingModal,
    getSelectedTabLoaded,
    setSelectedPass,
    getPassEliteCard,
    getPassEliteCardLoaded,
    getGoalFreeTestLoaded, getPassElitePageDetailsLoaded,
    getAttemptedTestsSuccess,
    setProjectName,
    passElitePitchLoaded
} from './app.actions';
import {SEO_GROUP_SUBFOOTER} from "@shared/utility/seo-utilities";
import {mergeArraysUniqueByKey} from "@models/mapping-framework";
import {mergeAttemptDetails} from "@shared/utility/test-utilities";
import {_AttemptedTest} from "@models/entity/tests/test.adapter";
import { SUPER_WHATSAPP_SHARE_OBJECT } from '@angular-commons/shared/utility/constants';
import {transformBannerData} from "@models/entity/goals/goals.adapter";
import {transformMasterSeriesDays} from "@models/entity/master-class/master-class.adapter";
import {addMinutes} from "@shared/utility/date-utilities";

export const appInitialState = {
    tb_token  : '',
    bookModal:{
        showModal : false,
        cardImages :[],
        cardTitle:"",
        cardDescription:""
    },
    counsellingModal:{
        showModal : false,
    },
    isLoggedIn: false,
    student : {
        id : '-1',
        name:'',
        mobile:'',
        email:'',
        globalPassState:{},
        passProState:{},
        goalPlanState:{},
        userType:[],
    }, // goalPlanState[goalId] = planState
    lang:'English',
    goals : {},
    goalPlans : {},
    recommendedGoalPlan : {},
    products : {},
    searchedTestSeries : {
        isInitialState: true,
        data: []
    },
    searchedPypTargets : {
        isInitialState: true,
        data: []
    },
    goalTestSeries: {
        goalId : '',
        testSeries : [],
        noMoreResults : false,
        skip : -1,
        limit : -1,
        isInitialState: true
    },
    goalPypTargets : {
        goalId : '',
        targets : [],
        noMoreResults : false,
        skip : -1,
        limit : -1,
        isInitialState: true
    },
    goalTestTabComponentState : '',
    enrolledGoalTestSeries: {
        goalId : '',
        testSeries : [],
        noMoreResults : false,
        skip : -1,
        limit : -1,
        isInitialState: true
    },
    passes : {},
    goal : <any> { isInitialState: true },
    goalMap: {},
    passFaqs:[],
    referredUser:{
        isReferredUser:false,
        referrer:{},
        username:''
    },
    reportModal: {
        show: false,
        entityId: '',
        entityType: '',
        entityTitle: ''
    },
    pageSeo: {},
    subFooter: {
        id: '',
        data: [],
        type: '',
        isSubFooterLoaded: false,
        isInitialState: true
    },
    continueInAppDeeplinkData:{
        trackingInfo:'',
        id:'',
        deeplinkPath:''
    },
    enrollment:{},
    superGroups: [],
    pypSuperGroups: [],
    targets: [],
    pypTargets: [],
    banners: {
        enabled : false,
        banners : []
    },
    bannerDate: '',
    couponModal: {
        show: false,
        ModalType:'default',
        productType:'default'
    },
    productsOnPage: {
        ids:[],
        details:{}
    },
    kindOffer:{
        show:false,
        pass:{}
    },
    autoCouponApply:false,
    disableAutoCouponApply:false,
    razorpayPayment: {
        isIframeVisible: false,
        details: {
            extraObj: {},
            params: {}
        },
        redirectUrl: '',
        transactionStatus: '',
    },
    showHeader: true,
    showSidebar: true,
    showFooter: true,
    showContinueInApp:false,
    showStickyFooter: true,
    target: {},
    productEntityCount: {},
    searchHistory: [],
    justLoggedIn: false,
    notificationsCount: {},
    notifications: [],
    deletedNotificationId: '',
    classSlugs: [],
    alertToast: {
        messages: [
        ],
        alertCounter:0,
        timer:1000,
        autoClose:true,
        isCompletedAlert: false,
        alertTitle: '',
        alertType:''
    },
    onboardingParams : {},
    enrolledTargets: [],
    enrolledPypTargets: [],
    recentActivity:{
        tests:[]
    },
    pricingModal: {
        passType : "globalPass",
        show: false,
        eventData: {},
        leadData: {},
        module: '',
        referrer:'',
        showComparisonModal: false
    },
    goalSelectionModal:{
        show: false,
        eventData: {},
        module: ''
    },
    transactionPopupAction: {},
    openInAppDialog: {
        show: false,
        deeplink: ''
    },
    productInfoModal: {
        show: false,
        popUpType: '',
        data: {},
        isInitialState: true
    },
    videoModal:{
        show: false,
        data: {}
    },
    whatsappModal:{
        show: false,
        data:{}
    },
    signupAndVerificationModal: {
        showModal: false,
        isSkippable: true,
        data: {}
    },
    multiAppJsonData:[],
    suggestedTestByGoal: {},
    customTags: {},
    goalPageSequence : {},
    pitchMapping:{
        primaryGoal:{},
        targetToGoalMap:{},
        isInitialState: true
    },
    educator: {},
    faculty:[],
    isEducatorFollowed:false,
    activePageType:'',
    isMobileVerified: false,
    eventExtras:{
        itemId:'',
        itemType:''
    },
    stickyfooterType:'',
    goalCourses:{
        courses:[],
        goalIds:'',
        isInitialState:true
    },
    teachersModules:{
        teachers:[],
        goalIds:'',
        isInitialState:true
    },
    resumeFlowActivities: [],
    targetToGoalMap:{},
    accountBlocked: {
    showWarning: false,
  },
    goalClasses: {
        customTagId: '',
        classes: [],
        isInitialState:true,
        total:1,
        classesMap: {}
    },
    masterSeries : {
        goalId : '',
        customTagIds:'',
        series : [],
        noMoreResults : false,
        skip : -1,
        limit : -1,
        isInitialState:true
    },
    currentPageGoal : undefined,
    goalBannerData: [],
    cloudBucketData: {},
    globalPopupBannerData: {},
    purchaseState: {
        data: {},
        isInitialState:true
    },
    seoMenu: {
        top: {},
        side: {}
    },
    requestCallBackModal: {
        show: false
    },
    showGoalPagePopup:false,
    personalisedBannerData:{},
    examCalendar:{},
    similarGoalPacks: [],
    jarvisBanner: {},
    searchBarQuery: '',
    showReattemptModal:{
        showModal:false,
        testId:'',
        isReattemptAvailable:false,
        interfaceType:''
    },
    availableContentLangs:{
        currLang: '',
        availableLang: []
    },
    passSaleBannerData: [],
    clientErrorData:'',
    popupData:{},
    passProIntroPopup: {
        show: false,
        isInitialState: true
    },
    passProDetails: {},
    dailyCurrentAffairs: {
        tags: '',
        notes: [],
        count: 0,
        date: '',
        isInitialState: true
    },
    monthlyCurrentAffairs: {
        notes: [],
        count: 0,
        date: '',
        month: 0,
        year: '',
        isInitialState: true
    },
    comparisonPagePassDetails: {
        faqs: [],
        benefits: []
    },
    goalFeatureModal: {
        showModal: false,
        popupData: {}
    },
    autoSelectTabData: {},
    selectedPass: null,

    passEliteCard: [],
    goalFreeTest:[],
    passElitePageDetails: [],
    passElitePitch:[]
};

const _appReducer = createReducer(appInitialState,
    on(setCurrentPageGoal, (_state,{payload}) => {
        let state = {..._state};
        state.currentPageGoal = payload;
        return state;
    }),
    on(login, _state => {
        let state = {..._state};
        //dummy login
        state.isLoggedIn = true;
        return Object.assign({},state);
    }),
    on(studentMeLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.student = {..._state.student};
        let globalPassState=globalPass.getStuPassState(payload.globalPassExpiry)
        let passProState = globalPass.getStuPassState(payload.passProExpiry)
        let goalPlanState  = {};
        if(payload.goalSubs){
            payload.goalSubs.forEach(sub => {
                goalPlanState[sub.goalId] = goalPlan.getStuGoalState(sub);
            });
        }
        if(payload.name){
            state.student.name = payload.name;
        }
        if(payload.mobile){
            state.student.mobile = payload.mobile
        }
        if(payload.email){
            state.student.email = payload.email;
        }
        let userType = getUserTypeState(payload.globalPassExpiry,payload.passProExpiry,goalPlanState);
        state.student = {... payload,globalPassState,goalPlanState,passProState,userType};
        return Object.assign({},state);
    }),
    on(passesLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.passes = {... payload};
        return Object.assign({},state);
    }),
    on(goalLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.goals = {... payload};
        return Object.assign({},state);
    }),
    on(goalPlansLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.goalPlans = {... payload};
        return Object.assign({},state);
    }),
    on(recommendedGoalPlanLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.recommendedGoalPlan = {... payload};
        return Object.assign({},state);
    }),

    on(getPassProductsLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.products = {... payload};
        return Object.assign({},state);
    }),
    on(setEventExtras,(_state,{itemId,itemType})=>{
        let state = {..._state};
        state.eventExtras={
            itemId:itemId,
            itemType:itemType
        }
        return state;
    }),
    on(passPageInfoLoaded, (_state,{payload}) => {
        let state = {..._state};
        state.passFaqs = [... payload.faqs];

        let flattenCountObj={
            activeUsersCount:payload.activeUsers.count,
            targetCount:payload.examCount,
            mockTestCount:payload.mockTestsInfo.count,
            onlineCoursesCount:payload.onlineCoursesInfo.count,
            pypCount:payload.totalPYPInfo.count,
        }
        state.productEntityCount={... state.productEntityCount,...flattenCountObj}
        state.comparisonPagePassDetails = {...state.comparisonPagePassDetails, faqs: [...payload.faqs], benefits: [...payload.benefits]}

        return Object.assign({},state);
    }),
    on(setReferredUserData,(_state,{referrer,isReferredUser,username})=>{
        let state = {..._state};
        let data={
            referrer:referrer,
            isReferredUser:isReferredUser,
            username:username
        }
        state.referredUser=data;
        return state;
    }),
    on(showReportModal, (_state, {entityId, entityType, entityTitle})=>{
        let state = {..._state};
        state.reportModal = {
            show: true,
            entityId: entityId,
            entityType: entityType,
            entityTitle: entityTitle
        };
        return state;
    }),
    on(hideReportModal, _state => {
        let state = {..._state};
        state.reportModal = {
            show: false,
            entityId: '',
            entityType: '',
            entityTitle: ''
        };
        return state;
    }),
    on(getPageSeoLoaded, (_state, {payload}) => {
        let state = {..._state};
        if(payload && payload.sections){
            state.subFooter = {
                ...state.subFooter,
                data: payload.sections,
                isSubFooterLoaded: true,
                isInitialState: undefined
            };
        }
        return state;
    }),
    on(getSubFooterLoaded, (_state, {payload}) => {
        let state = {..._state};
        if(payload && payload.seoGroup && payload.seoGroup.subFooter){
            state.subFooter = {
                ...state.subFooter,
                data: payload.seoGroup.subFooter,
                isSubFooterLoaded: true,
                isInitialState: undefined
            };
        }
        return state;
    }),
    on(setSeoSubFooter, (_state, {payload}) => {
        let state = {..._state};
        state.subFooter = {
            id: '',
            data: payload,
            type: SEO_GROUP_SUBFOOTER,
            isSubFooterLoaded: true,
            isInitialState: undefined
        };
        return state;
    }),
    on(setSubFooterType, (_state, {subFooterType}) => {
          let state = {..._state};
          state.subFooter = {
              ...state.subFooter,
              type: subFooterType
          };
          return state;
      }),
    on(setIdForSubFooter, (_state, {id}) => {
        let state = {..._state};
        state.subFooter = {
            ...state.subFooter,
            id,
            isInitialState: undefined
        };
        return state;
    }),
    on(setContinueInAppDeeplink,(_state, {continueInAppDeeplinkData})=>{
        let state = {..._state};
        state.continueInAppDeeplinkData ={
            trackingInfo:continueInAppDeeplinkData?.trackingInfo,
            id:continueInAppDeeplinkData?.id,
            deeplinkPath:continueInAppDeeplinkData?.deeplinkPath
        }
        return state;
    }),
    on(getTargetSuperGroupsSuccess, (_state, {payload}) => {
        let state = {..._state};
        state.superGroups = payload.superGroup;
        return state;
    }),
    on(getPypTargetSuperGroupsSuccess, (_state, {payload}) => {
        let state = {..._state};
        state.pypSuperGroups = payload.superGroup;
        return state;
    }),
    on(getTargetsOfSuperGroupSuccess, (_state, {payload}) => {
        let state = {..._state};
        state.targets = payload.targets;
        return state;
    }),
    on(getPypTargetsOfSuperGroupSuccess, (_state, {payload}) => {
        let state = {..._state};
        state.pypTargets = payload.targets;
        return state;
    }),
    on(getPromotionBannerSuccess, (_state, {payload}) => {
        let state = {..._state};

        state.bannerDate = payload.date;
        state.banners = {
            enabled : payload.enabled,
            banners : payload.banners
        };

        return state;
    }),
    on(justLoggedIn, (_state) => {
        let state = {..._state};
        state.justLoggedIn = true;
        return state;
    }),

    on(getNotificationsSuccess, (_state, {payload}) => {
        let state = {..._state};
        if(payload && payload.notifications && payload.notifications.results) {
            state.notifications = payload.notifications.results;
        }

        return state;
    }),
    on(getNotificationsCountSuccess, (_state, {payload}) => {
        let state = {..._state};
        state.notificationsCount = payload;

        return state;
    }),
    on(deleteNotificationSuccess, (_state, {payload, id}) => {
        let state = {..._state};
        if(payload.success) {
            state.deletedNotificationId = id;
        }
        return state;
    }),
    on(getSearchSuggestionsSuccess, (_state, {payload}) => {
        let state = {..._state};
        if(payload.suggestions) {
            state.searchHistory = payload.suggestions;
        }
        return state;
    }),
    on(showCouponModal, (_state,{ModalType,productType})=>{
        let nextState = {..._state};
        nextState.couponModal = {
            show: true,
            ModalType:ModalType||'default',
            productType:productType || 'default'
        };
        return nextState;
    }),
    on(hideCouponModal, (state) => {
        let nextState = {...state}
        nextState.couponModal = {
            show: false,
            ModalType: 'default',
            productType:'default'
        };
        return nextState;
    }),
    on(sendProductDetails, (_state, {productIds,productDetails})=>{
        let nextState = {..._state};
        nextState.productsOnPage = {
            ids: [...productIds],
            details:{... productDetails}
        };
        return nextState;
    }),
    on(autoCouponApply , state => {
        let nextState = {...state}
        nextState.autoCouponApply=true;
        return nextState;
    }),
    on(disableAutoCouponApply , (state,{disableAutoCouponApply} )=> {
        let nextState = {...state}
        nextState.disableAutoCouponApply=disableAutoCouponApply;
        return nextState;
    }),
    on(setPricingModalState, (_state, {passType, module, eventData, leadData = {},referrer, showComparisonModal = false}) => {
        let state = {..._state};
        state.pricingModal = {
            passType : passType || 'globalPass',
            show: true,
            eventData: {...eventData},
            leadData: {...leadData},
            module,
            referrer,
            showComparisonModal
        };
        return state;
    }),
    on(hidePricingModal, _state => {
        let state = {..._state};
        state.pricingModal = {
            passType : 'globalPass',
            show: false,
            eventData: {...state.pricingModal.eventData},
            leadData: {...state.pricingModal.leadData},
            module: '',
            referrer:state.pricingModal.referrer,
            showComparisonModal: false
        };
        return state;
    }),

    on(showBookCarouselModal, (_state, {cardTitle,cardImages,cardDescription}) => {
        let state = {..._state};
        state.bookModal = {
            showModal : true,
            cardTitle,
            cardImages,
            cardDescription,
        };
        return state;
    }),

    on(hideBookCarouselModal, _state => {
        let state = {..._state};
        state.bookModal = {
            showModal : false,
            cardImages:[],
            cardTitle:"",
            cardDescription:""
        };
        return state;
    }),

    on(showStudentCounsellingModal, (_state) => {
        let state = {..._state};
        state.counsellingModal = {
            showModal : true,
        };
        return state;
    }),

    on(hideStudentCounsellingModal, _state => {
        let state = {..._state};
        state.counsellingModal = {
            showModal : false,
        };
        return state;
    }),

    on(showProductInfoModal, (_state, {popUpType, data}) => {
        let state = {..._state};
        state.productInfoModal = {
            show: true,
            popUpType,
            data,
            isInitialState: undefined
        };
        return state;
    }),
    on(hideProductInfoModal, (_state,{popUpType}) => {
       let state = {..._state};
       state.productInfoModal = {
          show: false,
          popUpType,
          data: {},
          isInitialState: undefined
      };
       return state;
    }),
    on(showVideoModal, (_state, {data}) => {
        let state = {..._state};
        state.videoModal = {
            show: true,
            data
        };
        return state;
    }),
    on(hideVideoModal, _state => {
       let state = {..._state};
       state.videoModal = {
          show: false,
          data: {}
      };
       return state;
    }),
    on(showMobileSignupAndVerificationModal, (_state, {data, isSkippable = true,showModal=true}) => {
        let state = {..._state};
        state.signupAndVerificationModal = {
            data,
            isSkippable,
            showModal
        };
        return state;
    }),
    on(hideMobileSignupAndVerificationModal, _state => {
       let state = {..._state};
       state.signupAndVerificationModal = {
          showModal: false,
          isSkippable: true,
          data: {}
      };
       return state;
    }),
    on(showWhatsappModal, (_state) => {
        let state = {..._state};
        state.whatsappModal = SUPER_WHATSAPP_SHARE_OBJECT;
        return state;
    }),
    on(hideWhatsappModal, (_state) => {
        let state = {..._state};
        state.whatsappModal = {
            show: false,
            data: {}
        };
        return state;
    }),
    on(mobileVerificationSuccess, _state => {
       let state = {..._state};
       state.isMobileVerified = true;
       return state;
    }),
    on(showGoalSelectionModal, (_state, {module, eventData}) => {
        let state = {..._state};
        state.goalSelectionModal = {
            show: true,
            eventData: {...eventData},
            module: module
        }
        return state;
    }),
    on(hideGoalSelectionModal, _state => {
        let state = {..._state};
        state.goalSelectionModal = {
            show: false,
            eventData: {...state.goalSelectionModal.eventData},
            module: ''
        };
        return state;
    }),
    on(showHeader, (_state)=>{
        let state = {..._state};
        state.showHeader = true;
        return state;
    }),
    on(hideHeader, _state => {
        let state = {..._state};
        state.showHeader = false;
        return state;
    }),
    on(showSidebar, _state => {
        let state = {..._state};
        state.showSidebar = true;
        return state;
    }),
    on(hideSidebar, _state => {
        let state = {..._state};
        state.showSidebar = false;
        return state;
    }),
    on(showFooter, (_state)=>{
        let state = {..._state};
        state.showFooter = true;
        return state;
    }),
    on(hideFooter, _state => {
        let state = {..._state};
        state.showFooter = false;
        return state;
    }),
    on(showStickyFooter, (_state)=>{
        let state = {..._state};
        state.showStickyFooter = true;
        return state;
    }),
    on(stickyFooterType, (_state,{footerType})=>{
        let state = {..._state};
        state.stickyfooterType = footerType;
        return state;
    }),
    on(hideStickyFooter, _state => {
        let state = {..._state};
        state.showStickyFooter = false;
        return state;
    }),
    on(showContinueInApp, (_state)=>{
        let state = {..._state};
        state.showContinueInApp = true;
        return state;
    }),
    on(hideContinueInApp, _state => {
        let state = {..._state};
        state.showContinueInApp = false;
        return state;
    }),
    on(productEntityCountSuccess, (_state, {payload}) => {
        let state = {..._state};
        state.productEntityCount = payload;
        return  {...state};
    }),
    on(initiateRazorpayPayment, (state, {details, redirectUrl = ''})=>{
        let nextState = {...state}
        nextState.razorpayPayment = {
            ...nextState.razorpayPayment,
            isIframeVisible: true,
            details: {...details},
            redirectUrl,
            transactionStatus: ''
        };
        return nextState;
    }),
    on(hideRazorpayIframe, state => {
        let nextState = {...state}
        nextState.razorpayPayment = {
            ...nextState.razorpayPayment,
            isIframeVisible: false
        };
        return nextState;
    }),
    on(setRazorypayTransactionStatus, (state, {status}) => {
        let nextState = {...state}
        nextState.razorpayPayment = {
            ...nextState.razorpayPayment,
            transactionStatus: status,
            isIframeVisible: false
        };
        return nextState;
    }),
    on(setOnboardingParams,(_state,{payload}) => {
        let state = {..._state};
        state.onboardingParams = payload;
        return Object.assign({},state);
    }),
    on(showAlert,(_state, {message,timer=1000,autoClose=true,isCompletedAlert = false, alertTitle = '',alertType='default'}) => {
        let nextState = {..._state};
        let messages=[... nextState.alertToast.messages];
        let currentTime=new Date().getTime();
        messages.push({
            id:_state.alertToast.alertCounter,
            message:message,
            expiry:currentTime + timer
        });
        nextState.alertToast = {
            messages:messages,
            alertCounter:nextState.alertToast.alertCounter+1,
            timer:timer,
            autoClose:autoClose,
            isCompletedAlert: isCompletedAlert,
            alertTitle: alertTitle,
            alertType:alertType
        };

        return nextState;
    }),
    on(hideAlert,(_state,{id}) => {
        let nextState = {..._state};
        let messages = [... nextState.alertToast.messages];

         let index=messages.findIndex((message => message.id === id))
         messages.splice(index,1)


        nextState.alertToast = {
            ...nextState.alertToast,
            messages: messages,
            alertCounter:nextState.alertToast.alertCounter,
            isCompletedAlert: nextState.alertToast.isCompletedAlert,
            alertType:nextState.alertToast.alertType
        };

        return nextState;
    }),
    on(transactionPopupAction,(_state,{id,payloadtype,payload}) => {
        let state = {..._state};
        state.transactionPopupAction = {id,payloadtype,payload};
        return Object.assign({},state);
    }),
    on(resetOnNavigation, (_state)=>{
        let nextState = {..._state};
        nextState.autoCouponApply=false;
        nextState.productsOnPage={... appInitialState.productsOnPage};
        nextState.couponModal={... appInitialState.couponModal}
        nextState.subFooter = {...appInitialState.subFooter};
        nextState.enrollment = {... appInitialState.enrollment}
        nextState.activePageType='';
        nextState.currentPageGoal = undefined;
        nextState.availableContentLangs = appInitialState.availableContentLangs;
        return nextState;
    }),
    on(setOpenInAppDialogDeeplink, (_state, {deeplink}) => {
        let state = {..._state};
        state.openInAppDialog = {
            ..._state.openInAppDialog,
            deeplink
        };
        return state;
    }),
    on(setOpenInAppDialogVisibility, (_state, {show}) => {
        let state = {..._state};
        state.openInAppDialog = {
            ..._state.openInAppDialog,
            show
        };
        return state;
    }),
    on(showKindOfferModal,(_state,{pass})=>{
        let state = {..._state};
        let kindOfferObj={
            show:true,
            pass:pass
        }
        state.kindOffer=kindOfferObj;
        return state;
    }),
    on(hideKindOfferModal,(_state)=>{
        let state = {..._state};
        let kindOfferObj={
            show:false,
            pass:{}
        }
        state.kindOffer=kindOfferObj;
        return state;
    }),
    on(getMultiAppJsonSuccess, (_state, {payload})=> {
        let nextState = {..._state};
        nextState.multiAppJsonData=[...payload];
        return nextState;
    }),
    on(masterSeriesEnrollmentDetailsLoaded,(_state,{payload,id})=>{
        let nextState = Object.assign({},_state);
        nextState.enrollment = {... payload,id};
        return nextState;
    }),
    on(getGoalInfoSuccess, (_state, {payload}) => {
        let state = {..._state};
        if(state.goal?.id !== payload.goal?.id){
            state.goal = {...payload.goal, isInitialState: undefined};
            if(payload?.failed){
                state.goalMap = {
                    ...state.goalMap,
                    [payload.goal.id]: false,
                    [payload.goal.properties.slug]: false
                };
            } else if(payload?.goal) {
                state.goalMap = {
                    ...state.goalMap,
                    [payload.goal.id]: payload.goal,
                    [payload.goal.properties.slug]: payload.goal
                };
            }
        }
        return state;
    }),
    on(getTestSeriesByGoalIdSuccess,(_state, {testSeries, goalId, skip, limit}) => {
        let nextState = {..._state};
        let newSeries = [...testSeries];

        if (skip !== nextState.goalTestSeries.skip && skip > nextState.goalTestSeries.skip) {
            let newObject: any = {};
            newObject = {...nextState.goalTestSeries};

            if (limit && newSeries.length < limit) {
                newObject.noMoreResults = true;
            }
            newObject.skip = skip;
            newObject.goalId = goalId;
            newObject.limit = limit;
            newObject.testSeries = mergeArraysUniqueByKey(nextState.goalTestSeries.testSeries, newSeries,(ts)=>ts.details.id);
            newObject.isInitialState = undefined;
            nextState.goalTestSeries = Object.assign({},  newObject);
        }
        return nextState;
    }),

    on(resetGoalTestSeries,(_state) => {
        let nextState = {..._state};
        nextState.goalTestSeries = {
            goalId : '',
            testSeries : [],
            noMoreResults : false,
            skip : -1,
            limit : -1,
            isInitialState: true
        };
        return nextState;
    }),
    on(resetGoalPypTargets,(_state) => {
        let nextState = {..._state};
        nextState.goalPypTargets = {
            goalId : '',
            targets : [],
            noMoreResults : false,
            skip : -1,
            limit : -1,
            isInitialState: true
        };
        return nextState;
    }),
    on(setGoalTestTabComponentState,(_state, {componentState}) => {
         let nextState = {..._state};
         nextState.goalTestTabComponentState = componentState;
         return nextState;
    }),
    on(resetGoalTestTabComponentState,(_state) => {
         let nextState = {..._state};
         nextState.goalTestTabComponentState = '';
         return nextState;
    }),
    on(getEnrolledTestSeriesByGoalIdSuccess,(_state, { testSeries, goalId, skip, limit}) => {
        let nextState = {..._state};
        let newSeries = [...testSeries];

        if (skip !== nextState.enrolledGoalTestSeries.skip && skip > nextState.enrolledGoalTestSeries.skip) {
            let newObject: any = {};
            newObject = {...nextState.enrolledGoalTestSeries};

            if(limit && newSeries.length < limit) {
                newObject.noMoreResults = true;
            }
            newObject.skip = skip;
            newObject.goalId = goalId;
            newObject.limit = limit;
            newObject.testSeries = mergeArraysUniqueByKey(nextState.enrolledGoalTestSeries.testSeries, newSeries,(ts)=>ts.details.id);
            newObject.isInitialState = undefined;
            nextState.enrolledGoalTestSeries = Object.assign({},  newObject);
        }
        return nextState;
   }),
    on(getSuggestedTestByGoalIdSuccess,( _state, {payload}) => {
      let nextState = {..._state};
      nextState.suggestedTestByGoal = mergeAttemptDetails(payload?.testDetails, new _AttemptedTest(), payload.isResumbleTest) || [];
      return nextState;
   }),
    on(getSearchedTestSeriesSuccess,( _state, {payload}) => {
      let nextState = {..._state};
      nextState.searchedTestSeries =  {isInitialState: undefined, data: payload.results.testSeries};
      return nextState;
   }),
    on(getSearchedPypTargetsSuccess,( _state, {payload}) => {
      let nextState = {..._state};
      nextState.searchedPypTargets = {isInitialState: undefined, data: payload.results.targets};
      return nextState;
   }),
    on(getCustomTagsByGoalSuccess,( _state, {payload}) => {
      let nextState = {..._state};
      payload ={...payload};
      let defState = {count:'',id: '', title:'All Courses'};
      payload.classes=[defState, ...payload.classes];
      nextState.customTags =  payload;
      return nextState;
   }),
    on(getPypByGoalSuccess,( _state, {targets,  goalId, skip, limit}) => {
        let nextState = {..._state};
        let newTargets = [...targets];

        if (skip !== nextState.goalPypTargets.skip && skip > nextState.goalPypTargets.skip) {
            let newObject: any = {};
            newObject = {...nextState.goalPypTargets};

            if (limit && newTargets.length < limit) {
                newObject.noMoreResults = true;
            }
            newObject.skip = skip;
            newObject.goalId = goalId;
            newObject.limit = limit;
            newObject.targets = mergeArraysUniqueByKey(nextState.goalPypTargets.targets, newTargets);
            newObject.isInitialState = undefined;
            nextState.goalPypTargets = Object.assign({},  newObject);
        }
        return nextState;
   }),
    on(getGoalPageSequenceSuccess,( _state, {pageType, payload,slug}) => {
       let nextState = {..._state};
       let newPageSequence = {};

       if(slug){
           newPageSequence[pageType+slug] = payload?.sequence.length && payload?.sequence[0];
       } else {
           newPageSequence[pageType] = payload?.sequence.length && payload?.sequence[0];
       }
       nextState.goalPageSequence =  {...nextState.goalPageSequence, ...newPageSequence};

       return nextState;
    }),
    on(getFacultyDataLoaded,(_state,{educatorData,ids})=>{
        let nextState = {..._state};
            nextState.faculty = [...educatorData.faculty];
        return nextState;
    }),
    on(getEducatorDataLoaded,(_state,{educatorData,idOrSlug})=>{
        let nextState = {..._state};
        nextState.educator={...educatorData.educator,idOrSlug:idOrSlug,isInitialState: undefined};
        return nextState;
    }),
    on(setEducatorsFollowStatus,(_state,{followStatus})=>{
        let nextState = {..._state};
        nextState.isEducatorFollowed=followStatus;
        return nextState;
    }),
    on(markTestSeriesEnrolledSuccess, (_state, {payload, testSeriesId}) => {
        let state = {..._state};
        if (payload.success) {
            state.enrolledGoalTestSeries.testSeries.forEach(testSeries => {
                if (testSeries.id === testSeriesId) {
                    testSeries.studentStats.accessDetails.enrolled = true;
                }
            });
        }
        return state;
    }),
    on(setActivePageType,(_state, {pageType})=>{
        let state = {..._state};
        state.activePageType=pageType;
        return state;
    }),
    on(markTestSeriesUnEnrolledSuccess, (_state, {payload, testSeriesId}) => {
        let state = {..._state};
        if (payload.success) {
            state.enrolledGoalTestSeries.testSeries.forEach(testSeries => {
                if (testSeries.id === testSeriesId) {
                    testSeries.studentStats.accessDetails.enrolled = false;
                }
            });
        }
        return state;
    }),
    on(getGoalPitchMapLoaded,(_state,{payload})=>{
        let state = {..._state}
        let pitchMapping = {
            targetToGoalMap: payload.pitchMap.targetGoalMap,
            primaryGoal: payload.pitchMap.primaryGoal,
            isInitialState: false
        }
        state.pitchMapping = pitchMapping
        return state;
    }),
    on(getGoalCoursesLoaded,(_state,{coursesData,goalIds})=>{
        let state = {..._state}
        let _coursesData={...coursesData};
        _coursesData.courses = _coursesData.courses.map((course)=>{
            course = {...course,
                modules: course.modules.slice(0,3)
            }
            return course
        })
        let goalCourses={
            courses:_coursesData,
            goalIds:goalIds,
            isInitialState:false
        }
        state.goalCourses = goalCourses
        return state;
    }),
    on(getResumeFlowActivitiesLoaded,(_state,{teachersData, gaolIds, coursesData})=>{
        let state = {..._state}
        let _teachersData={...teachersData};
        _teachersData.results = _teachersData.results.map((teacher)=>{
            teacher = {...teacher,
                modules: teacher.modules.slice(0,3)
            }
            return teacher
        })
        let teachersModules={
            teachers:_teachersData,
            goalIds:gaolIds,
            isInitialState:false
        }
        let _coursesData={...coursesData};
        _coursesData.courses = _coursesData.courses.map((course)=>{
            course = {...course,
                modules: course.modules.slice(0,3)
            }
            return course
        })
        let goalCourses={
            courses:_coursesData,
            goalIds:gaolIds,
            isInitialState:false
        }

        let teacherResults=teachersModules.teachers.results
        let courseResults=goalCourses.courses.courses;
        let resumeFlowActivies=teacherResults.concat(courseResults)
        resumeFlowActivies = resumeFlowActivies.sort((firstEntity, nextEntity) =>
            nextEntity.lastActivityTime-firstEntity.lastActivityTime
        )
        state.resumeFlowActivities = resumeFlowActivies
        return state;
    }),
    on(updateAccountBlockedModalState, (_state, { data }) => {
    let state = { ..._state };
    let accountBlocked = { ...state.accountBlocked };

    accountBlocked.showWarning = data;
    state.accountBlocked = accountBlocked;

    return state;
  }),

  on(getClassesByIdSuccess, (_state, {payload, customTagId, goalIds, viewMore}) => {
      let state = {..._state};
      if(state.goalClasses.customTagId === customTagId && viewMore){
          state.goalClasses = {
                  customTagId: customTagId,
                  classes: mergeArraysUniqueByKey(state.goalClasses.classes, payload.classes),
                  isInitialState: undefined,
                  total: payload.total,
                  classesMap: {
                      [goalIds]: mergeArraysUniqueByKey(state.goalClasses.classesMap[goalIds], payload.classes)
                  }
          }
      }
      else {
          state.goalClasses = {
                  customTagId: customTagId,
                  classes: payload.classes,
                  isInitialState: undefined,
                  total: payload.total,
                  classesMap: {
                      [goalIds]: payload.classes
                  }
          }
      }
      return state;
  }),
   on(resetClassesById, (_state, {goalIds})=>{
       let state = {..._state}
        state.goalClasses = {
            ...state.goalClasses,
            classesMap: {
                ...state.goalClasses.classesMap,
                [goalIds]: []
            },
            classes: []
        }
        return state
    }),

  on(getMasterSeriesByGoalIdLoaded, (state, {masterSeriesData,goalIds,customTagIds,skip,limit})=>{
      let nextState = {...state};
      let newSeries =  transformMasterSeriesDays([...masterSeriesData.ongoingSeries]);
      if((nextState.masterSeries.customTagIds !== customTagIds) || (skip !== nextState.masterSeries.skip && skip > nextState.masterSeries.skip)){
          let newObject:any = {}
          if((nextState.masterSeries.customTagIds !== customTagIds)){
              newObject.series=[]
          }
          else{
              newObject={...nextState.masterSeries};
          }

          if(limit && newSeries.length<limit){
              newObject.noMoreResults = true;
          }
          newObject.skip=skip;
          newObject.goalIds=goalIds;
          newObject.customTagIds=customTagIds
          newObject.limit=limit;
          newObject.series = newObject.series.concat(newSeries);
          newObject.isInitialState=undefined;
          nextState.masterSeries = Object.assign({},newObject);
      }
      return nextState
  }),
    on(getGoalPageBannerLoaded, (_state, {payload})=> {
        let state = {..._state};
        state.goalBannerData = payload;
        return state;
    }),
    on(getCloudBucketDataLoaded, (_state, {payload, key}) => {
        let state = {..._state};
        state.cloudBucketData = {...state.cloudBucketData};
        if(key === "newTestSeriesPopup.json"){
            state.cloudBucketData["newTestSeriesPopup.json"] = transformBannerData(payload?.rows);
        } else {
            state.cloudBucketData[key] =  payload;
        }
        return state;
    }),
    on(getGlobalPopupBannerLoaded, (_state, {payload})=> {
        let state = {..._state};
        state.globalPopupBannerData = payload;
        return state;
    }),
    on(getPersonalisedBannerDataLoaded, (_state, {payload})=> {
        let state = {..._state};
        state.personalisedBannerData = payload;
        return state;
    }),
    on(getExamCalendarLoaded, (_state, { payload }) => {
        let state = {..._state};
        state.examCalendar = payload;

        return state;
    }),
    on(getPurchaseStateLoaded, (_state, {payload}) => {
        let state = {..._state};
        let simplPaymentPass = payload?.subscription?.allowedPaymentPartners.find(partner => partner.name === 'simpl');

        state.purchaseState = {
            isInitialState: undefined,
            data: {
                ...payload,
                titleWithoutGoal: payload?.subscription?.title.split('-')[0].trim(),
                simplPaymentPass,
                simplAmount: (payload?.discountedCost/3).toFixed(1),
                amountSaved: payload?.subscription?.oldCost - payload?.discountedCost,
                couponExpiry: addMinutes(Date.now(),30)
            }
        };
        return state;
    }),
    on(getSeoMenuByIdLoaded, (_state, {payload}) => {
        let state = {..._state};
        state.seoMenu = {
            ...state.seoMenu,
            [payload.properties.type] : {
                [payload.id]: payload.properties.levels
            }
        };
        return state;
    }),
    on(showRequestCallBackModal, (_state) => {
        let state = {..._state};
        state.requestCallBackModal = {
            show: true
        };
        return state;
    }),
    on(hideRequestCallBackModal, (_state) => {
        let state = {..._state};
        state.requestCallBackModal = {
            show: false,
        };
        return state;
    }),
    on(goalPagePopupState, (_state,{status}) => {
        let state ={..._state};
        state.showGoalPagePopup = status;
        return state;
    }),
    on(getSimilarGoalPacksLoaded, (_state,{id, payload}) => {
        let state ={..._state};
        if(!id){
            id = 'recommended';
        }
        state.similarGoalPacks = {
            ...state.similarGoalPacks,
            [id]: payload
        };
        return state;
    }),
    on(getJarvisBannerLoaded,(_state, {key, payload})=>{
        let state = {..._state};
        state.jarvisBanner = {
            ...state.jarvisBanner,
            [key]: payload.banner
        };
        return state;
    }),
    on(setSearchBarQuery,(_state, {payload})=>{
        let state = {..._state};
        state.searchBarQuery =  payload;
        return state;
    }),
    on(setContentLanguage, (_state, {payload, currLang})=>{
        let state = {..._state};
        state.availableContentLangs = {
            currLang,
            availableLang: payload
        };
        return state;
    }),
    on(getPassSaleBannerLoaded, (_state, {payload})=> {
        let state = {..._state};
        state.passSaleBannerData = payload;
        return state;
    }),
    on(sendClientErrorLogToBackendLoaded, (_state, {payload})=> {
        let state = {..._state};
        state.clientErrorData = payload;
        return state;
    }),
    on(getPopupDataLoaded, (_state, {payload})=> {
        let state = {..._state};
        state.popupData = payload;
        return state;
    }),
    on(showReattemptModal, (_state, {showModal=false,isReattemptAvailable=false,testId='',interfaceType=''})=>{
        let state = {..._state};
        state.showReattemptModal = {...state.showReattemptModal,showModal,testId,isReattemptAvailable,interfaceType:interfaceType};
        return state;
    }),
    on(showPassProIntroPopup, (_state) => {
        let state = {..._state};
        state.passProIntroPopup = {
            show: true,
            isInitialState: undefined
        };
        return state;
    }),
    on(dailyCurrentAffairsLoaded,(_state, {payload, tags, date, viewMore}) => {
        let state = {..._state};
        let notes = payload.notes;
        if(viewMore && notes.length) {
            notes = mergeArraysUniqueByKey(state.dailyCurrentAffairs.notes,notes);
        }
        state.dailyCurrentAffairs =  {
            tags,
            date,
            notes,
            count: payload.count,
            isInitialState: undefined
        };
        return state;
    }),
    on(hidePassProIntroPopup, (_state) => {
        let state = {..._state};
        state.passProIntroPopup = {
            show: false,
            isInitialState: undefined
        };
        return state;
    }),
    on(passProPageInfoLoaded, (_state, {payload}) => {
        let state = {..._state};
        state.passProDetails = payload;
        return state;
    }),
    on(monthlyCurrentAffairsLoaded,(_state, {payload, date, month, year, viewMore})=>{
        let state = {..._state};
        let notes = payload.notes;
        if(viewMore && notes.length) {
            notes = mergeArraysUniqueByKey(state.monthlyCurrentAffairs.notes,notes);
        }
        state.monthlyCurrentAffairs = {
            month ,
            year,
            date,
            notes,
            count: payload.count,
            isInitialState: undefined
        };
        return state;
    }),
    on(showGoalFeatureModal, (_state, payload) => {
        let state = {..._state};
        state.goalFeatureModal = {
            showModal: true,
            popupData: payload.popupData
        };
        return state;
    }),
    on(hideGoalFeatureModal, (_state) => {
        let state = {..._state};
        state.goalFeatureModal = {
            showModal: false,
            popupData: {}
        };
        return state;
    }),
    on(getSelectedTabLoaded, (_state, payload: any) => {
        let state = {..._state};
        state.autoSelectTabData = payload?.payload;
        return state;
    }),

    on(setSelectedPass, (state, {selectedPass})=>{
        let nextState = {...state};
        nextState.selectedPass = selectedPass;
        return nextState;
    }),

    on(getPassEliteCardLoaded, (state, {payload}) => {
        let nextState = {...state};
        nextState.passEliteCard = payload;
        return nextState;
    }),

    on(getGoalFreeTestLoaded, (state, {payload}) => {
        let nextState = {...state};
        nextState.goalFreeTest = [...payload.tests];
        return nextState;
    }),

    on(getPassElitePageDetailsLoaded, (state, {payload}) => {
        let nextState = {...state};
        nextState.passElitePageDetails = payload;
        return nextState;
    }),

    on(getAttemptedTestsSuccess, (_state, {payload}) => {
        let state = {..._state};
  
        if(state.goalFreeTest && state.goalFreeTest?.length) {
          state.goalFreeTest =  mergeAttemptDetails(state?.goalFreeTest, payload?.tests, payload?.resumableTests)
        }
          
        return state;
    }),
    on(passElitePitchLoaded, (state, {payload}) => {
        let nextState = {...state};
        nextState.passElitePitch = payload;
        return nextState;
    }),
);

export function appReducer(state, action) {
    return _appReducer(state, action);
}
