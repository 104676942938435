<div class="card {{cssModifierClass}}"
     *ngIf="passProducts.includes(passType)"
     (click)="selectPass(pass)"
     [ngClass]="{'active' : pass.id === selectedPassId}">
    <label>
        <input *ngIf="!isLogoutPaymentPage" type="radio" class="card__button"
               [name]="'pass'+cardType" [checked]="pass.id === selectedPassId"
               [attr.cb-persist]="'pass'+cardType+pass.id"
               (change)="selected($event)" [value]="pass.id">
        <div class="card__content">
            <div class="card__header">
                <h3>{{passType === 'globalPass' ? pass.titleWithoutPass : pass.title}} {{passSuffix}}</h3>
                <span class="card__heading">{{'PASS_CARD_HEADING' | translate : {'pass.validityString' : pass.validityString} }}</span>
            </div>
            <div class="card__ribbons">
                <span class="bestseller" *ngIf="!pass.isCouponApplied && pass.isHighlight" translate>BESTSELLER</span>
                <span class="discount" *ngIf="pass.discountPercentage !== 0">{{'PASS_CARD_DISCOUNT' | translate : {'discountPercentage' : pass.discountPercentage} }}</span>
            </div>
            <div class="card__price">
                <div class="h4">₹ {{pass.oldCost}}</div>
                <span class="h2" *ngIf="!pass.isCouponApplied">₹{{pass.cost}}</span>
                <span class="h2" *ngIf="pass.isCouponApplied">₹{{pass.afterCouponCost}}</span>
            </div>
        </div>
    </label>
    <div class="card__offer">
        <ng-container *ngIf="passType !== 'passPro'">
            <div class="card__offer--show" *ngIf="!pass.isCouponApplied && showOffer && pass.offers.offerType !=='luckyDeal'">
            <span>{{'PASS_CARD_DISCOUNT' | translate : {'discountPercentage' : pass.discountPercentage} }}
                <img src="/assets/img/pass-new/Timer.svg" alt="timer" height="16" width="16">
                {{'PASS_CARD_OFFER_ENDS' | translate : {'countDown' : countDown} }}
            </span>
            </div>
        </ng-container>
        <ng-container *ngIf="passType == 'passPro'">
            <div class="card__offer--show" *ngIf="!pass.isCouponApplied && showOffer &&  pass.offers.offerType  !=='luckyDeal'">
                <span class="pro-discount">50% OFF</span>
                <span>{{'PASS_CARD_DISCOUNT' | translate : {'discountPercentage' : pass.discountPercentage} }}<img
                        src="/assets/img/pass-new/timmer-pro.svg" alt="timer" height="16" width="16">{{'PASS_CARD_OFFER_ENDS' | translate : {'countDown' : countDown} }}</span>
            </div>
        </ng-container>
        <div class="card__offer--coupon"  *ngIf="pass.isCouponApplied && (!showOffer || showOffer && pass.offers.offerType !== 'luckyDeal')">
            <span>
                <img src="/assets/img/pass-new/Checkmark.svg" alt="checkmark" height="16" width="16">
                <strong>{{'COUPON_APPLIED' | translate}}</strong>
                :
                <ng-container *ngIf="pass.appliedCoupon && pass.appliedCoupon.isPassDays">
                        {{'GOT_EXTRA' | translate : {'extra': pass.appliedCoupon.discountUnitValue,'unit': pass.appliedCoupon.discountUnit} }}
                </ng-container>
                <ng-container *ngIf="!pass.appliedCoupon || !pass.appliedCoupon.isPassDays">
                        {{'SAVED_EXTRA' | translate : {'afterCouponCost': pass.cost-pass.afterCouponCost, 'title': pass.title} }}
                </ng-container>

            </span>
        </div>
        <div class="card__offer--kind" *ngIf="showOffer && pass.offers.offerType === 'luckyDeal'">
            <div class="offer-kind">
                <p>{{'PASS_CARD_LUCKYDEAL' | translate : {'title' : pass.title, 'prize':pass.offers.luckyDeal.prize} }}</p>
                <div class="offer-kind__flex">
                    {{'OFFER_ENDS_IN' | translate}}
                    <span class="badge-pass-timer">
                        <img src="/assets/img/pass-new/Timer.svg" alt="timer" height="14" width="14">{{countDown}}
                    </span>
                    <a class="offer-kind__more" href="javascript:void(0)" (click)="kindOffer()" translate>KNOW_MORE</a>
                </div>
            </div>
            <img loading="lazy" [src]="pass.offers.luckyDeal.imageInfo.prizeImage" alt="offer img">
         </div>
    </div>
</div>



<div class="coupon-plan" *ngIf="passType === 'goalSubs' || passType === 'passElite'" (click)="selectPass(pass)"
     [ngClass]="{'active' : pass.id === selectedPassId, 'coupon-banner': true,
     'elite': passType === 'passElite'}">
    <label class="coupon-plan__inner">
        <ng-container *ngIf="!showEMIPlansUI">
            <div class="coupon-plan__column" [ngClass]="{'elite': passType === 'passElite'}">
                <p class="h4">{{pass.titleWithoutPass}}</p>
                <h4 *ngIf="!this.showDiscountedPrice && pass.isCouponApplied">₹{{pass.costPerMonthAfterCoupon}}<span class="text-light"> / month</span></h4>
                <h4 *ngIf="!this.showDiscountedPrice && !pass.isCouponApplied">₹{{pass.costPerMonth}}<span class="text-light"> / month</span></h4>
                <p *ngIf="this.showDiscountedPrice && (pass.oldCost - pass.cost !== 0) || pass.isCouponApplied" class="price-old">₹{{pass.oldCost}}</p>
            </div>
            <div class="coupon-plan__column">
                <ng-container *ngIf="pass.isCouponApplied && couponDiscount>0">
                    <p *ngIf="pass.oldCost - pass.cost !== 0" class="price-drop">Price drop ₹{{pass.oldCost - pass.cost}} + Extra discount ₹{{pass.cost - pass.afterCouponCost}}</p>
                    <p *ngIf="pass.oldCost - pass.cost === 0" class="price-drop"></p>
                    <p class="h4" *ngIf="this.showDiscountedPrice">₹{{pass.afterCouponCost}}</p>
                    <p *ngIf="!this.showDiscountedPrice" class="price-old" style="text-decoration: none">Total ₹{{pass.afterCouponCost}}</p>
                </ng-container>
                <ng-container *ngIf="!pass.isCouponApplied || couponDiscount<=0">
                    <p *ngIf="pass.oldCost - pass.cost !== 0" class="price-drop">Price drop ₹{{pass.oldCost - pass.cost}}</p>
                    <p *ngIf="pass.oldCost - pass.cost === 0" class="price-drop"></p>
                    <p class="h4" *ngIf="this.showDiscountedPrice">₹{{pass.cost}}</p>
                    <p *ngIf="!this.showDiscountedPrice" class="price-old" style="text-decoration: none">Total ₹{{pass.oldCost - (pass.oldCost - pass.cost)}}</p>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="showEMIPlansUI">
            <div class="coupon-plan__column">
                <p class="h4">{{pass.titleWithoutGoal}}</p>
                <div style="text-align: right">
                    <ng-container *ngIf="pass.isCouponApplied">
                        <p *ngIf="showEMIPlansUI && this.showDiscountedPrice && (pass.oldCost - pass.cost !== 0)" class="price-old">₹{{pass.afterCouponCost}}</p>
                    </ng-container>
                    <ng-container *ngIf="!pass.isCouponApplied">
                        <p *ngIf="showEMIPlansUI && this.showDiscountedPrice && (pass.oldCost - pass.cost !== 0)" class="price-old">₹{{pass.cost}}</p>
                    </ng-container>

                    <ng-container *ngIf="pass.isCouponApplied && couponDiscount>0">
                        <div class="h4" *ngIf="this.showDiscountedPrice">{{pass?.couponAppliedEmis?.splits}} EMIs of ₹{{pass?.couponAppliedEmis?.payments[0]?.amount}}</div>
                        <!--    <p *ngIf="!this.showDiscountedPrice" class="price-old" style="text-decoration: none">Total ₹{{pass.afterCouponCost}}</p>-->
                    </ng-container>
                    <ng-container *ngIf="!pass.isCouponApplied || couponDiscount<=0">
                        <div class="h4" *ngIf="this.showDiscountedPrice">{{pass?.emis[0]?.splits}} EMIs of ₹{{pass?.emis[0]?.payments[0]?.amount}}</div>
                        <!--    <p *ngIf="!this.showDiscountedPrice" class="price-old" style="text-decoration: none">Total ₹{{pass.oldCost - (pass.oldCost - pass.cost)}}</p>-->
                    </ng-container>
                </div>
            </div>

        </ng-container>
    </label>
    <div class="card__offer--kind" *ngIf="showOffer && pass.offers.offerType === 'luckyDeal'">
        <div class="offer-kind">
            <p>{{'PASS_CARD_LUCKYDEAL' | translate : {'title' : pass.title, 'prize':pass.offers.luckyDeal.prize} }}</p>
            <div class="offer-kind__flex">
                {{'OFFER_ENDS_IN' | translate}}
                <span class="badge-pass-timer">
                        <img src="/assets/img/pass-new/Timer.svg" alt="timer" height="14" width="14">{{countDown}}
                    </span>
                <a class="offer-kind__more" href="javascript:void(0)" (click)="kindOffer()" translate>KNOW_MORE</a>
            </div>
        </div>
        <img loading="lazy" [src]="pass.offers.luckyDeal.imageInfo.prizeImage" alt="offer img">
    </div>
    <div class="coupon-plan__ribbon" *ngIf="pass.isHighlight"></div> <!-- || (!hasRecommendedPass && pass.isMaxDiscount) -->
    <input type="radio" class="card__button" [name]="'pass'+cardType" [checked]="pass.id === selectedPassId" [attr.cb-persist]="'pass'+cardType+pass.id" (change)="selected($event)" [value]="pass.id">
</div>

