import {arrayModel, objModel, propertyMaps, SourceProjectionMapper,pipeModel} from '../../mapping-framework';
import {Network} from '@core/services/model-manager/main';
import {baseModel, setupModel} from '../../types';
import {Validator as _} from '@shared/utility/validator';
import {isValidDate, MS_IN_ONE_DAY, NS_IN_A_DAY} from '@shared/utility/date-utilities';
import {SpaceConstrainedSerializer , CompactObject} from '@shared/utility/SpaceConstrainedSerializer';
import {SimpleGoalProperties} from "@models/entity/goals/goals.adapter";
import {passTypeProducts} from "@shared/utility/constants";

const MAX_DAYS_FOR_EXPIRING = 7;

export function costPerYear(cost, validity) {
    return validity / NS_IN_A_DAY >= 365 ? Math.round(cost / Math.floor((validity / NS_IN_A_DAY) / 365)) : null;
}

export function costPerMonth(cost,validity){
  return validity/NS_IN_A_DAY > 30 ? Math.round(cost/Math.floor((validity/(NS_IN_A_DAY) )/ 30)) : Math.round(cost/Math.floor((validity/(NS_IN_A_DAY) )))*30
}

export function costPerDay(cost,validity){
    return Math.round(cost/(validity/(NS_IN_A_DAY)));
}

class PassState {
    passExpired  : boolean = true;
    passExpiring : boolean = true;
    passUnpurchased : boolean = true;
    expiryInDays : number = 0;
    expiresOn    : Date = new Date(0);
    passEliteAccess?:boolean =false;
}


class GoalState {
    passExpired  : boolean = true;
    passExpiring : boolean = true;
    passUnpurchased : boolean = true;
    expiryInDays : number = 0;
    expiresOn    : Date = new Date(0);
    passEliteAccess?:boolean =false;
    id:string='';
    title:string='';
}

class PassStateSerializer extends SpaceConstrainedSerializer{
    passState: PassState = new PassState();
    read(compactObject: CompactObject){
        this.setCompactObject(compactObject);
        // order of write and order of read must be same per type
        this.passState.passExpired = this.ReadBool();
        this.passState.passExpiring = this.ReadBool();
        this.passState.passUnpurchased = this.ReadBool();
        this.passState.expiryInDays = this.ReadNum();
        this.passState.expiresOn = this.ReadDate();
    }
    write(ps: PassState){
        this.passState = ps;
        // order of write and order of read must be same per type
        this.WriteBool(ps.passExpired);
        this.WriteBool(ps.passExpiring);
        this.WriteBool(ps.passUnpurchased);
        this.WriteNum(ps.expiryInDays);
        this.WriteDate(ps.expiresOn);
    }
    constructor(){
        super();
    }
}

class GoalStateSerializer extends SpaceConstrainedSerializer{
    passState: GoalState = new GoalState();
    read(compactObject: CompactObject){
        this.setCompactObject(compactObject);
        // order of write and order of read must be same per type
        this.passState.passExpired = this.ReadBool();
        this.passState.passExpiring = this.ReadBool();
        this.passState.passUnpurchased = this.ReadBool();
        this.passState.expiryInDays = this.ReadNum();
        this.passState.expiresOn = this.ReadDate();
        this.passState.id = this.ReadString();
        this.passState.title = this.ReadString();
    }
    write(ps: GoalState){
        this.passState = ps;
        // order of write and order of read must be same per type
        this.WriteBool(ps.passExpired);
        this.WriteBool(ps.passExpiring);
        this.WriteBool(ps.passUnpurchased);
        this.WriteNum(ps.expiryInDays);
        this.WriteDate(ps.expiresOn);
        this.WriteString(ps.id);
        this.WriteString(ps.title);
    }
    constructor(){
        super();
    }
}

export abstract class _passModel{
    abstract filter(passes);

    constructor(){}

    addFrontendKeys(_pass, maxDiscount){
        let pass = {..._pass};
        pass.costPerYear = costPerYear(pass.cost, pass.validity);
        pass.costPerMonth = costPerMonth(pass.cost,pass.validity);
        pass.costPerDay = costPerDay(pass.cost,pass.validity);
        pass.costStr = pass.cost.toLocaleString();
        pass.oldCostStr = pass.oldCost.toLocaleString();
        pass.amtSaved = pass.oldCost - pass.cost;
        pass.amtSavedStr = (pass.oldCost - pass.cost).toLocaleString();
        pass.validityInDays=(pass.validity/NS_IN_A_DAY)
        pass.discountPercentage=(pass.oldCost !== 0) ? Math.round(((pass.oldCost - pass.cost) * 100) / pass.oldCost) : 0;
        pass.validityString=pass.validityString || (pass.validity/NS_IN_A_DAY) + ' days';
        pass.isCouponApplied=false;
        pass.afterCouponCost=-1;
        pass.coupon="";
        // pass.titleWithoutPass=pass.title.replace('Pass', '');
        pass.titleWithoutPass=pass.title;
        pass.titleWithoutGoal=pass.title.split('-')[0].trim();
        pass.discount = pass.oldCost - pass.cost;
        pass.isMaxDiscount = pass.discount === maxDiscount;
        let simplPaymentPass = pass?.allowedPaymentPartners.find(partner => partner.name === 'simpl');
        if(simplPaymentPass){
            pass.simplPaymentPass = simplPaymentPass;
            pass.simplAmount = (pass.cost/3).toFixed(1);
        }
        return pass;
    }

    adapt(rawObj,_forceNotEmptyObjectArrays,cacheStore){
        if(_forceNotEmptyObjectArrays){
            return 1;
        }
        if(rawObj.passes) {
            let passes = this.filter(rawObj.passes) || [];
            if (passes.length) {
                let maxDiscount = Math.max(...passes.map(p => (p.oldCost - p.cost)));
                passes = passes.map(pass => this.addFrontendKeys(pass, maxDiscount));
                passes=this.sortPasses(passes,passes[0].categoryObj.sortOrder,passes[0].categoryObj.sort)
            }
            let metaData={activePassStudents:rawObj.activePassStudents, referralDiscount:rawObj.referralDiscount};
            return {
                ...rawObj,
                passes,
                metaData
            };
            
        } else {
            throw "already processed passes";
        }
    }

    sortPasses(passes,sortOrder,sortKey){
        if(passes.length){
            if(passes[0][sortKey]){
                if(sortOrder === 'desc'){
                    passes.sort(function(thisPass,nextPass) {return nextPass[sortKey]-thisPass[sortKey]})
                }
                else{
                    passes.sort(function(thisPass,nextPass) {return thisPass[sortKey]-nextPass[sortKey]}) 
                }
            }
            return passes;
        }
    }


    static getCheapest(passes){
        let _passes=[...passes];
        if(passes?.length > 0){
            return _passes.sort(function (thisPass, nextPass) {return thisPass.costPerMonth - nextPass.costPerMonth;})[0]
        }
    }

    static getCheapestAfterCouponApplied(passes){
        let couponAppliedPasses = passes.filter(pass => pass.isCouponApplied);
        if(couponAppliedPasses.length > 0) {
            return couponAppliedPasses.sort(function (thisPass, nextPass) {return thisPass.afterCouponCost - nextPass.afterCouponCost;})[0]
        }
    }

    static getStuPassState = (stupass)=>{
        let passState : PassState = new PassState();
        passState.passExpired = false;
        passState.passExpiring = false;
        passState.expiryInDays = 0;
        passState.expiresOn = new Date(0);
        passState.passUnpurchased = true;
        passState.passEliteAccess = false;
        if(stupass){
            passState.expiresOn = new Date(stupass?.expiry);
            var expiry = passState.expiresOn.getTime();
            var expiresIn =  expiry - new Date().getTime();
            passState.passExpired = isValidDate(stupass?.expiry) && expiresIn < 0;
            passState.passExpiring = !passState.passExpired && expiresIn <= MAX_DAYS_FOR_EXPIRING * MS_IN_ONE_DAY;
            passState.expiryInDays = Math.round(expiresIn/MS_IN_ONE_DAY);
            if(expiry>0)
            {
                passState.passUnpurchased = false;
            }
            passState.passEliteAccess = stupass?.consumedFrom?.includes('passElite') || false;
        }
        return passState;
    };

    static getStuGoalState = (stupass)=>{
        let passState : GoalState = new GoalState();
        passState.passExpired = false;
        passState.passExpiring = false;
        passState.expiryInDays = 0;
        passState.expiresOn = new Date(0);
        passState.passUnpurchased = true;
        passState.passEliteAccess = false;
        if(stupass){
            passState.expiresOn = new Date(stupass?.expiry);
            var expiry = passState.expiresOn.getTime();
            var expiresIn =  expiry - new Date().getTime();
            passState.passExpired = isValidDate(stupass?.expiry) && expiresIn < 0;
            passState.passExpiring = !passState.passExpired && expiresIn <= MAX_DAYS_FOR_EXPIRING * MS_IN_ONE_DAY;
            passState.expiryInDays = Math.round(expiresIn/MS_IN_ONE_DAY);
            if(expiry>0)
            {
                passState.passUnpurchased = false;
            }
            passState.passEliteAccess = stupass?.consumedFrom?.includes('passElite') || false;
            passState.id = stupass?.goalId || '';
            passState.title = stupass?.goalTitle?.[0]?.value || '';
        }
        return passState;
    };


    static serialisePassState(passState: PassState){
        let serialiser = new PassStateSerializer();
        serialiser.write(passState);
        return serialiser.getCompactObject();
    }
    static deserialisePassState(compactObject: CompactObject){
        let serialiser = new PassStateSerializer();
        serialiser.read(compactObject);
        return serialiser.passState;
    }
    static isPassActive = (stupass,allValid) => {
        var state = _passModel.getStuPassState(stupass);
        if(allValid){
            return !(state.passUnpurchased || state.passExpired);
        } else {
            return !(state.passUnpurchased || state.passExpired || state.passExpiring);
        }
    };

    static isPassActiveByState = (state,allValid) => {
        if(allValid){
            return !(state.passUnpurchased || state.passExpired);
        } else {
            return !(state.passUnpurchased || state.passExpired || state.passExpiring);
        }
    };
}

export class globalPass extends _passModel implements _passModel{
    filter(passes){
        return passes && passes.filter(pass => passTypeProducts?.includes(pass.type));
    }
}

export class goalPlan extends _passModel implements _passModel{
    filter(passes){
        return passes && passes.filter(pass => pass.type === 'goalSubs');
    }

    static allPlansSerialiser(planStateMap:any){
        let serialisable: any = {};
        try{
            Object.keys(planStateMap).forEach((key) => {
                let serialiser = new GoalStateSerializer();
                serialiser.write(planStateMap[key]);
                serialisable[key] = serialiser.getCompactObject();
            });
            return JSON.stringify(serialisable);
        } catch (e) {
            console.error('Error serialising goal plans',e);
            return '';
        }
    }

    static allPlansDeserialsier(compactObjectString:string){
        let deserialised : any = {};
        try{
            let compactObjectMap = JSON.parse(compactObjectString);
            Object.keys(compactObjectMap).forEach((key) => {
                let serialiser = new GoalStateSerializer();
                serialiser.read(compactObjectMap[key]);
                deserialised[key] = serialiser.passState;
            });
        } catch (e) {
            console.error('Error de-serialising goal plans',e);
        }
        return deserialised;
    }
}

export class passProducts extends _passModel implements _passModel{
    filter(passes){
        return passes;
    }
}

export function transformImageDetails(item){
    let it:any={}
    if(item){
        it.prizeImage=item.prizeImageWeb.min || '';
        it.bannerImage=item.prizeImageWeb.max || '';
    }
    return it;
}

export class _luckyDeal extends baseModel{
    @propertyMaps('prize')
    public prize

    @propertyMaps('startTime')
    public startTime

    @propertyMaps('endTime')
    public endTime

    @propertyMaps('contestRules')
    public contestRules

    @propertyMaps('imageInfo',transformImageDetails)
    public imageInfo
}
setupModel(_luckyDeal,{})

export class offer extends baseModel {
    @propertyMaps()
    public offerStartTime;

    @propertyMaps()
    public offerEndTime;

    @propertyMaps()
    public lightningDeal;

    @propertyMaps()
    public normalDeal;

    @propertyMaps('luckyDeal',pipeModel(objModel(_luckyDeal)),_luckyDeal)
    public luckyDeal;

    @propertyMaps()
    public offerType;

}
setupModel(offer, {});


export class AllowedPaymentPartners extends baseModel {
    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('plan', _.str)
    public plan;
}
setupModel(AllowedPaymentPartners, {});

export class Payment extends baseModel {
    @propertyMaps('amount', _.num)
    public amount;

    @propertyMaps('minCost', _.num)
    public minCost;
}
setupModel(Payment, {});

export class Emi extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('split', _.num)
    public splits;

    @propertyMaps('totalAmount', _.num)
    public totalAmount;

    @propertyMaps('mode', _.str)
    public mode;

    @propertyMaps('payments', arrayModel(Payment), Payment)
    public payments;
}
setupModel(Emi, {});

export class pass extends baseModel{

    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('courseLogo',_.str)
    public image;

    @propertyMaps('title',_.str)
    public title;

    @propertyMaps('type',_.str)
    public type;

    @propertyMaps('oldCost',_.num)
    public oldCost;

    @propertyMaps('cost',_.num)
    public cost;

    @propertyMaps('validity',_.num)
    public validity;

    @propertyMaps('validityString',_.str)
    public validityString;

    @propertyMaps('isOffer', _.bool)
    public isOffer;

    @propertyMaps('offers', objModel(offer), offer)
    public offers

    @propertyMaps('isRecommended',_.bool)
    public isHighlight;

    @propertyMaps('categoryObj',_.obj)
    public categoryObj;

    @propertyMaps('courseLogo',_.str)
    public logo;

    @propertyMaps('allowedPaymentPartners', arrayModel(AllowedPaymentPartners), AllowedPaymentPartners)
    public allowedPaymentPartners;

    @propertyMaps('emis', arrayModel(Emi), Emi)
    public emis;

}
setupModel(pass,{});

export class passesData extends baseModel{

    @propertyMaps('tbPasses', arrayModel(pass),pass)
    public passes;

    @propertyMaps('activePassStudents')
    public activePassStudents;

    @propertyMaps('referralDiscount')
    public referralDiscount;

}
setupModel(passesData,{});

export class getPassesApi{
    static apiEndpoint = 'v2/products/tbpasses';

    static projection;
    static get  __projection(){ 
        if(!getPassesApi.projection){
            getPassesApi.projection = JSON.stringify(new SourceProjectionMapper(passesData).map());
        }
        return getPassesApi.projection;
    }

    static apiCall(network:Network,_params:any){

        var params :any = {};
        if(_params.passType){
            params.type = _params.passType;   
        }
        if(_params.pIds){
            params.pIds=_params.pIds;
        }
        return network.get(getPassesApi.apiEndpoint,{...params,__projection:getPassesApi.__projection});
    }
}

export class GoalPlanDetails extends baseModel{
    @propertyMaps('subscriptions',arrayModel(pass), pass)
    public passes;

    @propertyMaps('activePassStudents')
    public activePassStudents;

    @propertyMaps('referralDiscount')
    public referralDiscount;

    @propertyMaps('goalProperties',objModel(SimpleGoalProperties),SimpleGoalProperties)
    public goalProperties;

    @propertyMaps('coupon')
    public coupon;
}
setupModel(GoalPlanDetails,{});

export class getGoalPlanDetailsApi{
    static apiEndpoint = 'v1/products/goal-subscription';

    static projection;
    static get  __projection(){ 
        if(!getGoalPlanDetailsApi.projection){
            getGoalPlanDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(GoalPlanDetails).map());
        }
        return getGoalPlanDetailsApi.projection;
    }

    static apiCall(network:Network,params:any){ //gid
        return network.get(getGoalPlanDetailsApi.apiEndpoint,{...params,__projection:getGoalPlanDetailsApi.__projection});
    }
}


export class PassProductsDetails extends baseModel{
    @propertyMaps('products',arrayModel(pass))
    public passes;
}
setupModel(PassProductsDetails,{});

export class getPassProductsApi{
    static apiEndpoint = 'v1/products';

    static projection;
    static get  __projection(){ 
        if(!getPassProductsApi.projection){
            getPassProductsApi.projection = JSON.stringify(new SourceProjectionMapper(PassProductsDetails).map());
        }
        return getPassProductsApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(getPassProductsApi.apiEndpoint,{pids:params.productIds,__projection:getPassProductsApi.__projection});
    }
}

