import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {Validator as _} from "@shared/utility/validator";
import { _Faq } from "../targets/target.adapter";
import { _Test } from "../targets/target.test.adapter";
import { addTestAvailableKeys, processTests } from "@angular-commons/shared/utility/test-utilities";

export class properties extends baseModel {
    @propertyMaps('title',  _.str)
    public title

    @propertyMaps('icon', _.logoValidator)
    public icon

    @propertyMaps('pointers', _.arr)
    public pointers

    @propertyMaps('slug', _.str)
    public slug

    @propertyMaps('faqs', _.arr)
    public faqs

    @propertyMaps('includes', _.arr)
    public includes
}
setupModel(properties, {})

export class eliteDetails extends baseModel {
    @propertyMaps('properties', objModel(properties),properties)
    public properties:properties

    @propertyMaps('stats', _.obj)
    public stats
}
setupModel(eliteDetails, {});

export class passEliteDetails extends baseModel{
    @propertyMaps('eliteCards',  arrayModel(eliteDetails), eliteDetails)
    public eliteCards
}
setupModel(passEliteDetails, {})

export class goalFreeTestDetails extends baseModel {
    @propertyMaps('tests', pipeModel(arrayModel(_Test), processTests, addTestAvailableKeys), _Test)
    public tests;
}
setupModel(goalFreeTestDetails, {})



export class passElitePageDetails extends baseModel {
    @propertyMaps('faq', arrayModel(_Faq), _Faq)
    public faqs;
}
setupModel(passElitePageDetails, {})


export class getPassEliteCardApi  {
    static apiEndpoint = 'v1/pass-elite/cards';
    static projection;
    static get  __projection() {
        if (!getPassEliteCardApi.projection) {
            getPassEliteCardApi.projection = JSON.stringify( new SourceProjectionMapper(passEliteDetails).map())
        }
        return getPassEliteCardApi.projection
    }

    static apiCall(network:Network,_params:any) {
        var params: any = {};
        if(_params.pageType) {
            params.type = _params.pageType;
            params.limit = 10;
        }else{
            params.limit = 10;
        }
        return network.get(getPassEliteCardApi.apiEndpoint,{...params, __projection:getPassEliteCardApi.__projection});

    }
}

export class getGoalFreeTestApi {
    static apiEndpoint = 'v1/goals/{gid}/tests/recommended';
    static projection;
    static get  __projection() {
        if (!getGoalFreeTestApi.projection) {
            getGoalFreeTestApi.projection = JSON.stringify( new SourceProjectionMapper(goalFreeTestDetails).map())
        }
        return getGoalFreeTestApi.projection
    }


    static apiCall(network:Network,_params:any) {
        var params: any = {};
        if(_params.pageType) {
            params.type = _params.pageType;
        }
        return network.get(getGoalFreeTestApi.apiEndpoint.replace('{gid}',_params.gid),{__projection:getGoalFreeTestApi.__projection});

    }
}

export class getPassElitePageDetailsApi {
    static apiEndpoint = 'v1/pass-screen';
    static projection;
    static get  __projection() {
        if (!getPassElitePageDetailsApi.projection) {
            getPassElitePageDetailsApi.projection = JSON.stringify( new SourceProjectionMapper(passElitePageDetails).map())
        }
        return getPassElitePageDetailsApi.projection
    }


    static apiCall(network:Network,_params:any) {
        var params: any = {};
        if(_params.passType) {
            params.type = _params.passType;
        }
        return network.get(getPassElitePageDetailsApi.apiEndpoint,{type:params.type,  __projection:getPassElitePageDetailsApi.__projection});

    }
}
