import { _passModel } from "@angular-commons/models/entity/passes/passes.adapter";
import {getGoalPlansPageUrl} from "@shared/utility/goal-utility";

export function getPassPurchaseUrl(passId,coupon=''){
    let ptype=JSON.stringify({id:passId})
    return `/pass?trigger=paymentflow&ptype=${ptype}&coupon=${coupon}&who=TbPass`;
}

export function getGoalPlanPurchaseUrl(passId,goalSlug,coupon=''){
    let ptype=JSON.stringify({id:passId})
    return `${getGoalPlansPageUrl(goalSlug)}?trigger=paymentflow&ptype=${ptype}&coupon=${coupon}&who=TbPass`;
}

export function getUserType(access){
    let userType = [];
    if(!access?.passAccess && !access?.passProAccess && !access?.goalSubsAccess){
        userType.push('free');
    }
    if(access?.passAccess){
        userType.push('pass');
    }
    if(access?.passProAccess){
        userType.push('passPro');
    }
    if(access?.goalSubsAccess){
        userType.push('super');
    }
    if(access?.passEliteAccess){
        userType.push('elite');
    }
    return userType;
}

export function getUserTypeState(globalPassExpiry,passProExpiry,goalSubs){
    let passAccess = _passModel.isPassActive(globalPassExpiry, true);
    let passProAccess = _passModel.isPassActive(passProExpiry, true);
    let goalSubsAccess = false;
    let passEliteAccess = false
    for(let key in goalSubs){
        goalSubsAccess = !(goalSubs[key].passUnpurchased || goalSubs[key].passExpired);
        if(goalSubsAccess){
            break;
        }
    }
    for (let key in goalSubs) {
        passEliteAccess = !(goalSubs[key].passUnpurchased || goalSubs[key].passExpired) && goalSubs[key]?.passEliteAccess;
        if (passEliteAccess) {
            break;
        }
    }
    
    return getUserType({passAccess,passProAccess,goalSubsAccess,passEliteAccess});
}