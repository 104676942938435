<!-- Common conditions for all pass types --> 
<!-- {{!isInGoalPageHeader && showDefaultStatus && showHeading}} -->
<ng-container *ngIf="(isServer || !isLoggedIn || (globalPassState && globalPassState.passUnpurchased) || !globalPassState) && (!isReferredUser || !referrer || (student && student.isPaidUser)) && !isInGoalPageHeader && showDefaultStatus && showHeading">
  <div class="pass-status pass-status__plan" [ngClass]="{'pass-status--goal-plan': passType === 'goalSubs'}">
    {{ (isEMIPlan ? 'Select your EMI plan' : 'PICK_YOUR_PLAN') | translate }}
  </div>
</ng-container>

<!-- Active User Strip -->
<div class="pass-status pass-status__users" *ngIf="isMobile && !hideActiveUserStrip && passType === 'globalPass' && (isServer || !isLoggedIn || (globalPassState && globalPassState.passUnpurchased)) && (!isReferredUser || !referrer || (student && student.isPaidUser))">
    <div>
        <span class="icon"></span>
        <p><strong>{{ activeUsersCount }}</strong> {{ 'ACTIVE_PASS_USERS' | translate }}</p>
    </div>
    <image-bubble [bubbleImages]="activePassStudents"></image-bubble>
</div>

<!-- Pass or Goal or Pass Elite Subscription Expired -->
<div class="pass-status pass-status__expired" *ngIf="!isServer && (passType === 'globalPass' || passType === 'goalSubs' || passType === 'passElite') && isLoggedIn && globalPassState && globalPassState.passExpired && !globalPassState.passUnpurchased && (!isReferredUser || !referrer || (student && student.isPaidUser))">
    <div>
        <img src="/assets/img/pass-new/Report.svg" alt="report" height="24" width="24">
        <p class="mweb">
            <ng-container *ngIf="passType == 'globalPass'">
                {{ 'PASS_EXPIRED' | translate }}
            </ng-container>
            <ng-container *ngIf="passType == 'goalSubs'">
                {{ 'SUPER_COACHING_EXPIRED' | translate }}
            </ng-container>
            <ng-container *ngIf="passType == 'passElite'">
                {{ 'SUPER_COACHING_EXPIRED' | translate }}
            </ng-container>
        </p>
        <p class="desktop">
            <ng-container *ngIf="passType === 'globalPass'">
                {{ 'PASS_EXPIRED_RENEW_NOW' | translate }}
            </ng-container>
            <ng-container *ngIf="passType == 'goalSubs'">
                {{ 'SUPER_COACHING_EXPIRED_RENEW_NOW' | translate }}
            </ng-container>
            <ng-container *ngIf="passType == 'passElite'">
                {{ 'PASS_ELITE_EXPIRED_RENEW_NOW' | translate }}
            </ng-container>
        </p>
    </div>
    <span class="text-blue" (click)="renewPass()">
        <ng-container *ngIf="passType == 'globalPass'">
            {{ 'RENEW_PASS' | translate }}
        </ng-container>
        <ng-container *ngIf="passType == 'goalSubs'">
            {{ 'RENEW_SUPER_COACHING' | translate }}
        </ng-container>
        <ng-container *ngIf="passType == 'passElite'">
            {{ 'RENEW_PASS_ElITE' | translate }}
        </ng-container>
    </span>
</div>

<!-- Pass Pro Expired -->
<div class="pass-status pass-status__expired" *ngIf="!isServer && passType === 'passPro' && isLoggedIn
          && passProState?.passExpired && !passProState?.passUnpurchased
          && (!isReferredUser || !referrer || (student && student.isPaidUser))">
    <div>
        <img src="/assets/img/pass-new/Report.svg" alt="report" height="24" width="24">
        <p class="mweb">
            <ng-container>
                {{ 'PASS_PRO_EXPIRED' | translate }}
            </ng-container>
        </p>
        <p class="desktop">
            <ng-container>
                {{ 'PASS_PRO_EXPIRED_RENEW_NOW' | translate }}
            </ng-container>
        </p>
    </div>
</div>

<!-- Add Days (Pass or Pass Pro) -->
<div class="pass-status pass-status__add" [ngClass]="{'pass-status__pro': (passProAccess && passType === 'passPro') || (passType == 'globalPass' && passAccess && passProAccess), 'pass-status__elite': (passType === 'passElite' && currentGoalStateAccess?.passEliteAccess) }" *ngIf="!isServer && isLoggedIn && (globalPassState && !globalPassState.passExpired && !globalPassState.passUnpurchased || (passType === 'passPro' && passProAccess) || (passType === 'passElite' && currentGoalStateAccess?.passEliteAccess)) && (!isReferredUser || !referrer || (student && student.isPaidUser))">
    <div>
        <img *ngIf="passAccess && !passProAccess" src="/assets/img/pass-new/blue-pass.svg" alt="profile icon">
        <img *ngIf="passType == 'globalPass' && passAccess && passProAccess" src="/assets/img/pass-new/pass-pro-brand.svg?v=05092023" alt="pass-pro">
        <img *ngIf="passType == 'passPro' && passProAccess" src="/assets/img/pass-new/pass-pro-brand.svg?v=05092023" alt="pass-pro">
        <p class="mweb" *ngIf="!passProAccess && passType != 'passElite'">{{ globalPassState.expiryInDays }} Days Left<br>
            <span class="text-xsm text-light" *ngIf="passType == 'globalPass' || passType === 'passPro'">from your Testbook pass</span>
            <span class="text-xsm text-light" *ngIf="passType == 'goalSubs'">from your Testbook supercoaching</span>
        </p>
        <p class="mweb" *ngIf="passProAccess">{{ passProState.expiryInDays }} Days Left<br>
            <span class="text-xsm text-light" *ngIf="passType == 'passPro' || passType == 'globalPass'">from your Testbook pass pro</span>
        </p>

        <ng-container *ngIf="passType == 'globalPass' && passAccess && passProAccess">
            <p class="desktop" [innerHTML]="'PASS_PRO_EXPIRING_DAYS' | translate : {'expiryInDays' : passProState.expiryInDays}"></p>
        </ng-container>
        <ng-container *ngIf="passAccess && !passProAccess">
            <p class="desktop" [innerHTML]="'PASS_EXPIRING_DAYS' | translate : {'expiryInDays' : globalPassState.expiryInDays}"></p>
        </ng-container>
        <ng-container *ngIf="passType == 'passPro' && passProAccess">
            <p class="desktop" [innerHTML]="'PASS_PRO_EXPIRING_DAYS' | translate : {'expiryInDays' : passProState.expiryInDays}"></p>
        </ng-container>
        <ng-container *ngIf="passType == 'goalSubs'">
            <p class="desktop" [innerHTML]="'SUPER_COACHING_EXPIRING_DAYS' | translate : {'expiryInDays' : globalPassState.expiryInDays}"></p>
        </ng-container>
        <ng-container *ngIf="passType == 'passElite' && currentGoalStateAccess?.passEliteAccess">
            <img src="/assets/img/pass-new/pass-elite-logo.svg" alt="pass-pro">
            <p class="desktop" [innerHTML]="'PASS_ELITE_EXPIRING_DAYS' | translate : {'expiryInDays' : currentGoalStateAccess?.expiryInDays}"></p>
        </ng-container>
    </div>
    <span class="text-blue cursor-pointer" *ngIf="!passAccess" (click)="renewPass()">{{ 'ADD_DAYS' | translate }}</span>
</div>

<!-- Pass Page for Pass and Pass Pro -->
<div class="pass-status pass-status__add" *ngIf="!isServer && (passType == 'globalPass' && passAccess && passProAccess) && isLoggedIn
          && (globalPassState && !globalPassState.passExpired && !globalPassState.passUnpurchased)
          && (!isReferredUser || !referrer || (student && student.isPaidUser))">
    <div>
        <img src="/assets/img/pass-new/blue-pass.svg" alt="pass">
        <p class="mweb"> {{ globalPassState.expiryInDays }} Days Left<br>
            <span class="text-xsm text-light">from your Testbook pass</span>
        </p>
        <ng-container>
            <p class="desktop" [innerHTML]="'PASS_EXPIRING_DAYS' | translate : {'expiryInDays' : globalPassState.expiryInDays}"></p>
        </ng-container>
    </div>
</div>

<!-- Referral Discount -->
<div class="pass-status pass-status__referral" *ngIf="!isServer && isReferredUser && referrer && (!isLoggedIn || student && !student.isPaidUser)">
    <div>
        <img loading="lazy" [src]="(referrer.image === '') ? '/assets/img/pass-new/profile-icon.png' : referrer.image" alt="profile icon" height="38" width="38">
        <p class="mweb">{{ 'DISCOUNT_APPLYING_REFERRAL' | translate : {'username': username, 'referralDiscount': referralDiscount, 'referrer.name': referrer.name} }}</p>
        <p class="desktop" [innerHTML]="'DISCOUNT_GIFTED' | translate : {'referrer.name': referrer.name, 'referralDiscount': referralDiscount}"></p>
    </div>
</div>
