
import { Network } from "@angular-commons/core/services/model-manager/main";
import { arrayModel, propertyMaps, SourceProjectionMapper } from "@angular-commons/models/mapping-framework";
import { baseModel, setupModel } from "@angular-commons/models/types";
import { Validator as _ } from "../../../shared/utility/validator";
import { objModel } from '@models/mapping-framework';

export class setCurrentPracticeGroupBody extends baseModel{
  @propertyMaps('groupId', _.str)
  public groupId;
}
setupModel(setCurrentPracticeGroupBody, {});
export class setCurrentPracticeGroupParams extends baseModel{
  @propertyMaps('body', objModel(setCurrentPracticeGroupBody), setCurrentPracticeGroupBody)
  public body;
}
setupModel(setCurrentPracticeGroupParams, {});

export class MetaTag extends baseModel{
  @propertyMaps('title', _.str)
  public title
  @propertyMaps('description', _.str)
  public description
  @propertyMaps('ogTitle', _.str)
  public ogTitle
  @propertyMaps('ogDescription', _.str)
  public ogDescription
  @propertyMaps('ogImage', _.str)
  public ogImage
  @propertyMaps('twitterTitle', _.str)
  public twitterTitle
  @propertyMaps('twitterDescription', _.str)
  public twitterDescription
  @propertyMaps('noIndex', _.bool)
  public noIndex
  @propertyMaps('canonical', _.str)
  public canonical
  @propertyMaps('contentBlockOnTop', _.str)
  public contentBlockOnTop
  @propertyMaps('contentBlockAtEnd', _.str)
  public contentBlockAtEnd
  @propertyMaps('otherRelatedTopics', _.arr)
  public otherRelatedTopics
  @propertyMaps('otherRelatedSubject', _.arr)
  public otherRelatedSubject
  @propertyMaps('otherRelatedChapter', _.arr)
  public otherRelatedChapter
}
setupModel(MetaTag, {});

export class SeoProperties extends baseModel{
  @propertyMaps('slug', _.str)
  public slug
  @propertyMaps('meta', objModel(MetaTag),MetaTag)
  public metaTag
}
setupModel(SeoProperties, {});


export class Properties extends baseModel{
  @propertyMaps('title', _.str)  
  public title
  @propertyMaps('logo', _.str)
  public logo
  @propertyMaps('seoProperties', objModel(SeoProperties),SeoProperties)
  public seoProperties
  @propertyMaps('groupTitleAlias', _.str)
  public groupTitleAlias
  @propertyMaps('questionCount', _.num)
  public questionCount
}
setupModel(Properties, {});

export class Chapter extends baseModel{
  @propertyMaps('_id', _.str)
  public chapterId
  @propertyMaps("chapter", objModel(Properties), Properties)
  public chapter;
}
setupModel(Chapter, {});
export class Groups extends baseModel{
  @propertyMaps('_id', _.str)
  public id
  @propertyMaps('properties', objModel(Properties),Properties)
  public properties
}
setupModel(Groups, {});

export class freePracticeGroup extends baseModel {
  @propertyMaps("groups",arrayModel(Groups),Groups)
  public groups;
}
setupModel(freePracticeGroup, {})
export class Group extends baseModel{
  @propertyMaps('_id', _.str)
  public id
  @propertyMaps("properties", objModel(Properties), Properties)
  public properties;
}
setupModel(Group, {})

export class freePracticeIndividualSeoGroup extends baseModel{
  @propertyMaps("group", objModel(Group), Group)
  public group;
}
setupModel(freePracticeIndividualSeoGroup, {})
export class SeoData extends baseModel{
  @propertyMaps('slug', _.str)
  public slug
  @propertyMaps('metaTag', objModel(MetaTag),MetaTag)
  public metaTag
}
setupModel(SeoData, {});
export class GetSeoData extends baseModel{
  @propertyMaps("data", objModel(SeoData), SeoData)
  public seoData
}
setupModel(GetSeoData, {});
export interface RecentPracticeParams {
  groupId?: string,
  chapterId?: string
}

export class purchaseInfoType extends baseModel{
  @propertyMaps('type', _.str)
  public type;
  @propertyMaps("id",_.str)
  public id;
  @propertyMaps("consumedFrom",_.arr)
  public consumedFrom;
}
setupModel(purchaseInfoType, {});


export class TargetGroup extends baseModel{
  @propertyMaps('_id', _.str)
  public id
  @propertyMaps('title', _.str)
  public title

  @propertyMaps('isPrimary', _.str)
  public isPrimary
}
setupModel(TargetGroup, {});

export class Practice extends baseModel {

    @propertyMaps('_id', _.str)
    public id;
    @propertyMaps('title', _.str)
    public title;
    @propertyMaps('description', _.str)
    public description;
    @propertyMaps('ques', _.arr)
    public questions;

    @propertyMaps('quesCount', _.num)
    public questionsCount;
    @propertyMaps('displayQCount', _.num)
    public displayQuestionsCount;
    @propertyMaps('completedQuestionsCount', _.num)
    public completedQuestionsCount;
    
    @propertyMaps('stage', _.str)
    public stage;
    @propertyMaps('subjectId', _.str)
    public subjectId;
    @propertyMaps('chapterId', _.str)
    public chapterId;
    @propertyMaps('chapterTitle', _.str)
    public chapterTitle;
    @propertyMaps('sectionTitle', _.str)
    public sectionTitle
    @propertyMaps('sectionId', _.str)
    public sectionId;

    @propertyMaps('accuracy', _.num)
    public accuracy;
    @propertyMaps('speed', _.num)
    public speed;

    @propertyMaps('accuracyStage', _.num)
    public accuracyStage;
    @propertyMaps('speedStage', _.num)
    public speedStage;

    @propertyMaps('targetAccuracy', _.num)
    public targetAccuracy;
    @propertyMaps('targetSpeed', _.num)
    public targetSpeed;

    @propertyMaps('level', _.num)
    public level;

    @propertyMaps('purchaseInfo',arrayModel(purchaseInfoType),purchaseInfoType)
    public purchaseInfo;

    @propertyMaps('hasAccess', _.bool)
    public hasAccess;
    
    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('studentImages', _.arr)
    public studentImages;
    @propertyMaps('completedStudentCount', _.num)
    public completedStudentCount;

    @propertyMaps('targetGroup',arrayModel(TargetGroup),TargetGroup)
    public targetG;

    @propertyMaps('targetSuperGroup',arrayModel(TargetGroup),TargetGroup)
    public targetSG;
}
setupModel(Practice, {});
export class PracticesModel extends baseModel {

  @propertyMaps('practices', arrayModel(Practice),Practice)
  public practices;

}
setupModel(PracticesModel, {});

export class PracticeModel extends baseModel {

  @propertyMaps('practice', objModel(Practice),Practice)
  public practice;

}
setupModel(PracticeModel, {});

export class Summary extends baseModel{
    @propertyMaps('accuracy', _.num)
    public accuracy;
    @propertyMaps('correctAttempts', _.num)
    public correctAttempts;
    @propertyMaps('incorrectAttempts', _.num)
    public incorrectAttempts;
    @propertyMaps('speed', _.num)
    public speed;
    @propertyMaps('totalAttempts', _.num)
    public totalAttempts;
    @propertyMaps('lastWeekAttempts', _.num)
    public lastWeekAttempts;
    
}
setupModel(Summary, {});
export class PracticeSummary extends baseModel{
  @propertyMaps('summary', objModel(Summary),Summary)
  public summary;
}
setupModel(PracticeSummary, {});

export class slugForFreeGroup extends baseModel{
  @propertyMaps('slug', _.str)
  public slug;
  @propertyMaps('groupId', _.str)
  public groupId;
}
setupModel(slugForFreeGroup, {})

export class slugForFreeChapter extends baseModel{
  @propertyMaps('slug', _.str)
  public slug;
  @propertyMaps('chapterId', _.str)
  public chapterId;
}
setupModel(slugForFreeChapter, {})
export class resetPracticeAdapter extends baseModel{

  @propertyMaps('practiceId', _.str)
  public practiceId;

  @propertyMaps('parentId', _.str)
  public parentId;

  @propertyMaps('parentType', _.str)
  public parentType;

}
setupModel(resetPracticeAdapter, {})

export interface PracticeSummaryParams{
  groupId:string
}
export class GetRecentPracticesApi {
    static apiEndpoint = 'v2/study-tab/recent-practices';

    static projection;
    static get  __projection(){ 
      if(!GetRecentPracticesApi.projection){
        GetRecentPracticesApi.projection = JSON.stringify(new SourceProjectionMapper(PracticesModel).map());
      }
      return GetRecentPracticesApi.projection;
  }
  
    static apiCall(network: Network, params: RecentPracticeParams) {
      return network.get(GetRecentPracticesApi.apiEndpoint, {...params, __projection: GetRecentPracticesApi.__projection });
    }
}

export class GetRecentPracticeApi {
  static apiEndpoint = 'v2/study-tab/recent-practice';

  static projection;
  static get  __projection(){ 
    if(!GetRecentPracticeApi.projection){
      GetRecentPracticeApi.projection = JSON.stringify(new SourceProjectionMapper(PracticeModel).map());
    }
    return GetRecentPracticeApi.projection;
}

  static apiCall(network: Network, params: RecentPracticeParams) {
    return network.get(GetRecentPracticeApi.apiEndpoint, {...params, __projection: GetRecentPracticeApi.__projection });
  }
}

export class GetPracticeAnalysisApi {
    static apiEndpoint = 'v2/study-tab/practice-summary';

    static projection;
    static get  __projection(){ 
      if(!GetPracticeAnalysisApi.projection){
        GetPracticeAnalysisApi.projection = JSON.stringify(new SourceProjectionMapper(PracticeSummary).map());
      }
      return GetPracticeAnalysisApi.projection;
  }
  
    static apiCall(network: Network, params:PracticeSummaryParams) {
      return network.get(GetPracticeAnalysisApi.apiEndpoint, {...params, __projection: GetPracticeAnalysisApi.__projection });
    }
}

export class GetAllFreeGroupsApi{
  static apiEndpoint = 'v2/study-tab/groups';

  static projection;
  static get  __projection(){ 
    if(!GetAllFreeGroupsApi.projection){
      GetAllFreeGroupsApi.projection = JSON.stringify(new SourceProjectionMapper(freePracticeGroup).map());
    }
    return GetAllFreeGroupsApi.projection;
}

  static apiCall(network:Network,params:any){
      return network.get(GetAllFreeGroupsApi.apiEndpoint,{__projection:GetAllFreeGroupsApi.__projection,...params});
  }
}
export class freePracticeIndividualSeoGroupApi{
  static apiEndpoint = 'v2/study-tab/group/{groupId}';
  static projection;
  static get  __projection(){ 
    if(!freePracticeIndividualSeoGroupApi.projection){
      freePracticeIndividualSeoGroupApi.projection = JSON.stringify(new SourceProjectionMapper(freePracticeIndividualSeoGroup).map());
    }
    return freePracticeIndividualSeoGroupApi.projection;
}

  static apiCall(network: Network, params: any) {
    
    return network.get(freePracticeIndividualSeoGroupApi.apiEndpoint.replace('{groupId}',params?.groupId),{__projection:freePracticeIndividualSeoGroupApi.__projection,...params});
  }
}

export class GetSeoDataApi{
  static apiEndpoint = 'v1/common/data?type=studyTab';

  static projection;
  static get  __projection(){ 
    if(!GetSeoDataApi.projection){
      GetSeoDataApi.projection = JSON.stringify(new SourceProjectionMapper(GetSeoData).map());
    }
    return GetSeoDataApi.projection;
}

  static apiCall(network:Network,params:any){
      return network.get(GetSeoDataApi.apiEndpoint,{__projection:GetSeoDataApi.__projection,...params});
  }
}

export class resetPracticesApi{
    static apiEndpoint = 'v2.2/practices/{practiceId}/reattempt'

    static  __projection = 1;

  static apiCall(network: Network, params: resetPracticeAdapter) {
    let paramObj:any={
      __projection:resetPracticesApi.__projection
    }
    if(params.practiceId){
            paramObj.practiceId=params.practiceId
            paramObj.parentId=params.parentId; 
            paramObj.parentType=params.parentType;
    }
   return network.post(resetPracticesApi.apiEndpoint.replace('{practiceId}',params.practiceId),{},paramObj);
       
    }
}
export class setCurrentPracticeGroupApi{
  static apiEndpoint = 'v1/study-tab/groups'
  static apiCall(network: Network, params: any) {
    return network.post(setCurrentPracticeGroupApi.apiEndpoint, params);
  }
}