import { Validator as _ } from "@shared/utility/validator";
import { baseModel, setupModel } from "@models/types";
import { arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper } from "@models/mapping-framework";
import { Network } from "@core/services/model-manager/main";
import { processOverallCutoffs, _Test } from "../targets/target.test.adapter";
import { languageModifier, processTestProgress, processTests } from "@angular-commons/shared/utility/test-utilities";
import { _AttemptedTest } from "../tests/test.adapter";
import {PurchaseInfo} from "@models/entity/purchaseInfo";


export class _Filter extends baseModel{
    @propertyMaps('_id')
    public id;
    
    @propertyMaps()
    public name;
    
    @propertyMaps()
    public isSelected;
    
    @propertyMaps()
    public isFilter;
    
    @propertyMaps()
    public count;
    
    @propertyMaps()
    public quizCount;
    
    @propertyMaps()
    public testCount;
    
    @propertyMaps()
    public icon;
}
setupModel(_Filter,{});

export class _FiltersArray extends baseModel{
    @propertyMaps('filter', pipeModel(transformFilter, arrayModel(_Filter)),_Filter)
    public filter;
}
setupModel(_FiltersArray,{});

export class Filter extends baseModel{
    @propertyMaps('id', _.str)
    public id;
    
    @propertyMaps('name', _.str)
    public name;
    
    @propertyMaps('isSelected', _.bool)
    public isSelected;
    
    @propertyMaps('isFilter', _.bool)
    public isFilter;
    
    @propertyMaps('count', _.num)
    public count;
    
    @propertyMaps('quizCount', _.num)
    public quizCount;
    
    @propertyMaps('testCount', _.num)
    public testCount;
    
    @propertyMaps('icon', _.str)
    public icon;
}
setupModel(Filter,{type: 'filter'});

export function transformFilter(item) {
    return item[0].items
}

export class _TestCard extends baseModel{
    @propertyMaps()
    public analysisAfter;
    
    @propertyMaps()
    public availFrom;

    @propertyMaps()
    public availTill;
    
    @propertyMaps()
    public category;
    
    @propertyMaps('categoryName', _.str)
    public categoryName;
    
    @propertyMaps()
    public course;
    
    @propertyMaps('cutOffs', _.obj)
    public cutOffs;

    @propertyMaps('cutOffs', processOverallCutoffs)
    public overallCutoffs;
    
    @propertyMaps()
    public description;
    
    @propertyMaps()
    public duration;
    
    @propertyMaps()
    public endTime;
    
    @propertyMaps()
    public examDate;
    
    @propertyMaps()
    public id;
    
    @propertyMaps()
    public isAnalysisGenerated;
    
    @propertyMaps('isAttempted', _.bool)
    public isAttempted;
    
    @propertyMaps()
    public isFree;
    
    @propertyMaps()
    public isLive;
    
    @propertyMaps()
    public isPdfAvailable;
    
    @propertyMaps()
    public isQuiz;

    @propertyMaps('isQuiz', processIsTest)
    public isTest;
    
    @propertyMaps()
    public isScholarship;
    
    @propertyMaps()
    public isTestAvailable;
    
    @propertyMaps()
    public languages;

    @propertyMaps('languages', languageModifier(', ',false))
    public languagesStr;
    
    @propertyMaps()
    public pdfId;

    @propertyMaps()
    public isSolutionPresent;
    
    @propertyMaps()
    public pdfLanguages;
    
    @propertyMaps()
    public popularity;
    
    @propertyMaps()
    public questionCount;
    
    @propertyMaps()
    public registeredCount;
    
    @propertyMaps()
    public scholarshipId;
    
    @propertyMaps()
    public servesOn;
    
    @propertyMaps()
    public specificExams;
    
    @propertyMaps()
    public startTime;
    
    @propertyMaps()
    public status;
    
    @propertyMaps()
    public summary;

    @propertyMaps('summary', objModel(_AttemptedTest))
    public progress;
    
    @propertyMaps()
    public target;

    @propertyMaps()
    public labelTags;
    
    @propertyMaps()
    public title;
    
    @propertyMaps()
    public totalAttempts;
    
    @propertyMaps()
    public totalMark;

    @propertyMaps()
    public hasAccess;

    @propertyMaps('purchaseInfo',arrayModel(PurchaseInfo),PurchaseInfo)
    public purchaseInfo;

    @propertyMaps()
    public reattemptPurchaseInfo;
}
setupModel(_TestCard,{});

export class _TestCardArray extends baseModel{
    @propertyMaps('liveTests', pipeModel(arrayModel(_TestCard), processTests, processTestProgress),_TestCard)
    public liveTests;

    @propertyMaps()
     public registeredTests;
}
setupModel(_TestCardArray,{});


export class _TestCardAttemptedSummary extends baseModel{
    @propertyMaps()
    public attemptedOn;
    
    @propertyMaps()
    public attemptedQuesions;
    
    @propertyMaps()
    public correct;
    
    @propertyMaps()
    public markScored;
    
    @propertyMaps()
    public rank;
    
    @propertyMaps()
    public timeTaken;
    
    @propertyMaps()
    public totalStudents;

    @propertyMaps()
    public isResumableForNextAttempt;

    @propertyMaps()
    public maxAllowedAttempts;

    @propertyMaps()
    public attemptNo;

    @propertyMaps()
    public isReattemptable;
}
setupModel(_TestCardAttemptedSummary,{});

export class _TestCardAttempted extends baseModel{
    @propertyMaps('details', objModel(_TestCard), _TestCard)
    public details;
    
    @propertyMaps('summary', objModel(_TestCardAttemptedSummary), _TestCardAttemptedSummary)
    public summary;
}
setupModel(_TestCardAttempted,{});

export class _TestCardAttemptedArray extends baseModel{
    @propertyMaps('attemptedTests', pipeModel(arrayModel(_TestCardAttempted)),_TestCardAttempted)
    public attemptedTests;
}
setupModel(_TestCardAttemptedArray,{});

export class TestCardAttemptedSummary extends baseModel{
    @propertyMaps()
    public attemptedOn;
    
    @propertyMaps()
    public attemptedQuesions;
    
    @propertyMaps()
    public correct;
    
    @propertyMaps()
    public markScored;
    
    @propertyMaps()
    public rank;
    
    @propertyMaps()
    public timeTaken;
    
    @propertyMaps()
    public totalStudents;

    @propertyMaps()
    public isResumableForNextAttempt;

    @propertyMaps()
    public maxAllowedAttempts;

    @propertyMaps()
    public attemptNo;

    @propertyMaps()
    public isReattemptable;
}
setupModel(TestCardAttemptedSummary,{});

export function processIsTest(isQuiz) {
    return !isQuiz
}

export class GetCategoryFilterApi {
    static apiEndpoint = 'v2_1/live-panel/filter';
    
    static __projection = 1 ;
    
    static apiCall(network: Network) {
        return network.get(GetCategoryFilterApi.apiEndpoint, {__projection: GetCategoryFilterApi.__projection})
    }

}

export class GetTestDataApi {
    static apiEndpoint = 'v2_1/live-panel/all';

    static projection;
    static get  __projection(){ 
        if(!GetTestDataApi.projection){
            GetTestDataApi.projection = JSON.stringify(new SourceProjectionMapper(_TestCardArray).map());
        }
        return GetTestDataApi.projection;
    }
    
    static apiCall(network: Network, apiParams) {
        let params = {...apiParams.params};
        if(params.hasOwnProperty("isPro")){
            delete params.isPro;
        }
        if(params.hasOwnProperty("isElite")){
            delete params.isElite;
        }
        return network.get(GetTestDataApi.apiEndpoint ,  {__projection: GetTestDataApi.__projection, ...params})
    }

}

export class sendSelectedFiltersApi{
    static apiEndpoint = 'v1/live-panel/filter';
    static apiCall(network:Network,params:any){
        return network.post(sendSelectedFiltersApi.apiEndpoint , undefined, {'SGTarget' : params.toString()})
    }
} 

export class GetAttemptedTestDataApi {
    static apiEndpoint = 'v2_1/live-panel/attempted';

    static projection;
    static get  __projection(){ 
        if(!GetAttemptedTestDataApi.projection){
            GetAttemptedTestDataApi.projection = JSON.stringify(new SourceProjectionMapper(_TestCardAttemptedArray).map());
        }
        return GetAttemptedTestDataApi.projection;
    }
    
    static apiCall(network: Network, params: any) {
        return network.get(GetAttemptedTestDataApi.apiEndpoint , {__projection: GetAttemptedTestDataApi.__projection, ...params.params})    
    }

}




